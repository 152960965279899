import {ReactElement} from "react";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {ReactPdfViewer} from "../../../components/ReactPdfViewer/ReactPdfViewer";

type PreviewProps = {
    isLoaded: boolean;
    documentUrl: string;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

function Preview(props:PreviewProps) :ReactElement {
    const {
        isLoaded,
        documentUrl,
        handleNextStep,
        handlePreviousStep,
    } = props;
    
    return (
        isLoaded ? (
            <div className="w-full h-full flex flex-column align-items-center relative">
                <div className="w-9 overflow-y-auto" style={{maxHeight: "72vh"}}>
                    <ReactPdfViewer fileUrl={documentUrl} isModal={false}/>
                </div>
                <div className="w-9 p-4 w-full flex justify-content-center gap-2 bg-white absolute bottom-0">
                    <Button label="Back" severity="secondary" onClick={handlePreviousStep}/>
                    <Button label="Next" onClick={handleNextStep}/>
                </div>
            </div>
        ) : (
            <div className="w-full h-full flex flex-column justify-content-center align-items-center">
                <ProgressSpinner style={{ width: '50px', height:'50px' }}  strokeWidth="4"/>
            </div>
        )
    )
}

export default Preview;