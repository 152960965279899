import React, { FC, useMemo } from "react";
import styles from '../shared.module.scss';
import BusinessInformationForm from "./BusinessInformationForm";
import {TBusinessInformationFormValues, TBusinessInformationRequestParams} from "./types";
import {SubmitHandler} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../../hooks/useToast";
import {Form2553Service} from "../../../Service";
import {formatFormValues} from "../../../helpers";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";
import {TForm2553Information} from "../../CreationControl/types";


const dateValueKeys = [
    'electionEffectiveDate',
    'incorporationDate',
    'taxYearStartDate',
];

interface TBusinessInformationProps {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    isLateElection: boolean;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
    inputDataHandler:(name:string, value:string) => void;
}
const BusinessInformation: FC<TBusinessInformationProps> = ({
companyId,
formId,
setStep,
isLateElection,
form2553Information,
refetch2553Data,
inputDataHandler
}) => {
    const { show } = useToast();
    const businessInformation: TBusinessInformationRequestParams = form2553Information?.businessInformation || {};

    const postBusinessInformation = useMutation({
        mutationFn: Form2553Service.postBusinessInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
            setStep(stepsKeys.LATE_ELECTION_JUSTIFICATION);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postBusinessInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<TBusinessInformationFormValues> = (values) => {
        const params = formatFormValues(
            values as unknown as any[],
            dateValueKeys) as TBusinessInformationRequestParams;
        postBusinessInformation.mutate({ params, companyId, formId });
    };

    const businessInformationFormInitialValues = useMemo(
        () => {
            type TF = Record<string, string | undefined | boolean | Record<string, string>>;
            const formValueBlueprint: TF = {
                entityName: "",
                ein: "",
                address: {
                    line1: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    country: "United States",
                },
                incorporationDate: undefined,
                electionEffectiveDate: undefined,
                stateOfIncorporation: "",
                nameCheckbox: false,
                addressCheckbox: false,
                calendarYearCheckbox: false,
                yearEndingOfDecemberCheckbox: false,
                moreThan100ShareholdersCheckbox: false,
                officerName: "",
                officerTitle: "",
                officerPhoneNumber: "",
                lateElectionQuestionEnabled: false,
            }
            const resultObj: TF = {};
            Object.keys(formValueBlueprint).map((key: string) => {
                const defaultValue = formValueBlueprint[key as string];
                // @ts-ignore
                const vl = businessInformation[key];


                if(key === "address") {
                    if(vl) {
                        resultObj[key] = {
                            ...vl,
                            country: vl.country || "US",
                        }
                    }  else {
                        resultObj[key] = defaultValue;
                    }

                } else {
                    resultObj[key] = vl !== null ? vl : defaultValue;
                }

            });
            return {
                ...resultObj,
                electionEffectiveDate: businessInformation.electionEffectiveDate ? new Date(businessInformation.electionEffectiveDate) : undefined,
                incorporationDate: businessInformation.incorporationDate ? new Date(businessInformation.incorporationDate) : undefined,
            }
        },

        [businessInformation]
    );

    return (
        <div className={styles.stepSection}>
            <div className={styles.formColumn}>
                <BusinessInformationForm
                    onSubmit={onSubmit}
                    onInputChange={inputDataHandler}
                    initialValues={businessInformationFormInitialValues as TBusinessInformationFormValues}
                />
            </div>
        </div>
    );
};

export default BusinessInformation;

