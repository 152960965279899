import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import Loading from "../../../components/Loading/Loading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HttpService} from "../../../services/HttpService";
import SubmissionCreatedModal
    from "../../../components/DocumentGeneration/components/SubmissionCreatedModal/SubmissionCreatedModal";
type SignWellProps = {
    companyId: number
    embeddedUrl: string;
}
function SignWell(props:SignWellProps): ReactElement {
    const {
        companyId,
        embeddedUrl
    } = props;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isDocumentSigned = useRef(false);
    const [showSubmissionSuccessModal, setShowSubmissionSuccessModal] = useState(false);
    const showEmbeddedSignWell = useCallback((url:string) => {
        // @ts-ignore
        const signWellEmbed = new SignWellEmbed({
            url: url,
            events: {
                completed:() => {
                    isDocumentSigned.current= true
                },
                closed: () => {
                    if(isDocumentSigned.current) {
                        setShowSubmissionSuccessModal(true);
                    } else {
                        navigate("/filing")
                    }


                },
            }
        })
        signWellEmbed.open();
    },[isDocumentSigned])

    useEffect(() => {
        if(embeddedUrl) {
            showEmbeddedSignWell(embeddedUrl);
        } else {
            HttpService.get(
                `/api/companies/${companyId}/file83b/embedded-url/${searchParams.get('rdn')}`
            ).then(res => {
                showEmbeddedSignWell(res.data.embeddedSigningUrl);
            })
        }
    }, [embeddedUrl]);

    return (
        <div className="h-full w-full flex justify-content-center align-items-center">
            <Loading horizontalSpacing={120} />
            <SubmissionCreatedModal
                isSupposeExists={false}
                showSubmissionSuccessModal={showSubmissionSuccessModal}
            />
        </div>
    )
}

export default SignWell;