import React, { useMemo } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import styles from './breadcrumbs.module.scss';

import homeIcon from '../../assets/icons/home.svg';
import chevronRight from '../../assets/icons/chevron_right.svg';

const labels: Record<string, string> = {
    'admin-dashboard' : 'Dashboard',
    'manage-user': 'Manage Users',
    'subscriptions': 'Subscriptions',
    'filing': 'File 83(b) election',
    'file-83b': 'New filing',
    'grants': 'Grants',
    'documents': 'Super Admin Dashboard',
    'acknowledgements': 'Acknowledgements',
    'upload': 'Upload',
    'admin-terms': 'Admin terms',
    'update': 'Update',
    'PRIVACY_POLICY': 'Privacy Policy',
    'TERMS_FOR_83B': 'Terms For 83b',
    'TERMS_OF_SERVICE': 'Terms Of Service',
    "form-2553-dashboard":"S Corp Election",
};

const Breadcrumbs = () => {
    const { pathname } = useLocation();
    const breadcrumbItems = useMemo(() => {
        const paths = pathname.split('/').filter(x => x);
        const crumbList = [];

        for (let i = 0; i < paths.length; i++) {
            const path = `/${paths.slice(0, i + 1).join('/')}`;
            const isLast = i === paths.length - 1;
            const isPathNumber = typeof Number(paths[i]) === 'number' && !Number.isNaN(Number(paths[i]));
            const name = labels[paths[i]] || paths[i];
            if (isPathNumber) {
                continue;
            }
            crumbList.push(
                isLast ? (
                    <span key={path}>{name}</span>
                ) : (
                    <NavLink key={path} to={path}>{name}</NavLink>
                )
            );
        }

        return crumbList;
    }, [pathname]);
    return (
        <div className={styles.breadcrumbs}>
            <img src={homeIcon} alt="" className={styles.homeIcon}/>
            <img src={chevronRight} alt="" className={styles.arrowIcon} />
            <ul>
                {breadcrumbItems.map((item, index) => (
                    <li key={index}>
                        {index !== 0 && (<i className="bi bi-chevron-right"></i>)}
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Breadcrumbs;
