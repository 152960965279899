import React, {useCallback, useMemo, useRef} from "react";

import {Toast} from "primereact/toast";
import {SplitButton} from "primereact/splitbutton";

// @ts-ignore
import FileIcon from "../../../../assets/icons/document-icon.svg?react";
import {stepsKeys} from "../../../../uiComponents/StepsControl/StepsControl";
import {HttpService} from "../../../../services/HttpService";

type SubmitProps = {
    document:any,
    companyId:number;
    updateDocList: (formId:number) => void;
    formId: number | undefined;
    handleStepChange: (doc:any, step:string) => void;
    handleDownload: (document:any, b?: boolean) => Promise<void>;
}

function SubmitUploadedFile(props:SubmitProps) {
    const {
        formId,
        companyId,
        document,
        updateDocList,
        handleDownload,
        handleStepChange
    } = props

    const toast = useRef<Toast | null>(null)
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = useCallback(async  () => {
        const {userType, shareholderId, documentType} = document;
        setIsLoading(true);
        try {
            if(userType === "SHARE_HOLDER") {
               await HttpService.post(
                    `/api/companies/${companyId}/form2553/${formId}/file/shareholder/${shareholderId}/sign`
                )
            } else if(userType === "OFFICER") {
                const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER"
                await HttpService.post(
                    `/api/companies/${companyId}/form2553/${formId}/file/sign/${isCoverLetter}`
                );
            }
            setIsLoading(false);
            updateDocList(Number(formId));
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully submitted"
            });
        } catch (e) {
            setIsLoading(false);
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        }

    }, [document, companyId, formId]);


    const items = useMemo(() => {

        return [
            {
                label: "Download",
                command: () => {
                    setIsLoading(true);
                    handleDownload(document).then(() => {
                        setIsLoading(false);
                    })
                }
            },
            {
                label: "Re-upload",
                command: () => handleStepChange(document, stepsKeys.UPLOAD)
            },
        ]
    }, [handleDownload, formId]);

    return (
        <div className="w-full p-3 flex bg-white justify-content-between border-round border-1 border-solid border-300 border-round-lg">
            <Toast ref={toast} />
            <div className="p-2 w-3rem flex justify-content-center align-items-center h-3rem bg-blue-100 border-round-lg">
                <FileIcon width={100} height={50}/>
            </div>
            <div>
                <SplitButton
                    label="Submit"
                    model={items}
                    loading={isLoading}
                    onClick={handleSubmit}
                    disabled={document.currentStep === stepsKeys.SIGNED}
                    pt={{
                        menu: {
                            className:"font-semibold text-base",
                            style: {right:"50%", left:"auto"},
                        },

                    }}/>
            </div>
        </div>
    )
}

export  default SubmitUploadedFile;