import React, {ReactElement, useEffect, useState} from "react";
import {PrivacyService} from "../../PolicyPages/PolicyService";
import {TermsTypeKeys} from "../../TermsUpdate/utils";
import EditorResult from "../../../components/HtmlEditor/EditorResult";
import Loading from "../../../components/Loading/Loading";
import {Button} from "primereact/button";

import {Checkbox} from "primereact/checkbox";
type TermsProps = {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}
function Terms(props:TermsProps):ReactElement {
    const {
        handleNextStep,
        handlePreviousStep,
    } = props;
    const [isLoaded, setLoaded] = useState(false);
    const [content, setContent] = useState<string>('')
    const [isTermsAccepted, setTermsState] = useState(false);

    useEffect(() => {
        PrivacyService.getContentByType(
            TermsTypeKeys.TERMS_FOR_83B
        ).then(({data}) => {
            setContent(data.text);
            setLoaded(true);
        });
    }, []);
    return (
        <div className="w-full h-full flex justify-content-center align-items-start" >
            <div className="p-5 w-7 bg-white relative flex flex-column justify-content-center gap-3">
                {
                    isLoaded ? (
                        <>
                        <div className="overflow-y-auto" style={{maxHeight: "60vh"}}>
                            <EditorResult content={content}/>
                        </div>
                            <div className="pt-4 w-full flex justify-content-between align-items-center gap-2">
                                <div className="w-auto flex pl-6">
                                    <Checkbox
                                        inputId="inputid"
                                        name="terms"
                                        className="mr-2"
                                        onChange={() => setTermsState(!isTermsAccepted)}
                                        checked={isTermsAccepted}
                                    />
                                    <label htmlFor="inputid">I have read and agree to these terms.</label>
                                </div>
                                <div className="w-7 flex justify-content-end ">
                                    <Button label="Back" severity="secondary" onClick={handlePreviousStep}/>
                                    <Button label="Next" className="ml-4" onClick={handleNextStep} disabled={!isTermsAccepted}/>
                                </div>

                            </div>
                        </>

                    ) : (
                        <div className="h-full flex justify-content-center align-items-center">
                            <Loading horizontalSpacing={120} />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Terms