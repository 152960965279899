import React, {useState} from 'react';
import styles from './public83b.module.scss';
import DocumentGeneration from "../../components/DocumentGeneration/DocumentGeneration";
import Loading from "../../components/Loading/Loading";
import {useQuery} from "@tanstack/react-query";
import {TemplatesService} from "./TemplateService";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import {getYupDynamicScheme} from "../../components/DocumentGeneration/helpers/yupValidationHelpers";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";

import FormWrapper15620 from "./Form15620/FormWrapper15620";
import {InputText} from "primereact/inputtext";
import {useDebounce} from "primereact/hooks";
import { useForm, Controller } from 'react-hook-form';
import {classNames} from "primereact/utils";

const PublicFile83b = () => {
    const [isSupposeExists, setSuppose] = useState(false);

    const [isRegularForm, setRegularForm] = useState(true);
    const [isFormTypeSelected, setFormTypeSelected] = useState(false);
    const [showChooseFormTypeDialog, setShowChooseFormTypeDialog] = useState(true);
    const [inputValue, debouncedEmail, setInputValue] = useDebounce('', 500);
    const defaultValues = {
        formType:"regular",
        email:''
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const { data: initialInfo } = useQuery({
        queryKey: TemplatesService.fetchInitialInfo.queryKey,
        queryFn: () => TemplatesService.fetchInitialInfo.queryFn(),
    });
    const { templateCode, templateSpouseCode, price, uspsPrice, templateCrypto} = initialInfo || {};
    const template = isSupposeExists
        ? templateSpouseCode
        : templateCode;

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: [...TemplatesService.fetchTemplateConfiguration.queryKey, isSupposeExists],
        queryFn: () => TemplatesService.fetchTemplateConfiguration.queryFn(template),
        enabled: Boolean(templateCode) && Boolean(templateSpouseCode),
        gcTime: 0,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: [...TemplatesService.exportDocAsHtml.queryKey, isSupposeExists],
        queryFn: () => TemplatesService.exportDocAsHtml.queryFn(template),
        enabled: Boolean(templateCode) && Boolean(templateSpouseCode),
        gcTime: 0,
    });
    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs) : undefined;
    return (
        <div className={styles.public83b}>
            {
                ((!templateCode || !templateSpouseCode)) ? (
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={250} />
                    </div>
                ) : (
                    <>
                        <PublicHeader />
                        <DocumentGeneration
                            isSupposeExists={isSupposeExists}
                            setSuppose={setSuppose}
                            template={template}
                            data={fieldsConfiguration}
                            requiredFieldsLoading={requiredFieldsLoading}
                            docAsHtml={docAsHtml}
                            docAsHtmlLoading={docAsHtmlLoading}
                            isPublic
                            price={price}
                            uspsPrice={uspsPrice}
                            showSelectTemplate={Boolean(templateSpouseCode)}
                            formSchema={formSchema}
                        />
                    </>
                )
            }
        </div>
    )
    // return (
    //     <div className="h-full max-w-full">
    //         <Dialog visible={showChooseFormTypeDialog}
    //                 onHide={() => {
    //                     setRegularForm(true);
    //                     setFormTypeSelected(true);
    //                     setShowChooseFormTypeDialog(false);
    //                 }
    //                 }
    //                 style={{ width: '25vw' }}
    //                 header="Which form would you like to use?">
    //             <div className="h-full flex flex-column gap-3">
    //                 <form className="h-full p-fluid flex flex-column gap-3" onSubmit={handleSubmit(() => {
    //                     setFormTypeSelected(true);
    //                     setShowChooseFormTypeDialog(false)
    //                 })} >
    //                     <div className="w-full flex align-items-start pt-1">
    //                         <RadioButton inputId="regular83b"
    //                                      name="formType"
    //                                      value="regular"
    //                                      checked={isRegularForm}
    //
    //                                      onChange={(e) => setRegularForm(true)} />
    //                         <div className="ml-2 flex flex-column gap-1">
    //                             <span className="font-bold text-lg">Freeform statement</span>
    //                             <span className="font-italic text-base">Convenient for restricted stock awards</span>
    //                         </div>
    //                     </div>
    //                     <div className="w-full flex align-items-start pt-1">
    //                         <RadioButton inputId="form15620"
    //                                      name="formType"
    //                                      value="form15620"
    //                                      checked={!isRegularForm}
    //                                      onChange={(e) => {
    //                                          reset();
    //                                          setRegularForm(false)}
    //                                     }/>
    //                         <div className="ml-2 flex flex-column gap-1">
    //                             <span className="font-bold text-lg">Form 15620</span>
    //                             <span className="font-italic text-base">Convenient for profits interests, tokens, and other equity/property types</span>
    //                         </div>
    //
    //                     </div>
    //                     {
    //                         !isRegularForm ? (
    //                             <div className="w-full flex flex-column align-items-start pt-1 gap-1">
    //                                 <label htmlFor="email" className={classNames({"text-red-500": errors.email})}>
    //                                     Email<span className="ml-1 text-red-300">*</span>
    //                                 </label>
    //                                 <Controller
    //                                     name="email"
    //                                     control={control}
    //                                     rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
    //                                     render={({field, fieldState }) => {
    //                                         return (
    //                                             <>
    //                                                 <InputText  {...field}
    //                                                             id="email"
    //                                                             tooltip="Enter the email"
    //                                                             tooltipOptions={{position: "top",style:{maxWidth:"30vw"}}}
    //                                                             onChange={(e) => {
    //                                                                 setInputValue(e.target.value)
    //                                                                 field.onChange(e.target.value)
    //                                                             }}
    //                                                             className={classNames({
    //                                                                 'p-invalid': fieldState.invalid,
    //                                                                 'text-red-500': fieldState.invalid,
    //                                                             })}/>
    //                                                 {
    //                                                     fieldState.invalid ? (
    //                                                         <small className="text-red-500">
    //                                                             {fieldState.error?.message}
    //                                                         </small>
    //                                                     ) : null
    //                                                 }
    //
    //                                             </>
    //                                         )
    //                                     }}
    //                                 />
    //
    //                             </div>
    //                         ) : null
    //                     }
    //
    //                     <Button label="Select" type="submit" className="align-self-end w-auto"/>
    //                 </form>
    //             </div>
    //         </Dialog>
    //         {
    //             isFormTypeSelected ? (
    //                 isRegularForm ? (
    //                     <>
    //                         <PublicHeader />
    //                         <DocumentGeneration
    //                             isSupposeExists={isSupposeExists}
    //                             setSuppose={setSuppose}
    //                             template={template}
    //                             data={fieldsConfiguration}
    //                             requiredFieldsLoading={requiredFieldsLoading}
    //                             docAsHtml={docAsHtml}
    //                             docAsHtmlLoading={docAsHtmlLoading}
    //                             isPublic
    //                             price={price}
    //                             uspsPrice={uspsPrice}
    //                             showSelectTemplate={Boolean(templateSpouseCode)}
    //                             formSchema={formSchema}
    //                         />
    //                     </>
    //                 ) : (
    //                     <FormWrapper15620
    //                         price={price}
    //                         uspsPrice={uspsPrice}
    //                         isPublic={true}
    //                         email={debouncedEmail}
    //                         isDataLoaded={true}
    //                         templateId={templateCrypto}
    //                         isPublicOpenAi={false}
    //                         paymentEnabled={true} />
    //                 )
    //             ) : (
    //                 <div className="w-full h-full flex align-items-center justify-content-between">
    //                     <Loading/>
    //                 </div>
    //             )
    //         }
    //
    //     </div>
    // );

}

export default PublicFile83b;
