import React, {memo} from "react";
import fedexLogo from "./fedex-logo.png";
type Props = {
    trackingNumber:number
}
function FedExIndoHeader(props:Props) {
    const {trackingNumber} = props;
    return (
        <div className="w-full p-4 row-gap-3 border-bottom-1 surface-border flex flex-column">
            <div className="w-full font-semibold text-2xl">
                Shipping Details
            </div>
            <div className="w-full flex">
                <div className="flex-1">
                    <div className="mb-2 text-base font-normal text-lg">
                        Delivery Type
                    </div>
                    <div className="flex-1 text-base w-6rem">
                        <img src={fedexLogo}  alt="fedex logo" className="max-w-full h-auto"/>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="mb-2 text-base font-normal text-lg">
                        Tracking ID
                    </div>
                    <div className="flex-1 text-base justify-content-center">
                        {
                            trackingNumber ? (
                                <a className="no-underline text-primary" target="_blank" href={`https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`}>
                                    { trackingNumber }
                                    <i className="ml-2 text-xs pi pi-external-link"/>
                                </a>
                            ) : "-"
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export  default  memo(FedExIndoHeader)