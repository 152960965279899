import React, { FC } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Form2553Creation from "./Form2553Creation/Form2553Creation";
import FilingList from "./pages/FilingList/FilingList";
import Details from "./pages/Details/Details";
import Preview from "./Preview/Preview";

interface TForm2553RoutesProps {
    companyId: number
    companies: any[]
    isAdmin: boolean
}

const Form2553Routes: FC<TForm2553RoutesProps> = ({companies, companyId, isAdmin }) => {

    return (
        <Routes>
            <Route path="/" element={<FilingList companyId={companyId} />} />
            <Route path="/create" element={<Form2553Creation companyId={companyId} companies={companies} isAdmin={isAdmin} />} />
            <Route path="/update/:formIdParam" element={<Form2553Creation companyId={companyId} companies={companies} isAdmin={isAdmin} />}/>
            <Route path="/details/:formId" element={<Details companyId={companyId} />} />
            <Route path="/publish/:formIdParam" element={<Form2553Creation companyId={companyId} companies={companies} hasPaid isAdmin={isAdmin} />} />
            <Route path="/preview/:formId/pdf" element={<Preview companyId={companyId} />} />
            <Route path="/*" element={<Navigate to="" replace />} />
        </Routes>
    )
}

export default Form2553Routes;
