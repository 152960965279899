import React, { FC } from 'react';
import widgetIcon from '../../../../assets/images/no-submission-widget.svg';
import styles from './fillingList.module.scss';
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TNoSubmissionWidgetProps {
    goToCreation: () => void;
}
const NoSubmissionWidget: FC<TNoSubmissionWidgetProps> = ({ goToCreation }) => {
    return (
        <div className={styles.noSubmissionWidget}>
            <div className={styles.ct}>
                <img src={widgetIcon} alt="" />
                <p className={styles.txt_a}>There are no submissions available</p>
                <p className={styles.txt_b}>To get started, simply click on the button below.</p>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={goToCreation}
                    iconClassName="bi-plus"
                    label="Add new submission"
                />
            </div>
        </div>
    )
}

export default NoSubmissionWidget;
