import moment from "moment/moment";

export const formatDate = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
};

export const formatFormValues = (values: any[], dateValueKeys: string[]) => {
    const result: Record<string, any> = {};
    Object.entries(values).forEach(([key, value]) => {
        const ifDateValue = dateValueKeys.includes(key);
        if (ifDateValue) {
            result[key] = formatDate(value as Date);
            return;
        }
        result[key] = value;
    });
    return result;
}

export  const downloadFile = (url:string, fileName:string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

