import React, { FC } from 'react';
import styles from './shareholders.module.scss';
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {OwnershipMetric, TAdditionalInformationParams, TOwnershipMetric} from "./types";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

const ownershipMetricOptions: { label: string; value: TOwnershipMetric }[] = [
    {
        label: 'Number of shares',
        value: OwnershipMetric.NUMBER_OF_SHARES,
    },
    {
        label: 'Percentage',
        value: OwnershipMetric.PERCENTAGE,
    }
];

interface TOwnershipMetricModalProps {
    onPrev: () => void;
    selectedValue: any;
    onPatchAdditionalInformation: (params: TAdditionalInformationParams) => void;
}
const OwnershipMetricModal: FC<TOwnershipMetricModalProps> = ({
    onPrev,
selectedValue,
onPatchAdditionalInformation,
}) => {
    const [value, setValue] = React.useState<TOwnershipMetric | ''>(selectedValue);
    const onAccept = () => {
        onPatchAdditionalInformation({ ownershipMetric: value as TOwnershipMetric });
    }
    return (
        <div className={styles.ownershipMetricModalOverlay}>
            <div className={styles.modal}>
                <div className={styles.selectRow}>
                    <p className={styles.msg}>Will you be providing shareholder ownership in number of shares or percentage? </p>
                    <Dropdown
                        value={value}
                        placeholder="Select ownership metric"
                        className="w-full mt-2"
                        options={ownershipMetricOptions}
                        onChange={(e) => setValue(e.value)}
                    />
                </div>
                <div className="w-full flex justify-content-around">
                    <Button severity="secondary" label="Back" className="w-5" onClick={onPrev}/>
                    <Button label="Accept" className="w-5" onClick={onAccept} disabled={!value}/>
                </div>
            </div>
        </div>
    )
}

export default OwnershipMetricModal;
