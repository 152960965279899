import { ReactElement, useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import {useNavigate, useSearchParams} from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { HttpService } from "../../../../services/HttpService";
import { extractErrorMessage } from "../../../../helpers/extractErrorMessage";
import { getYupDynamicScheme } from "../../../../components/DocumentGeneration/helpers/yupValidationHelpers";
import Draft83bFilledModal from "../../../../components/Draft83bFilledModal/Draft83bFilledModal";
import DocumentGeneration from "../../../../components/DocumentGeneration/DocumentGeneration";
import { TDataItem } from "../../../../components/DocumentGeneration/types";
import FormWrapper15620 from "../../Form15620/FormWrapper15620";
import Loading from "../../../../components/Loading/Loading";
import { TemplatesService } from "../../../PublicFile83b/TemplateService";


type FileCreationWrapperPropTypes = {
    companyId: number;
    templateCode: string;
    templateCrypto?: string;
    paymentEnabled: boolean;
    isEveryCompany: boolean;
    templateSpouseCode?: string;
    email?: string;
    fullName?: string;
    deliveryService?: string;
    filingCreationEnabled: boolean;
}

function FileCreationWrapper(props: FileCreationWrapperPropTypes): ReactElement {

    const {
        email = '',
        fullName = '',
        companyId,
        templateCode,
        templateCrypto,
        paymentEnabled,
        isEveryCompany,
        templateSpouseCode,
        deliveryService='',
        filingCreationEnabled,
    } = props;

    const { show } = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [fileId] = useState(searchParams.get('fileId'));
    const [isOnlyRegularFormAllowed] = useState(templateCrypto === null);
    const [formData, setFormData] = useState<Record<string, any>>({});
    const [formSchema, setFormSchema] = useState<Record<string, any>>({});
    const [hasSuppose, setSuppose] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [orderNumber, setOrderNumber] = useState(searchParams.get('rdn'));
    const [isUpdateMode, setUpdateMode] = useState(searchParams.has('fileId'));
    const [isRegularForm, setRegularForm] = useState(true);
    const [isAlreadyFilled, setAlreadyFilled] = useState(false);
    const [isFormDataLoaded, setFormDataLoaded] = useState(false);
    const [isFormTypeSelected, setFormTypeSelected] = useState(isOnlyRegularFormAllowed);
    const [fieldsConfig, setFieldsConfig] = useState<{ configs: TDataItem[] }>({ configs: [] });
    const [isFieldsConfigLoaded, setFieldsConfigLoaded] = useState(false);
    const [docAsHtml, setDocAsHtml] = useState('');
    const [isDocAsHtmlLoaded, setDocAsHtmlLoaded] = useState(false);
    const [showChooseFormTypeDialog, setShowChooseFormTypeDialog] = useState((!isUpdateMode && !orderNumber && !isOnlyRegularFormAllowed));

    const { data: deliveryPrices } = useQuery({
        queryKey: TemplatesService.fetchInitialInfo.queryKey,
        queryFn: () => TemplatesService.fetchInitialInfo.queryFn(),
        enabled:true
    });
    const {price, uspsPrice} = deliveryPrices || {price:0, uspsPrice:0};
    const setSupposeHandler = useCallback((hasSuppose: boolean) => {

        setSuppose(hasSuppose);
        setTemplateId(() => {
            const templateId = hasSuppose ? templateSpouseCode : templateCode;
            return templateId as string;
        });

    }, [templateCode, templateSpouseCode]);

    const fetchFieldsConfig = useCallback(() => {
        HttpService.get(
            `/api/companies/${companyId}/file83b/getPlaceholdersByTemplateCodeDb/${templateId}${isUpdateMode ? `/edit` : ''}`
        ).then(({ data }) => {
            setFieldsConfig(data);
            setFieldsConfigLoaded(true);
            setFormSchema(data.configs ? getYupDynamicScheme(data.configs) : {});
        }).catch((error) => {
            extractErrorMessage({
                error: error,
                show: show,
            });
        })
    }, [companyId, templateId, isUpdateMode]);

    const fetchDocAsHtml = useCallback(() => {
        HttpService.get(
            `/api/companies/${companyId}/file83b/exportDocAsHtml/${templateId}`
        ).then(({ data }) => {
            setDocAsHtml(data);
            setDocAsHtmlLoaded(true);
        }).catch((error) => {
            extractErrorMessage({
                error: error,
                show: show,
            });
        })
    }, [companyId, templateId]);

    useEffect(() => {
        if (templateId !== '') {
            fetchFieldsConfig();
            fetchDocAsHtml();
        }
    }, [templateId, fetchFieldsConfig]);

    useEffect(() => {
        if (isUpdateMode) {
            HttpService.get(
                `/api/companies/${companyId}/file83b/${fileId}`
            ).then(({ data }) => {
                setFormData(data);
                setFormDataLoaded(true);
                setRegularForm(data?.regular);
                setFormTypeSelected(true);
                setSupposeHandler(data?.jointlyWithSpouse);
            }).catch((error) => {
                if (error?.response?.status === 400) {
                    setAlreadyFilled(true);
                } else {
                    extractErrorMessage({
                        error: error,
                        show: show,
                    });
                }
            })
        }
    }, [templateSpouseCode]);

    return (
        <div className="h-full max-w-full">
            <Dialog visible={showChooseFormTypeDialog}
                onHide={() => {
                    setRegularForm(true);
                    setFormTypeSelected(true);
                    setShowChooseFormTypeDialog(false);
                    navigate("/filing")
                }
                }
                style={{ width: '25vw' }}
                header="Which form would you like to use?"
                footer={<Button label="Select" onClick={() => {
                    setFormTypeSelected(true);
                    setShowChooseFormTypeDialog(false)
                }} />}>
                <div className="h-full flex flex-column gap-3">
                    <div className="w-full flex align-items-start pt-1">
                        <RadioButton inputId="regular83b"
                            name="form-83b"
                            value="regular"
                            checked={isRegularForm}

                            onChange={(e) => setRegularForm(true)} />
                        <div className="ml-2 flex flex-column gap-1">
                            <span className="font-bold text-lg">Freeform statement</span>
                            <span className="font-italic text-base">Convenient for restricted stock awards</span>
                        </div>
                    </div>
                    <div className="w-full flex align-items-start pt-1">
                        <RadioButton inputId="form15620"
                            name="form-83b"
                            value="form15620"
                            checked={!isRegularForm}
                            onChange={(e) => setRegularForm(false)} />
                        <div className="ml-2 flex flex-column gap-1">
                            <span className="font-bold text-lg">Form 15620</span>
                            <span className="font-italic text-base">Convenient for profits interests, tokens, and other equity/property types</span>
                        </div>
                    </div>
                </div>
            </Dialog>

            {
                isAlreadyFilled ? (
                    <Draft83bFilledModal
                        onClose={() => setAlreadyFilled(false)}
                        filingCreationEnabled={filingCreationEnabled} />
                ) : null
            }
            {
                isFormTypeSelected ? (
                    isRegularForm ? (
                        <DocumentGeneration
                            companyId={companyId}
                            email={email}
                            fullName={fullName}
                            isSupposeExists={hasSuppose}
                            setSuppose={setSupposeHandler}
                            template={templateId}
                            data={fieldsConfig}
                            isPublic={false}
                            price={price}
                            uspsPrice={uspsPrice}   
                            requiredFieldsLoading={!isFieldsConfigLoaded}
                            docAsHtml={docAsHtml}
                            docAsHtmlLoading={!isDocAsHtmlLoaded}
                            preFilledFormValues={formData}
                            file83bUpdate={isUpdateMode}
                            preFilledFormValuesLoading={!isFormDataLoaded}
                            fileId={fileId || ''}
                            showSelectTemplate={Boolean(templateSpouseCode)}
                            signAfterEmbeddedPaymentOrderNumber={orderNumber}
                            paymentEnabled={(paymentEnabled && !formData?.paid)}
                            formSchema={formSchema}
                            isEveryCompany={isEveryCompany}
                            showDeliveryService={deliveryService === 'NONE'}
                        />
                    ) : (
                        <FormWrapper15620
                            isPublic={false}
                            email={email}
                            price={price}
                            uspsPrice={uspsPrice} 
                            deliveryService={deliveryService}
                            isDataLoaded={isUpdateMode ? isFormDataLoaded : true}
                            companyId={companyId}
                            templateId={templateCrypto}
                            isPublicOpenAi={false}
                            formData={formData}
                            paymentEnabled={(paymentEnabled && !formData?.paid)} />
                    )
                ) : (
                    <div className="w-full h-full flex align-items-center justify-content-between">
                        <Loading/>
                    </div>                    
                )
            }

        </div>
    );
}

export default FileCreationWrapper;