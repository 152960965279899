import {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";

import {
    FieldValues,
    Controller,
    useForm,
    useWatch,
} from "react-hook-form";

import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {InputTextarea} from "primereact/inputtextarea";
import { yupResolver } from "@hookform/resolvers/yup";

import {HttpService} from "../../../services/HttpService";

import yupScheme from "./yup-scheme";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { extractErrorMessage } from "../../../helpers/extractErrorMessage";
import { useToast } from "../../../hooks/useToast";
import CountryBasedTinDialog from "./CountryBasedTinDialog";


type  Props = {
    fileId: string | null;
    companyId: number;
    formData: Record<string, any>;
    onDataSave: ( data:FieldValues) => void,
}
function FormFile15620 (props:Props) : ReactElement {
    const {
        fileId,
        formData,
        companyId,
        onDataSave
    } = props;

    const {
        control,
        handleSubmit,
        formState:{errors},
        watch,
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(yupScheme),
        defaultValues: formData,
    });

    const { show } = useToast();
    const [isSubmitted, setSubmitted] = useState(false);
    const [countries, setCountries] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [countryStates, setCountryStates] = useState([]);
    const [selectedCountry , setSelectedCountry] = useState(watch('country'));
    const [isSelectTinDialogOpen, setSelectTinDialogOpen] = useState(false);
    const [isCompanyTinSelected, setCompanyTinSelected] = useState(false);
    const [selectedCompanyCountry , setSelectedCompanyCountry] = useState(watch('company_country'));

    const getCountriesANdStates = useCallback(async () => {
        const states = await HttpService.get('/api/ref/state').then(res => res.data);
        const countries = await HttpService.get('/api/ref/country').then(res => res.data);
        return {countries, states};
    },[]);

    const FMV = useWatch({name: 'FMV', control});
    const pricePerShare = useWatch({name: 'price_per_share', control});
    const numberOfShares = useWatch({name: 'number_of_shares', control});   
    
    const totalPaid = useWatch({name: 'total_paid', control});
    const paidPricePerShare = useWatch({name: 'price_paid', control});
    const paidNumberOfShares = useWatch({name: 'paid_number_of_shares', control});

    const dateRange = useMemo(() => {
        const range = 21;
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setDate(minDate.getDate() - range);
        maxDate.setDate(maxDate.getDate() + 1);
        return {
        minDate,
        maxDate,
        }
    }, []);

    useEffect(() => {
        if(numberOfShares) {
            setValue('paid_number_of_shares', numberOfShares, {shouldValidate: true});
        }

        if(pricePerShare && numberOfShares) {
            const newValue = pricePerShare * numberOfShares
            setValue('FMV', newValue, {shouldValidate: true});
        }

        if(paidPricePerShare && paidNumberOfShares) {
            const newValue =  paidPricePerShare * paidNumberOfShares
            setValue('total_paid',newValue, {shouldValidate: true});
        }

        if(FMV && totalPaid) {        
            setValue('income', (FMV - totalPaid), {shouldValidate: true});
        }

    }, [FMV, totalPaid, pricePerShare, numberOfShares, paidPricePerShare, paidNumberOfShares]);

    const onSubmitHandler = useCallback((formData:FieldValues) => {
        setSubmitted(true);
        onDataSave(formData);
    },[]);

    useEffect(() => {
        getCountriesANdStates().then(data => {
            setCountryStates(data.states);
            setCountries(data.countries);
            setIsDataLoaded(true);
        });

    }, []);

    useEffect(() => {

        if(fileId && !Object.keys(formData).length) {
            HttpService.get(
                `/api/companies/${companyId}/file83b/${fileId}`
            ).then(({ data }) => {
                reset(data);
            }).catch((error) => {
                extractErrorMessage({
                    error: error,
                    show: show,
                });
            })
        }   
    }, [fileId])


    return (
        <div className="w-full flex justify-content-center">
            <div className="w-7 mt-4 p-6 pb-0 bg-white relative">
                <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off" className="border-top-3 text-color text-base font-medium relative">
                    <header className="flex overflow-hidden border-bottom-2">
                        <div className="p-3 capitalize flex-none border-right-1">
                            <div className="text-color">
                                form <span className="text-2xl font-bold">15620</span>
                                <span className="text-sm block">
                                    (October 2024)
                                </span>
                            </div>
                        </div>
                        <div className="p-2 flex-grow-1 flex align-items-center flex-column">
                            <div className="text-base font-medium mt-1">
                                Department of the Treasury - Internal Revenue Service
                            </div>
                            <h2 className="text-4xl font-bold">Section 83(b) Election</h2>
                        </div>
                        <div className="p-3 capitalize flex-none border-left-1">
                            <div className="text-base text-center font-normal">OMB Number</div>
                            <div className="text-base text-center font-medium">1545-0074</div>
                        </div>
                    </header>
                    <div className="py-2 border-bottom-1">
                        The undersigned taxpayer hereby elects, pursuant to § 83(b) of the Internal Revenue Code of 1986, as amended, to include in gross
                        income as compensation for services the excess (if any) of the fair market value of the property described below over the amount paid
                        for the property.
                    </div>
                    <section className="py-2">
                        <div className="border-bottom-1 pb-1">
                            1. The taxpayer’s name, taxpayer identification number (TIN), and address:
                        </div>
                        <div className="w-full flex border-bottom-1">
                            <div className="w-8 p-1 flex flex-column gap-1">
                                <label htmlFor="taxpayer_name" className={classNames({"text-red-500": errors.taxpayer_name})}>
                                    Taxpayer’s name
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="taxpayer_name"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="taxpayer_name"                                                            
                                                            tooltip="Enter the name of the person making the 83(b) election."
                                                            tooltipOptions={{position: "top", showDelay:500, style:{maxWidth:"30vw"}}}
                                                            style={{outline: 'none', boxShadow: 'none',}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround", {
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }

                                            </>
                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 p-1 border-left-1 flex flex-column gap-1">
                                <label htmlFor="taxpayer_id" className={classNames({"text-red-500": errors.taxpayer_id})}>
                                    Taxpayer’s TIN
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="taxpayer_id"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="taxpayer_id"
                                                            readOnly
                                                            onFocus={() => {
                                                                setSelectTinDialogOpen(true);
                                                                setCompanyTinSelected(false)
                                                            }} 
                                                            tooltip='Enter the Taxpayer Identification Number (TIN) of the person making the 83(b) election.  In the case of an individual, normally, this would be your Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).  If you do not have such a number (for example, if you’re a non-U.S. person), you may want to write "N/A", “Foreign individual”, or “Applied for”.  However, before you do so, make sure you consult with a tax professional on this.'
                                                            tooltipOptions={{position: "top", showDelay:500, style:{maxWidth:"30vw"}}}
                                                            style={{outline: 'none', boxShadow: 'none',}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround", {
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}
                                                />
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="address" className={classNames({"text-red-500": errors.address})}>
                                Address
                                <span className="font-normal font-italic text-base">(number and street)</span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="address"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputText  {...field}
                                                        id="address"
                                                        tooltip="Enter the address of the person making the 83(b) election.  We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                        tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                        className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                            'p-invalid': fieldState.invalid,
                                                            'border-none': !fieldState.invalid,
                                                            'text-red-500': fieldState.invalid,
                                                        })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex">
                            <div className="w-3 p-1 -my-2 flex flex-column gap-1">
                                <label htmlFor="city" className={classNames({"text-red-500": errors.city})}>
                                    City
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="city"
                                                            tooltip="Enter the address of the person making the 83(b) election. We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="state" className={classNames({"text-red-500": errors.state})}>
                                    State or province
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                {
                                                    (selectedCountry === 'United States') ? (
                                                        <Dropdown
                                                            {...field}
                                                            filter
                                                            showClear
                                                            style={{width: '100%'}}
                                                            options={countryStates}
                                                            disabled={!isDataLoaded}
                                                            optionLabel="label"
                                                            optionValue="label"
                                                            placeholder="Select a State"
                                                            id="state"
                                                            tooltip="Enter the address of the person making the 83(b) election. We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}
                                                        />
                                                    ) : (
                                                        <InputText  {...field}
                                                                    id="state"
                                                                    disabled={!isDataLoaded}
                                                                    tooltip="Enter the address of the person making the 83(b) election. We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                                    tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                                    className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                        'p-invalid': fieldState.invalid,
                                                                        'border-none': !fieldState.invalid,
                                                                        'text-red-500': fieldState.invalid,
                                                                    })}/>
                                                    )
                                                }

                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="zip_code" className={classNames({"text-red-500": errors.zip_code})}>
                                    ZIP or postal code
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="zip_code"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="zip_code"
                                                            tooltip="Enter the address of the person making the 83(b) election. We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="country" className={classNames({"text-red-500": errors.country})}>
                                    Country
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <Dropdown
                                                    {...field}
                                                    filter
                                                    showClear 
                                                    value={field.value}
                                                    style={{width: '100%'}}
                                                    options={countries}
                                                    disabled={!isDataLoaded}
                                                    optionLabel="label"
                                                    optionValue="label"
                                                    placeholder="Select a Country"
                                                    id="country"
                                                    tooltip="Enter the address of the person making the 83(b) election. We will use this address to determine the IRS location where your 83(b) election should be sent."
                                                    tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                    className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                        'p-invalid': fieldState.invalid,
                                                        'border-none': !fieldState.invalid,
                                                        'text-red-500': fieldState.invalid,
                                                    })}
                                                    onChange={e => {
                                                        const value:string = e.value;
                                                        setSelectedCountry(value)
                                                        field.onChange(value)
                                                    }}
                                                />
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="property_subject_election"  className={classNames({"text-red-500": errors.property_subject_election})}>
                                2. The property which is the subject of this election is <span className="font-italic">(describe property and quantity below)</span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="property_subject_election"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputTextarea  {...field}
                                                        id="property_subject_election"
                                                            autoResize
                                                            rows={5}
                                                            cols={30}
                                                            tooltip="Enter a description of the property that was transferred,including the quantity transferred. For example, “1,000 shares of Class A common stock of Corporation B."
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="date_of_transfer" className={classNames({"text-red-500": errors.date_of_transfer})}>
                                3. The date the property was transferred
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller                
                                name="date_of_transfer"
                                control={control}
                                render={({field, fieldState }) => {

                                    return (
                                        <>
                                            <Calendar
                                                {...field}
                                                value={field.value ? new Date(field.value) : undefined}
                                                appendTo="self"
                                                dateFormat="MM d, yy"
                                                id="date_of_transfer"
                                                {...dateRange}
                                                panelClassName="w-4"
                                                panelStyle={{width: '300px!important'}}
                                                tooltip="Enter the date when the property was transferred.  Normally, this is the date on your grant document. However, depending on its language, it may also be the date of the company resolution which approved your grant. We do not accept submissions that are more than 21 days past the date of transfer.

                                                    For more information on what constitutes a transfer of property for purposes of IRC § 83, see Treas. Reg. § 1.83-3(a)."
                                                tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                className="w-12"
                                                inputClassName={classNames("w-12 p-inputtext-sm bg-blue-50 border-noround flex-grow-0 flex-shrink-0",{                                                    
                                                    'p-invalid': fieldState.invalid,
                                                    'border-none': !fieldState.invalid,
                                                    'text-red-500': fieldState.invalid,
                                                })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="taxable_year" className={classNames({"text-red-500": errors.taxable_year})}>
                                4. Taxable year for which the election is being made
                                <span className="font-italic">(taxable year that includes the date the property was transferred as reported in Box 3)</span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="taxable_year"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputText  {...field}
                                                        id="taxable_year"
                                                        tooltip="For example, “calendar year 2025” or “fiscal year ending May 31, 2025.”"
                                                        tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                        className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                            'p-invalid': fieldState.invalid,
                                                            'border-none': !fieldState.invalid,
                                                            'text-red-500': fieldState.invalid,
                                                        })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>
                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="property_subject_restrictions" className={classNames({"text-red-500": errors.property_subject_restrictions})}>
                                5. The property is subject to the following restrictions 
                                <span className="font-italic">(describe applicable restrictions below)</span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="property_subject_restrictions"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputTextarea  {...field}
                                                            id="property_subject_restrictions"
                                                            autoResize
                                                            rows={5}
                                                            cols={30}
                                                            tooltip="Enter a description of the restrictions applicable to the substantially nonvested property that was transferred. For example, “the terms of the restricted stock agreement provide that 1,000 shares of Class A common stock of Corporation B will be forfeited if the person making the election ceases to provide services to Corporation B as an employee prior to April 1, 2027.
                                                                    
                                                                    For more information on what constitutes substantially nonvested property for purposes of IRC § 83, see Treas. Reg. § 1.83-3(b). "
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '31vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="text-color py-1">
                            6. The total fair market value of the property at the time of transfer is
                            <span className="ml-1 text-red-300">*</span>
                        </div>
                        <div className="w-full flex">
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="price_per_share" className={classNames({"text-red-500": errors.price_per_share})}>
                                    a. Value per item
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="price_per_share"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="price_per_share"
                                                                className="w-12"
                                                                mode="currency"
                                                                currency="USD"
                                                                placeholder="$"
                                                                minFractionDigits={2} 
                                                                maxFractionDigits={5} 
                                                                tooltip="Enter the fair market value per item of property at the time of transfer. The fair market value is determined without regard to any lapse restriction, as defined in Treas. Reg. § 1.83-3(i)."
                                                                  tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                                onChange={(e) => {
                                                                    field.onChange(e.value)
                                                                }}
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                    <span className="mx-3">&#215;</span>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="number_of_shares" className={classNames({"text-red-500": errors.number_of_shares})}>
                                    b. Quantity
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="number_of_shares"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="number_of_shares"
                                                                tooltip="Enter the quantity transferred."
                                                                  tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                                onChange={(e) => {field.onChange(e.value)}}
                                                                className="w-12"
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                    <span className="mx-2">&#61;</span>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="FMV" className={classNames({"text-red-500": errors.fMV})}>
                                    c. Total fair market value
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="FMV"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="FMV"
                                                                className="w-12"
                                                                mode="currency"
                                                                currency="USD"
                                                                placeholder="$"
                                                                  minFractionDigits={2}
                                                                  maxFractionDigits={5}
                                                                  tooltip="Enter the total fair market value of the property (Box 6(a) multiplied by Box 6(b)).  "
                                                                  tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                                onChange={(e) => {field.onChange(e.value)}}
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="text-color py-1">
                            7. For the property transferred, the taxpayer paid a total of
                            <span className="ml-1 text-red-300">*</span>
                        </div>
                        <div className="w-full flex">
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="price_paid" className={classNames({"text-red-500": errors.price_paid})}>
                                    a. Price paid per item
                                    <span className="ml-2 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="price_paid"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="price_paid"
                                                                mode="currency"
                                                                currency="USD"
                                                                placeholder="$"
                                                                minFractionDigits={2} 
                                                                maxFractionDigits={5} 
                                                                tooltip="Enter the price paid (if any) per item of property. For more information on what constitutes the amount paid for property for purposes of IRC § 83, see Treas. Reg. § 1.83-3(g)."
                                                                  tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                                onChange={(e) => {field.onChange(e.value)}}
                                                                className="w-12"
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                    <span className="mx-3">&#215;</span>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="paid_number_of_shares" className={classNames({"text-red-500": errors.paid_number_of_shares})}>
                                    b. Quantity
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="paid_number_of_shares"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="paid_number_of_shares"
                                                                tooltip="Enter the quantity transferred."
                                                                tooltipOptions={{position:"top", showDelay:500}}
                                                                onChange={(e) => {field.onChange(e.value)}}
                                                                className="w-12"
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                    <span className="mx-2">&#61;</span>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 flex flex-column gap-1">
                                <label htmlFor="total_paid" className={classNames({"text-red-500": errors.total_paid})}>
                                    c. Total price paid
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="total_paid"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <div className="w-full flex align-items-center">
                                                    <InputNumber  {...field}
                                                                id="total_paid"
                                                                mode="currency"
                                                                currency="USD"
                                                                placeholder="$"
                                                                  minFractionDigits={2}
                                                                  maxFractionDigits={5}
                                                                  tooltip="Enter the total price paid for the property (Box 7(a) multiplied by Box 7(b)."
                                                                tooltipOptions={{position:"top", showDelay:500}}
                                                                onChange={(e) => {field.onChange(e.value)}}
                                                                className="w-12"
                                                                inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                                    'p-invalid': fieldState.invalid,
                                                                    'border-none': !fieldState.invalid,
                                                                    'text-red-500': fieldState.invalid,
                                                                })}/>
                                                </div>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="income" className={classNames({"text-red-500": errors.income})}>
                                8. The amount to include in gross income for the taxable year is  
                                <span className="font-italic ml-1">
                                    (the result of the amount reported in Box 6(с) minus the amount reported in Box 7(с))
                                </span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="income"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputNumber  {...field}
                                                        id="income"
                                                        mode="currency"
                                                        currency="USD"
                                                        placeholder="$"
                                                        readOnly
                                                        onChange={(e) => {field.onChange(e.value)}}
                                                        tooltip="Enter the total amount (if any) included in gross income for the taxable year indicated in Box 4. Calculate this amount by subtracting the total amount paid for property reported in Box 7(c) from the total fair market value of the property at the time of transfer reported in Box 6(c)."
                                                        tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                        inputClassName={classNames("w-11 p-inputtext-sm bg-blue-50 border-noround",{
                                                            'p-invalid': fieldState.invalid,
                                                            'border-none': !fieldState.invalid,
                                                            'text-red-500': fieldState.invalid,
                                                        })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2">
                        <div className="border-bottom-1 pb-1">
                            9. Name, TIN, and address of the person for whom the taxpayer is providing
                            services in connection with the transfer of property:
                            <span className="ml-1 text-red-300">*</span>
                        </div>
                        <div className="w-full flex border-bottom-1">
                            <div className="w-8 p-1 flex flex-column gap-1">
                                <label htmlFor="company_name" className={classNames({"text-red-500": errors.company_name})}>
                                    Name
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_name"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="company_name"
                                                            tooltip="Enter the name of the person for whom the person making the 83(b) election is providing services in connection with the transfer of property. Typically, this would be your employer or the company you’re providing services to."
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-4 p-1 border-left-1 flex flex-column gap-1">
                                <label htmlFor="company_id" className={classNames({"text-red-500": errors.company_id})}>
                                    TIN <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_id"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <> 
                                                <InputText  {...field}
                                                            id="company_id"
                                                            readOnly
                                                            tooltip="Enter the TIN of the person for whom the person making the 83(b) election is providing services in connection with the transfer of property. Typically, this would be your employer or the company you’re providing services to, in which case the TIN would be that entity’s EIN. "
                                                            tooltipOptions={{position:"top", showDelay:500, style: {maxWidth: '30vw'}}}
                                                            onFocus={() => {
                                                                setSelectTinDialogOpen(true);
                                                                setCompanyTinSelected(true)
                                                            }}   
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex flex-column gap-1">
                            <label htmlFor="company_address" className={classNames({"text-red-500": errors.company_address})}>
                                Address
                                <span className="font-normal font-italic text-base">(number and street)</span>
                                <span className="ml-1 text-red-300">*</span>
                            </label>
                            <Controller
                                name="company_address"
                                control={control}
                                render={({field, fieldState }) => {
                                    return (
                                        <>
                                            <InputText  {...field}
                                                        id="company_address"
                                                        tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                                services in connection with the transfer of property.  Typically, this would be your employer
                                                                 or the company you’re providing services to."
                                                        tooltipOptions={{position:"top"}}
                                                        className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                            'p-invalid': fieldState.invalid,
                                                            'border-none': !fieldState.invalid,
                                                            'text-red-500': fieldState.invalid,
                                                        })}/>
                                            {
                                                fieldState.invalid ? (
                                                    <small className="text-red-500">
                                                        {fieldState.error?.message}
                                                    </small>
                                                ) : null
                                            }
                                        </>

                                    )
                                }}
                            />
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        <div className="w-full flex">
                            <div className="w-3 p-1 -my-2 flex flex-column gap-1">
                                <label htmlFor="company_city" className={classNames({"text-red-500": errors.company_city})}>
                                    City
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_city"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="company_city"
                                                            tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                                     services in connection with the transfer of property.  Typically, this would be your employer 
                                                                     or the company you’re providing services to."
                                                            tooltipOptions={{position:"top", showDelay:500}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="company_state" className={classNames({"text-red-500": errors.company_state})}>
                                    State or province
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_state"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                {
                                                    selectedCompanyCountry === 'United States' ? (
                                                        <Dropdown
                                                            {...field}
                                                            filter
                                                            showClear
                                                            style={{width: '100%'}}
                                                            options={countryStates}
                                                            disabled={!isDataLoaded}
                                                            optionLabel="label"
                                                            optionValue="label"
                                                            placeholder="Select a State"
                                                            id="company_state"
                                                            tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                                     services in connection with the transfer of property.  Typically, this would be your employer 
                                                                     or the company you’re providing services to."
                                                            tooltipOptions={{position:"top", showDelay:500}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}
                                                        />
                                                    ) :(
                                                        <InputText  {...field}
                                                                    id="company_state"
                                                                    tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                                    services in connection with the transfer of property.  Typically, this would be your employer 
                                                                    or the company you’re providing services to."
                                                                    tooltipOptions={{position:"top"}}
                                                                    className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                        'p-invalid': fieldState.invalid,
                                                                        'border-none': !fieldState.invalid,
                                                                        'text-red-500': fieldState.invalid,
                                                                    })}/>
                                                    )
                                                }

                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="company_zip" className={classNames({"text-red-500": errors.company_zip})}>
                                    ZIP or postal code
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_zip"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <InputText  {...field}
                                                            id="company_zip"
                                                            tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                                     services in connection with the transfer of property.  Typically, this would be your employer 
                                                                     or the company you’re providing services to."
                                                            tooltipOptions={{position:"top", showDelay:500}}
                                                            className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                                'p-invalid': fieldState.invalid,
                                                                'border-none': !fieldState.invalid,
                                                                'text-red-500': fieldState.invalid,
                                                            })}/>
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                            <div className="w-3 p-1 -my-2 border-left-1 flex flex-column gap-1">
                                <label htmlFor="company_country" className={classNames({"text-red-500": errors.company_country})}>
                                    Country
                                    <span className="ml-1 text-red-300">*</span>
                                </label>
                                <Controller
                                    name="company_country"
                                    control={control}
                                    render={({field, fieldState }) => {
                                        return (
                                            <>
                                                <Dropdown
                                                    {...field}
                                                    filter
                                                    showClear
                                                    value={field.value}
                                                    style={{width: '100%'}}
                                                    options={countries}
                                                    disabled={!isDataLoaded}
                                                    optionLabel="label"
                                                    optionValue="label"
                                                    placeholder="Select a Country"
                                                    id="company_country"
                                                    tooltip="Enter the address of the person for whom the person making the 83(b) election is providing 
                                                    services in connection with the transfer of property.  Typically, this would be your employer 
                                                    or the company you’re providing services to."
                                                    tooltipOptions={{position:"top", showDelay:500}}
                                                    className={classNames("p-inputtext-sm bg-blue-50 border-noround",{
                                                        'p-invalid': fieldState.invalid,
                                                        'border-none': !fieldState.invalid,
                                                        'text-red-500': fieldState.invalid,
                                                    })}
                                                    onChange={e => {
                                                        const value:string = e.value;
                                                        setSelectedCompanyCountry(value)
                                                        field.onChange(value)
                                                    }}
                                                />
                                                {
                                                    fieldState.invalid ? (
                                                        <small className="text-red-500">
                                                            {fieldState.error?.message}
                                                        </small>
                                                    ) : null
                                                }
                                            </>

                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="py-2 border-bottom-1">
                        The undersigned taxpayer is the person performing the services in connection with which the property was transferred. The taxpayer
                        will file this election with the Internal Revenue Service office with which taxpayer files his or her annual income tax return not later than
                        30 days after the date of transfer of the property. A copy of the election also will be furnished to (i) the person for whom the services
                        were performed and (ii) the transferee of the property if the taxpayer and the transferee of the property are not the same person. Under
                        penalty of perjury, the undersigned taxpayer declares that, to the best of undersigned taxpayer’s knowledge and belief, the information
                        entered on this Form 15620 is true, correct, complete, and made in good faith.
                    </section>
                    <section className="py-2 border-bottom-1 flex">
                        <div className="w-10 pr-2 -my-1 border-right-1">
                        Taxpayer signature
                        </div>
                        <div className="w-2 pl-1 -my-2 flex flex-column">
                            <div>Date signed</div>
                        </div>
                    </section>
                    <section className="py-1 flex align-items-baseline border-top-2">
                        <div className="flex-1">Catalog Number 95376D</div>
                        <div className="flex-1 text-center">
                            <a href="https://www.irs.gov/" target="_blank">www.irs.gov</a>
                        </div>
                        <div className="flex-1 text-right">
                            Form <span className="text-2xl font-bold">15620</span>
                            <span className="text-sm text-center">
                            (10-2024)
                            </span>
                        </div>
                    </section>
                     <footer className="py-4 sticky flex justify-content-between fixed bottom-0 bg-white" style={{width:"100%"}}>
                     <Button label="Access IRS instructions"
                             severity="info"
                             outlined onClick={() =>  window.open('https://www.irs.gov/pub/irs-pdf/f15620.pdf', '_blank')}/>
                        <Button label="Review"
                                type="submit"
                                loading={isSubmitted}
                                disabled={isSubmitted}
                                className="align-self-end"/>
                    </footer>
                </form>

            </div>
            <CountryBasedTinDialog
                visible={isSelectTinDialogOpen}
                setFieldValue={setValue}
                onClose={() => setSelectTinDialogOpen(false)}
                isCompanyTinSelected={isCompanyTinSelected}/>
        </div>
    )
}

export default FormFile15620;