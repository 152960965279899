import React, {useCallback, useEffect, useState} from "react";
import {useToast} from "../../hooks/useToast";
import {HttpService} from "../../services/HttpService";
import {DataTable, DataTableStateEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import moment from "moment/moment";
import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import StatusList from "./components/StatusList/StatusList";
import FedExLabel from "./components/FedExLabel/FedExLabel";
import ActionsBlock from "./components/ActionsBlock/ActionsBlock";
import {downloadFile} from "../../pages/Form2553/helpers";

type Props = {
    companyId: number;
}


const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;

function Form2553Documents(props: Props) {
    const {companyId} = props;
    const {show} = useToast();

    const [rowsPerPage] = useState<number>(10);
    const [isLoading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState<Record<any, any>[]>([]);
    const [isBulkSelected, setIsBulkSelected] = useState<boolean>(true);
    const [documents, setDocuments] = useState<Record<string, any>[]>([]);
    const [pageableInfo, setPageableInfo] = useState<Record<string, any>>({});


    const getDataMutation = useMutation({
        mutationFn: async (params: URLSearchParams): Promise<void> => HttpService.get(
            `/api/admin/form2553/search?${params.toString()}`,
            {baseURL}
        ),
        onSuccess: (res: any) => {
            setDocuments(res.data.content);
            setPageableInfo({
                first: res.data.first,
                last: res.data.last,
                empty: res.data.empty,
                page: res.data.number,
                size: res.data.size,
                totalPages: res.data.totalPages,
                totalElements: res.data.totalElements,
            })
            setLoading(false);
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {

            const errorData = {
                error: error,
                variables: variables,
                retryFn: getDataMutation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    })
    const handlePagination = useCallback((event: DataTableStateEvent) => {
        const params = new URLSearchParams();
        params.append('page', `${event.page}`);
        params.append('size', `${event.rows}`);
        getDataMutation.mutate(params);
    }, [rowsPerPage, getDataMutation]);

    const downloadCSVMutation = useMutation({
        mutationFn: async () => HttpService.post(`/api/admin/form2553/export-csv`,
            //{"ids": [16,17]},
            {baseURL}
        ),
        onSuccess: ({data}) => {
            const blob = new Blob([data], {type: "text/csv"});
            const url = URL.createObjectURL(blob);
            downloadFile(url, "data.csv");
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const handleDownloadCSV = useCallback(async () => {;
        await downloadCSVMutation.mutateAsync();
    }, [downloadCSVMutation]);

    const downloadSelectedCSVMutation = useMutation({
        mutationFn: async (params:Record<string, any>) => HttpService.post(`/api/admin/form2553/export-data`,
            {"ids": params.ids},
            {baseURL}
        ),
        onSuccess: ({data}) => {
            show({
                severity: 'success',
                detail: 'The exported file has been emailed to you!',
            })
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const handleSelectedDownload = useCallback(async () => {

        const selectedIds = selectedRows.map(row => row.id);
        downloadSelectedCSVMutation.mutate({
            "ids":selectedIds
        })

    },[selectedRows]);

    useEffect(() => {

        const params = new URLSearchParams();

        params.append('page', '0');
        params.append('size', `${rowsPerPage}`);
        getDataMutation.mutate(params)
    }, []);

    return (
        <div className="p-6">
            <div className="p-card flex flex-column">
                <div className="w-full p-3 mb-2 flex justify-content-end">
                    <Button
                        label="Export Selected"
                        icon="pi pi-download"
                        outlined
                        className="mr-4"
                        onClick={handleSelectedDownload}
                        disabled={downloadSelectedCSVMutation.isPending}
                    />

                    <Button
                        label="Export"
                        icon="pi pi-download"
                        className="p-button-help"
                        onClick={handleDownloadCSV}
                        disabled={downloadCSVMutation.isPending}
                    />
                </div>
                <DataTable lazy
                           first={pageableInfo.page * pageableInfo.size}
                           value={documents}
                           rows={rowsPerPage}
                           loading={isLoading}
                           paginator={(pageableInfo.totalPages > 1)}
                           totalRecords={pageableInfo.totalElements}
                           tableStyle={{minWidth: '50rem'}}
                           onPage={handlePagination}
                           selection={selectedRows}
                           dataKey="id"
                           selectionMode="checkbox"
                           onSelectionChange={e => {
                               // @ts-ignore
                               setSelectedRows(e.value)
                           }}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    <Column field="name" header="Name"/>
                    <Column field="email" header="Email"/>
                    <Column field="orderNumber" header="Order Number"/>
                    <Column field="trackingNumber" header="Tracking Number"/>
                    <Column field="createdDateTime" header="Created Date" body={(document) => moment.utc(document.createdDateTime).format("MMM D, YYYY")}/>
                    <Column field="updatedDateTime" header="Updated Date" body={(document) => moment.utc(document.updatedDateTime).format("MMM D, YYYY")}/>
                    <Column field="statuses"
                            header="Statuses"
                            body={(document: any) => <StatusList document={document} toastRef={show}/>}/>
                    <Column header="FedEx label" body={(document, options)=> <FedExLabel document={document} toastRef={show} />}/>
                    <Column header="Actions" body={(data: any) => {
                        return <ActionsBlock document={data} toastRef={show} />
                    }}/>
                </DataTable>
            </div>
        </div>

    )
}

export default Form2553Documents;