import React, { FC } from 'react';
import {If} from "../If/If";
import {classNames} from "primereact/utils";
import styles from "./sidebar.module.scss";
import {NavLink} from "react-router-dom";

interface TNavItemProps {
    iconActive: string;
    icon: string;
    isOpened: boolean;
    isAnimate: boolean;
    label: string;
    index: number;
    to: string;
}

const NavItem: FC<TNavItemProps> = ({
iconActive,
icon,
isOpened,
isAnimate,
label,
index,
to,
}) => {
    return (
        <NavLink
            key={`${index}${label}`}
            to={to}
            className={({ isActive }) =>
                classNames(styles.navItem, {
                    [styles.navItemActive]: isActive,
                })
            }
        >
            {({ isActive }) => {
                return (
                    <>
                        <img src={isActive ? iconActive : icon} alt="" />
                        <If condition={isOpened}>
                            <p
                                className={classNames(styles.label, {
                                    [styles.delayedSmoothAppear]: isAnimate,
                                })}
                            >
                                {label}
                            </p>
                        </If>
                    </>
                );
            }}
        </NavLink>
    )
}

export default NavItem;
