import React, { FC } from 'react';
import styles from './policyAcceptModal.module.scss';
import ButtonPrimary from "../../uiComponents/ButtonPrimary/ButtonPrimary";
import {TPolicyVersion} from "../../@types/global";

interface TPolicyAcceptModalProps {
    privacyPolicyNotAccepted: boolean;
    termsNotAccepted: boolean;
    onCloseModal: () => void;
    onAccept: () => void;
    loading: boolean;
    policyVersion:TPolicyVersion
}

const PolicyAcceptModal: FC<TPolicyAcceptModalProps> = ({
privacyPolicyNotAccepted,
termsNotAccepted,
onCloseModal,
onAccept,
loading,
    policyVersion,
}) => {

    const isFirstTimeLogin = policyVersion.userPolicyVersion === "" && policyVersion.userTermsVersion === "";

    return (
        <div className={styles.policyModalOverlay}>
            <div className={styles.policyModal}>
                <button type="button" className={styles.closeBtn} onClick={onCloseModal}>
                    <i className="bi bi-x"/>
                </button>
                <p className={styles.modalHeading}>
                    {
                        isFirstTimeLogin ? " Accept our updated terms" : "Terms have been updated"
                    }
                    </p>
                <p className={styles.description}>
                    Corpora has updated the below terms since you last visited.
                    By continuing, you confirm that you have reviewed and accepted the updated terms.
                    Accepting the updated terms is required to access the platform.
                </p>
                <div className={styles.listWrapper}>
                    <ul>
                        {
                            (termsNotAccepted || policyVersion.userTermsVersion !== policyVersion.currentTermsVersion) && (
                                <li>
                                    <a href="https://83b.corpora.us/terms-of-service" target="_blank">Terms of Service</a>
                                </li>
                            )
                        }
                        {
                            (privacyPolicyNotAccepted || policyVersion.userPolicyVersion !== policyVersion.currentPolicyVersion) && (
                                <li>
                                    <a href="https://83b.corpora.us/privacy-policy" target="_blank">Privacy Policy</a>
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div className={styles.actionBtnWrapper}>
                    <ButtonPrimary
                        onClick={onAccept}
                        label="Accept"
                        className={styles.actionBtn}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default PolicyAcceptModal;
