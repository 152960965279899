import React, {FC} from 'react';
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import styles from './embeddedPayment.module.scss';
import {loadStripe} from "@stripe/stripe-js";
import {Button} from "primereact/button";

const isStaging = import.meta.env.VITE_PROFILE === 'staging';
const paymentKey = isStaging ?
    "pk_test_51NT2e4IivIfmPuEIxcPIdXQmsLAufjVevAxDGLCLLAmnXA72qzeEJlomIBICXI0CrpZM36yD7hc6FZnUEeUb12NX00eQjZpa4V" :
    "pk_live_51NT2e4IivIfmPuEIRJehwqQSK6gjUNLmMVUUbe0DrJajVHqpzb8wDaCVmjIuyodfvKBKzbheXi3Om8eTqixQhek4007QmuWiN4"

interface TClientSecretProps {
    clientSecret: string;
    onClose?: () => void;
}

const EmbeddedPayment: FC<TClientSecretProps> = (props: TClientSecretProps) => {

    const {
        onClose = null,
        clientSecret,
    } = props;
    const stripePromise = loadStripe(paymentKey);
    return (
        <div className={styles.embeddedPaymentOverlay}>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{clientSecret}}
            >
                <div className="relative">
                    {
                        !!onClose ? (
                            <Button text
                                    rounded
                                    icon="pi pi-times"
                                    onClick={onClose}
                                    className="text-white absolute right-0 top-0"/>
                        ) : null
                    }
                    <EmbeddedCheckout/>
                </div>

            </EmbeddedCheckoutProvider>
        </div>
    )
}

export default EmbeddedPayment;
