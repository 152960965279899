import React, {useRef, useState} from 'react';
import styles from './acknowledgement.module.scss';
import {DragDropFile} from "../../components/Drag&DropFile/Drag&DropFile";
import {useMutation} from "@tanstack/react-query";
import axios, { type AxiosResponse } from "axios";
import {AcknowledgementService} from "./Service";
import {Nullable} from "primereact/ts-helpers";
import {classNames} from "primereact/utils";

const currentUploadFileInitialValue = {
    file: null,
    uuid: '',
};

type TFile = {
    fileName: string;
    completed: boolean;
    success: boolean;
}

const UploadAcknowledgement = () => {
    const filesRef = useRef<Blob[]>([]);
    const uploadingIndex = useRef(0);
    const currentUploadFile = useRef<{
        file: Nullable<Blob>,
        uuid: string;
    }>(currentUploadFileInitialValue);
    const [files, setFiles] = useState<TFile[]>([]);

    const startUploading = () => {
        if (filesRef.current[uploadingIndex.current]) {
            const uploadingFile = filesRef.current[uploadingIndex.current];
            currentUploadFile.current.file = filesRef.current[uploadingIndex.current]
            //@ts-ignore
            const fileName = uploadingFile?.name;
            getUploadUrl.mutate({ fileName: fileName });
        }
    }

    const triggerNextOrFinish = () => {
        if (uploadingIndex.current < filesRef.current.length - 1) {
            uploadingIndex.current = uploadingIndex.current + 1;
            startUploading();
        } else {
            uploadingIndex.current = 0;
            filesRef.current = [];
            currentUploadFile.current.file = null;
            currentUploadFile.current.uuid = '';
            setFiles([]);
        }
    }

    const updateCurrentFileFlags = (success: boolean, index: number) => {
        setFiles((prev) => {
            prev[index].completed = true;
            prev[index].success = success;
            return prev;
        });
    }

    const triggerUploadComplete = useMutation({
        mutationFn: AcknowledgementService.triggerUploadComplete,
        onSuccess: () => {
            updateCurrentFileFlags(true, uploadingIndex.current);
            triggerNextOrFinish();
        },
        onError: () => {
            updateCurrentFileFlags(false, uploadingIndex.current);
            triggerNextOrFinish();
        },
    });

    const uploadFileRequest = useMutation({
        mutationFn: async (newDoc: { uploadUrl: string; fileSrc: Blob }) => {

            const fl = URL.createObjectURL(newDoc.fileSrc);
            const blob = await fetch(fl).then(async (r) => r.blob());
            const { data: response } = await axios.put(newDoc.uploadUrl, blob, {});
            return response.data;
        },
        onSuccess: () => {
            triggerUploadComplete.mutate({ uuid: currentUploadFile.current.uuid });
        },
        onError: () => {
            updateCurrentFileFlags(false, uploadingIndex.current);
            triggerNextOrFinish();
        },
    });

    const getUploadUrl = useMutation({
        mutationFn: AcknowledgementService.getUploadUrl,
        onSuccess: (response: AxiosResponse) => {
            const fileUrl = response.data.fileUrl;
            currentUploadFile.current.uuid = response.data.uuid;
            uploadFileRequest.mutate({
                uploadUrl: fileUrl,
                fileSrc: currentUploadFile.current.file as Blob,
            });
        },
        onError: () => {
            updateCurrentFileFlags(false, uploadingIndex.current);
            triggerNextOrFinish();
        },
    });

    const onSelect = (files: Blob[]) => {
        const filesArr = [...files];
        const mappedFiles: TFile[] = filesArr.map((File) => {
            return ({
                //@ts-ignore
                fileName: File?.name,
                completed: false,
                success: false,
            })
        });
        setFiles(mappedFiles);
        filesRef.current = filesArr;
        startUploading();
    }

    return (
        <div className={styles.uploadAcknowledgement}>
            {
                files.length > 0 ? (
                    <div className={styles.filesWrapper}>
                        {
                            files.map((fileItem: TFile) => {
                                const {fileName, completed, success} = fileItem;
                                return (
                                    <div className={styles.fileItem}>
                                        <span className={styles.fileName}>{fileName}</span>
                                        {
                                            completed ? (
                                                <div className={styles.stateWrapper}>
                                                    {success ? (
                                                        <>
                                                            <span className={styles.txt}>Uploaded</span>
                                                            <i className={classNames('bi bi-check-lg', styles.successColor)} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className={styles.txt}>Failed to upload</span>
                                                            <i className={classNames('bi bi-x-lg', styles.errorColor)} />
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className={styles.loading} />
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : (
                    <DragDropFile
                        label="Drag and drop the PDF file."
                        onChange={onSelect}
                        multiple={true}
                        allowedExtensions=".pdf"
                    />
                )
            }
        </div>
    )
}

export default UploadAcknowledgement;
