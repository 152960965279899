import React, {useCallback, useRef} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import { Toast } from "primereact/toast";
import {useDropzone} from 'react-dropzone';

// @ts-ignore
import UploadIcon from "../../../../assets/icons/extract-doc-icon.svg?react";
import {TStep, stepsKeys} from "../../../../uiComponents/StepsControl/StepsControl";
import {Form2553Service} from "../../Service";
import {ProgressBar} from "primereact/progressbar";



type UploadProps = {
    formId: number | undefined;
    document: Record<string, any>;
    companyId: number;
    handleStepChange:(doc:any, status:string) => void;
}
const Upload = (props:UploadProps) => {
    const {
        formId,
        document,
        companyId,
        handleStepChange
    } = props;

    const toast = useRef<Toast | null>(null)
    const [isLoading, setIsLoading,] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const [isUploadStarted, setUploadStarted] = React.useState(false);

    const handleUpload = useCallback(async (files: any[]) => {
         if (files.length === 0) return alert("No file selected");

        setUploadStarted(true)
        let response;
        try {
            if(document.userType === "SHARE_HOLDER" ) {
                response = await Form2553Service.getShareHolderDocumentUrl({
                    companyId,
                    formId,
                    ...document
                })
            } else if (document.userType === "OFFICER") {
                response = await Form2553Service.getOfficerDocumentUrl({
                    companyId,
                    formId,
                    ...document
                })
            }
            const {
                url,
                confirmUrl
            } = response;
            await fetch(url, {
                method: 'PUT',
                body: files[0],
                headers: {
                    'Content-Type': files[0].type,
                },
            });
            await  Form2553Service.confirmUpload(confirmUrl);
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully uploaded"
            });
            setIsDialogOpen(false);
            setUploadStarted(false);
            handleStepChange(document, stepsKeys.SUBMIT)
        } catch (e) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        }

    }, [document]);

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop:handleUpload,
        accept: {'application/pdf': ['.pdf']},
        multiple:false,
    });


    return (
        <>
            <Toast ref={toast} />
            <div className="w-full p-3 flex bg-white justify-content-between border-round border-2 border-dashed border-300 border-round-lg">
                <div className="p-2 w-3rem flex justify-content-center align-items-center h-3rem">
                    <UploadIcon width={100} height={50}/>
                </div>
                <Button outlined
                        label="Upload"
                        severity="secondary"
                        onClick={() => setIsDialogOpen(true)}
                        icon={isLoading ? "pi pi-spin pi-spinner" : "pi pi-upload"}/>
            </div>
            <Dialog visible={isDialogOpen}
                    style={{width:'50vw'}}
                    onHide={() => setIsDialogOpen(false)}
                    footer={
                        <div className="w-full flex justify-content-center">
                            <Button outlined
                                    label="Cancel"
                                    severity="secondary"
                                    onClick={() => setIsDialogOpen(false)} />
                            <Button outlined label="Choose a file" onClick={open}/>
                        </div>
                    }
                    header={
                        <>
                            <h2>Upload Shareholder Consent Statement</h2>
                            <span className="font-medium text-gray-600 text-lg">
                                Please upload the consent statement
                                document to proceed. Ensure the file
                                is in the correct format and meets the
                                required guidelines.
                            </span>
                        </>
                    }
            >
                <div {...getRootProps()} className="relative flex flex-wrap justify-content-center align-content-center cursor-pointer p-2 w-ful bg-gray-50 h-15rem border-2 border-dashed border-round border-gray-200">
                    <input {...getInputProps()} />
                    <div>
                        Drop or choose PDF file
                    </div>
                </div>
                {
                    isUploadStarted ? (
                        <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
                    ) : null
                }

            </Dialog>
        </>

    )
}

export default Upload;
