import * as Yup from "yup";

export default  Yup.object().shape({
    taxpayer_name: Yup.string().required("This field is required"),
    taxpayer_id: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    zip_code:Yup.string().when('company_country', {
        is: 'United States',
        then: Yup.string().matches(
                /^(((?!(0))\d{5})|((?!(0))\d{5})-\d{4})$/,
                'The ZIP code is invalid. Please enter a valid US ZIP code e.g. 12345 or 12345-1234'
            ),
    }).required("This field is required"),
    country: Yup.string().required("This field is required"),
    property_subject_election: Yup.string().required("This field is required"),
    date_of_transfer: Yup.date().required("This field is required"),
    taxable_year: Yup.string().required("This field is required"),
    property_subject_restrictions: Yup.string().required("This field is required"),
    price_per_share: Yup.number().typeError("This field is required").required("This field is required"),
    number_of_shares: Yup.number().typeError("This field is required").required("This field is required"),
    FMV: Yup.number().test(
            'FMV-check',
            'FMV should be the product of the value per item and Quantity values',
            function (value) {
                const {price_per_share, number_of_shares} = this.parent;
                return price_per_share * number_of_shares === value;
    }).required("This field is required"),

    price_paid: Yup.number().typeError("This field is required").required("This field is required"),
    paid_number_of_shares: Yup.number().typeError("This field is required").required("This field is required"),
    total_paid: Yup.number().test(
        'total-paid-check',
        'Total paid should be the product of Price paid per item and Quantity values',
        function (value) {
            const {price_paid, paid_number_of_shares} = this.parent;
            return price_paid * paid_number_of_shares === value;
    }).test('greater-than-total_paid', '“Total price paid” cannot be larger than “Total fair market value”', function (value) {
      return  (value ?? 0) <= this.parent.FMV;
    }).required("This field is required"),

    income: Yup.number().moreThan(-1,"The value of this field cannot be negative.").test(
        'income-check',
        'Income should be result of the amount reported in Box 6(c) minus the amount reported in Box 7',
        function (value) {
            const {total_paid, FMV} = this.parent;
            return FMV - total_paid === value;
    }).required("This field is required"),
    company_name: Yup.string().required("This field is required"),
    company_id: Yup.string().required("This field is required"),
    company_address: Yup.string().required("This field is required"),
    company_city: Yup.string().required("This field is required"),
    company_state: Yup.string().required("This field is required"),
    company_zip: Yup.string().when('company_country', {
        is: 'United States',
        then: Yup.string().matches(
                /^(((?!(0))\d{5})|((?!(0))\d{5})-\d{4})$/,
                'The company ZIP code is invalid. Please enter a valid US ZIP code e.g. 12345 or 12345-1234'
            ),
    }).required("This field is required"),
    company_country: Yup.string().required("This field is required")
});
