import { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboard from './pages/Dashboard/Dashboard';
import FileCreationWrapper from './pages/FileCreationWrapper/FileCreationWrapper';

type File83bRoutesPropTypes = {
    filingCreationEnabled: boolean;
    templateSpouseCode?: string;
    paymentEnabled: boolean;
    isEveryCompany: boolean;
    templateCrypto: string;
    templateCode: string;
    companyId: number;
    fullName: string;
    userId: number;
    email: string;
    deliveryService: string;
    companies: any[]
}

function File83bRoutes(props: File83bRoutesPropTypes): ReactElement {
    const {
        email,
        userId,
        fullName,
        companies,
        companyId,
        templateCode,
        templateCrypto,
        paymentEnabled,
        isEveryCompany,
        templateSpouseCode,
        deliveryService,
        filingCreationEnabled,
    } = props;
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Dashboard
                        userId={userId}
                        companyId={companyId}
                        companies={companies}
                        filingCreationEnabled={filingCreationEnabled} />
                } />
            <Route
                path="/file-83b"
                element={
                    <FileCreationWrapper
                        email={email}
                        fullName={fullName}
                        companyId={companyId}
                        deliveryService={deliveryService}
                        templateCode={templateCode}
                        templateCrypto={templateCrypto}
                        paymentEnabled={paymentEnabled}
                        isEveryCompany={isEveryCompany}
                        templateSpouseCode={templateSpouseCode}
                        filingCreationEnabled={filingCreationEnabled}
                    />
                } />
        </Routes>
    );
}

export default File83bRoutes;