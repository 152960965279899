import React, {
    useCallback, useEffect,
    useMemo,
    useState
} from 'react';

import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {Card} from "primereact/card";
import {Skeleton} from "primereact/skeleton";
import {Divider} from "primereact/divider";
import {DataTable, DataTableValue} from "primereact/datatable";
import {Column} from "primereact/column";

import {Form2553Service} from "../../Service";

import AvatarFrame from "../../../../uiComponents/AvatarFrame/AvatarFrame";
import StepsControl, {
    stepsKeys
} from "../../../../uiComponents/StepsControl/StepsControl";

import Upload from "./Upload";
import Download from "./Download";

import SubmitUploadedFile from "./SubmitUploadedFile";
import GuidForSignUpload from "./GuidForSignUpload";

import ShareholderCardHeader from "./ShareholderCardHeader";
import {downloadFile} from "../../helpers";


interface IDetailsProps {
    companyId: number;
}

function Details(props: IDetailsProps) {
    const {
        companyId
    } = props;

    const [searchParams] = useSearchParams();
    const fileId = searchParams.get("fileId");
    const [formId, setFormId] = useState<number | undefined>(fileId ? Number(fileId) : undefined);
    const [allDocuments, setAllDocuments] = useState<any>([]);
    const [isAllDocsLoaded, setIsAllDocsLoaded] = useState(false);
    const [shareHolders, setShareHolders] = useState<any>([]);
    const [isShareholdersLoading, setIsShareholdersLoading] = useState(false);

    const downloadOriginalDocument = useCallback(async () => {
        const response = await Form2553Service.downloadDocument({
            formId: String(formId),
            companyId
        });
        const {url, fileName} = response;
        downloadFile(url, fileName);
    },[formId, companyId]);

    const onDownload = useCallback(async (doc:Record<string, any>, willStepChange: boolean = false) => {

        let response;

        if(doc.userType === "SHARE_HOLDER" ) {
            response = await Form2553Service.downloadShareHolderDocument({
                companyId,
                formId,
                ...doc
            })
        } else if (doc.userType === "OFFICER") {
            response = await Form2553Service.downloadOfficerDocument({
                companyId,
                formId,
                ...doc
            })
        }

        const {url, fileName} = response;
        downloadFile(url, fileName);
        if (willStepChange) {
            setAllDocuments((prevDocs:any) => {

                return prevDocs.map((prevDoc:any) => {
                    const tempDoc = {...prevDoc}
                    if(prevDoc.id === doc.id){
                        tempDoc.currentStep = stepsKeys.UPLOAD
                    }
                    return tempDoc;
                })
            });
        }

    }, [formId, companyId]);


    const setDocumentStatus = useCallback((document:any, status:string) => {

        setAllDocuments((prevDocs: any[]) => {
            return prevDocs.map((prevDoc:any) => {
                const tempDoc = {...prevDoc}
                if(prevDoc.id === document.id){
                    tempDoc.currentStep = status;
                }
                return tempDoc;
            })
        });
    },[]);

    const fetchAllDocuments = useCallback(async  (formId:number) => {

        try {
            let response = await  Form2553Service.loadAllDocuments(String(formId), companyId);
            let allDocuments = response.map((doc: any) => {
                const tempDoc = {...doc, currentStep: stepsKeys.DOWNLOAD};
                if (tempDoc.uploadDate !== null) {
                    if (tempDoc.signedDate !== null) {
                        tempDoc.currentStep = stepsKeys.SIGNED;
                    } else {
                        tempDoc.currentStep = stepsKeys.SUBMIT;
                    }
                }
                return tempDoc;
            }).sort((a:any, b:any) => {
                if(a.id > b.id) {
                    return -1;
                }
                if (a.id < b.id) {
                    return 1;
                }
                return 0;
            });

            setAllDocuments(allDocuments);
            setIsAllDocsLoaded(true);
        } catch (e) {
            console.error(e);
        }

    },[companyId]);

    useEffect(() => {
        let formId:number;
        Form2553Service.getSignedInfo(
            companyId
        ).then(res => {
            formId = res.id;
            setFormId(prev => {
                if(prev) {
                    return prev;
                }
                return Number(formId);
            });
            setIsShareholdersLoading(true);
            return Form2553Service.getForm2553Data(String(formId), companyId)
        }).then(res => {
            setShareHolders(res.shareHolders);
            setIsShareholdersLoading(false);

            fetchAllDocuments(formId).then(() => {
                setIsAllDocsLoaded(true);
            });
        })

    }, [companyId]);

    return (
        <div className="w-full py-6 px-7 flex flex-column row-gap-4">
            {
                !isAllDocsLoaded ? (
                    <Skeleton width="100%" height="200px"/>
                ) : (
                    <div className="w-full flex flex-wrap flex-column gap-3">
                        {
                            allDocuments.map((document:any) => {
                                return (
                                    <Card key={document.id}
                                          pt={{content:{className: "pb-0"}}}
                                          header={<ShareholderCardHeader document={document} onDownload={downloadOriginalDocument}/>}>
                                        <div className="w-full pt-4 align-items-start flex justify-content-between">
                                            <div className="flex flex-column row-gap-2" style={{width:'49%'}}>
                                                <div className="bg-gray-50 border-round-lg" >
                                                    <StepsControl currentStep={document.currentStep} useCustomSteps/>
                                                    <div className="px-6 py-4">
                                                        <Divider className="p-0 m-0 mb-6"/>
                                                        <div className='stepper-content'>
                                                            {
                                                                document.currentStep === stepsKeys.DOWNLOAD && (
                                                                    <Download onDownload={onDownload} document={document}/>
                                                                )
                                                            }
                                                            {
                                                                document.currentStep === stepsKeys.UPLOAD && (
                                                                    <Upload formId={formId}
                                                                            document={document}
                                                                            companyId={companyId}
                                                                            handleStepChange={setDocumentStatus}/>
                                                                )
                                                            }
                                                            {
                                                                (document.currentStep === stepsKeys.SUBMIT
                                                                    || document.currentStep === stepsKeys.SIGNED
                                                                ) && (
                                                                    <SubmitUploadedFile  formId={formId}
                                                                                         document={document}
                                                                                         companyId={companyId}
                                                                                         updateDocList={fetchAllDocuments}
                                                                                         handleDownload={onDownload}
                                                                                         handleStepChange={setDocumentStatus}/>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-column row-gap-2" style={{width:'49%'}}>
                                                <div className="p-card py-4 pl-5 flex justify-content-between">
                                                    <GuidForSignUpload />
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })
                        }
                    </div>
                )
            }
            {
                isShareholdersLoading ? (

                    <DataTable value={[Array.from({ length: 5 }, (v, i) => i)]} className="p-datatable-striped">
                        <Column field="ID" header="ID" style={{ width: '25%' }} body={<Skeleton />}></Column>
                        <Column field="name" header="Name" style={{ width: '25%' }} body={<Skeleton />}></Column>
                        <Column field="email" header="Email" style={{ width: '25%' }} body={<Skeleton />}></Column>
                        <Column field="ownershipType" header="Ownership Type" style={{ width: '25%' }} body={<Skeleton />}></Column>
                    </DataTable>
                ) : (
                    <DataTable value={shareHolders}>
                        <Column field="id" header="ID" className="font-medium text-lg"/>
                        <Column field="name" header="Name" body={(row) => {
                            return (
                                <div className="flex w-full align-items-baseline font-medium text-lg">
                                    <AvatarFrame fullName={row.name || ''}/>
                                    <div className="ml-2 font-medium text-lg">{row.name}</div>
                                </div>
                            )
                        }}/>
                        <Column field="email" header="Email" className="font-medium text-lg"/>
                        <Column field="ownershipType" header="Ownership Type" className="font-medium text-lg"/>
                    </DataTable>
                )
            }
        </div>
    )

}

export default Details;
