import React, {useCallback} from "react";
import {Button} from "primereact/button";
// @ts-ignore
import DownloadIcon from "../../../../assets/icons/document-icon.svg?react";


type DownloadProps = {
    document:any
    onDownload: (document:any, b: boolean) => Promise<void>;
}

function Download(props: DownloadProps) {
    const {
        document,
        onDownload
    } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    const  handleDownload = useCallback(() => {
        setIsLoading(true);
        onDownload(document, true).then(() => {
            setIsLoading(false);
        })
    },[onDownload]);

    const getFilename = useCallback(() => {
        let fileName:string = "";
         if(document.documentType === "OFFICER_DOCUMENT") {
             fileName = `${document.name}'s Officer Consent Statement.pdf`
         } else if(document.documentType === "SHAREHOLDER_DOCUMENT") {
             fileName = `${document.name}'s Shareholder Consent Statement.pdf`
         } else if(document.documentType === "COVER_LETTER_DOCUMENT") {
             fileName = `${document.name}'s Cover Letter.pdf`
         }
        return fileName
    }, [document]);

    return (
        <div className="w-full p-3 bg-blue-100 flex align-items-center justify-content-between border-round-lg">
            <div className="flex align-items-center">
                <div className="p-2 w-3rem flex justify-content-center align-items-center h-3rem bg-white border-round-lg">
                    <DownloadIcon width={100} height={50}/>
                </div>
                <div className="ml-2 font-semibold text-lg">
                    {getFilename()}
                </div>
            </div>

            <Button label="Download"
                    onClick={handleDownload}
                    icon={isLoading ? "pi pi-spin pi-spinner" : "pi pi-download"}/>
        </div>
    )
}

export default Download;