import React, {memo} from "react";

type Props = {
    document:Record<string, any>,
    handleDownload: (doc:Record<string, any>) => void,
    shareholder:Record<string, any>;
}
function TabContent(props:Props) {
    const {shareholder, handleDownload} = props;

    const downloadDocument = (doc:Record<string, any>)=> {
        handleDownload(shareholder);
    }

    return (
        <>
            <div className="w-full px-3 flex mb-4 pb-4 border-bottom-1 border-gray-300">
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        Email:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        {shareholder.email}
                    </div>
                </div>
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        Document:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3 cursor-pointer" onClick={downloadDocument}>
                        <i className="pi pi-file-o text-primary-500 mr-2"/>
                        {shareholder.fileName}
                        <i className="pi pi-download ml-2"/>
                    </div>
                </div>
            </div>

            <div className="w-full px-3 flex mb-4 pb-4 border-bottom-1 border-gray-300">
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        SSN/EIN:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        {shareholder.ein}
                    </div>
                </div>
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        Stock Number:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        12312123
                    </div>
                </div>
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        Date Acquired:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        {shareholder.dateAcquired}
                    </div>
                </div>
                <div className="flex-1">
                    <div className="font-normal text-base text-600 line-height-3">
                        Tax Year End:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        {shareholder.taxYearEndMonthAndDay}
                    </div>
                </div>
            </div>

            <div className="w-full px-3 flex mb-4 pb-4">
                <div className="w-full">
                    <div className="font-normal text-base text-600 line-height-3">
                        Address:
                    </div>
                    <div className="font-base text-lg text-black-alpha-90 line-height-3">
                        {
                            `${shareholder.address.line1}, 
                             ${shareholder.address.line2}, 
                             ${shareholder.address.city}, 
                             ${shareholder.address.state},
                             ${shareholder.address.country} ,
                             ${shareholder.address.zipCode}`
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default memo(TabContent)