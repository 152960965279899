import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  FieldValues,
  Controller,
  useForm,
} from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";


type DialogProps = {
  visible: boolean;
  onClose: () => void;
  isCompanyTinSelected: boolean;
  setFieldValue: (field: string, value: string) => void;
}

function CountryBasedTinDialog(props: DialogProps): ReactElement {
  const {
    visible,
    onClose,
    setFieldValue,
    isCompanyTinSelected
  } = props;

  const [isUsApplicbleTinSelected, setUsApplicbleTinSelected] = useState(true);
  const yupScheme = useMemo(() => {
    if (isCompanyTinSelected) {
      return Yup.object().shape({
        ssn: Yup.string().when("applicableTin", {
          is: "USA",
          then: Yup.string().matches(/^\d{2}-\d{7}$/, 'TIN must be in the format XX-XXXXXXX'),      
        }).required("This field is required")
      });
    }

    return Yup.object().shape({
      ssn: Yup.string().when("applicableTin", {
        is: "USA",
        then: Yup.string().matches(/^\d{3}-\d{2}-\d{4}$/, 'TIN must be in the format XXX-XX-XXXX'),
      }).required("This field is required")
    });

  }, [isCompanyTinSelected]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(yupScheme),
  });

  const handleClose = useCallback(() => {
    clearErrors();  
    onClose();
    reset();
    setUsApplicbleTinSelected(true);
  } , [clearErrors, reset, onClose]);

  const onSubmitHandler = useCallback((data: FieldValues) => {
   
    if (isCompanyTinSelected) {
      setFieldValue("company_id", data.ssn);
    }
    else {
      setFieldValue("taxpayer_id", data.ssn);
    }
    handleClose();
  },[isCompanyTinSelected, setFieldValue, handleClose]);

  useEffect(() => {
    return () =>  clearErrors();
  }, []);
  return (

    <Dialog
      visible={visible}
      header="What kind of input would you like to provide?"
      onHide={handleClose} style={{ width: '30vw' }}>
      <div className="flex flex-column gap-3">

        <form className="w-full flex flex-column gap-3 p-2" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="flex flex-wrap gap-3 p-2">
            <div className="flex align-items-center">
              <RadioButton
                value={true}
                inputId="USA"
                name="applicableTin"
                checked={isUsApplicbleTinSelected}
                onChange={(e) => setUsApplicbleTinSelected(e.value)}
              />
              <label htmlFor="USA" className="ml-2 font-medium">TIN</label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                value={false}
                inputId="N/A"
                name="applicableTin"
                checked={!isUsApplicbleTinSelected}
                onChange={(e) => setUsApplicbleTinSelected(e.value)} />
              <label htmlFor="N/A" className="ml-2 font-medium">Text</label>
            </div>
          </div>
          <div className="flex flex-column gap-2">
            <label htmlFor="ssn" className={classNames("font-medium", { "text-red-500": errors.ssn })}>
              {isCompanyTinSelected ? "Company TIN" : "Taxpayer’s TIN"}
              <span className="ml-1 text-red-300">*</span>
            </label>
            <Controller
              name="ssn"
              control={control}
              rules={{ required: 'Name is required.' }}
              render={({ field, fieldState }) => {
                return (
                  <>
                    {
                      isUsApplicbleTinSelected ? (
                        <InputMask {...field}
                          id={field.name}
                          {...(isCompanyTinSelected ? {
                            mask: "99-9999999",
                            placeholder: "99-9999999"
                          } : {
                            mask: "999-99-9999",
                            placeholder: "999-99-9999"
                          }
                          )
                          }
                          className={classNames("p-inputtext-sm bg-blue-50 border-noround", {
                            'p-invalid': fieldState.invalid,
                            'border-none': !fieldState.invalid,
                            'text-red-500': fieldState.invalid,
                          })} />
                      ) : (
                        <InputText  {...field}
                          id={field.name}
                          className={classNames("p-inputtext-sm bg-blue-50 border-noround", {
                            'p-invalid': fieldState.invalid,
                            'border-none': !fieldState.invalid,
                            'text-red-500': fieldState.invalid,
                          })} />
                      )
                    }
                    {
                      fieldState.invalid ? (
                        <small className="text-red-500">
                          {fieldState.error?.message}
                        </small>
                      ) : null
                    }
                  </>
                )
              }}
            />
          </div>
          <div className="flex justify-content-end">
            <Button label="Accept" type="submit"/>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

export default CountryBasedTinDialog;