import React, {SetStateAction, useCallback, useEffect} from "react";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";
import {TForm2553Information} from "../../CreationControl/types";

import styles from './Preview.module.scss';
import {Accordion, AccordionTab } from "primereact/accordion";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useQuery} from "@tanstack/react-query";
import {PrivacyService} from "../../../../PolicyPages/PolicyService";
import {TermsTypeKeys} from "../../../../TermsUpdate/utils";
import {ProgressSpinner} from "primereact/progressspinner";
import {Checkbox} from "primereact/checkbox";
import moment from "moment";

type pageProps = {
    pdfLoaded: boolean;
    companyId: number;
    formId: string;
    companies:any[];
    setIsPdfLoaded:React.Dispatch<SetStateAction<boolean>>;
    previewDocument: any;
    form2553Information: TForm2553Information;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
}
function PreviewAndTerms(props:pageProps) {
    const {
        form2553Information,
        previewDocument,
        setIsPdfLoaded,
        companyId,
        companies,
        pdfLoaded,
        setStep,
        formId
    } = props;

    const {
        businessInformation,
        shareHolders,
    } = form2553Information;

    const [showModal, setShowModal] = React.useState(false);
    const [isTermsReaded, setIsTermsReaded] = React.useState(false);


    const [isCharged] = React.useState(() => {
        let charged = false
        companies.forEach(company => {
            if (company.companyId === companyId) {
                charged = company.formPricing.FILE_83B.allowedLimit > 0
            }
        })
        return charged;
    });

    const onPreviousStep = () => {
        setStep(stepsKeys.SHAREHOLDER_INFORMATION);
    }

    const { data: termsContent, isFetched } = useQuery({
        gcTime: 0,
        staleTime: 0,
        queryKey: ['terms-for-83b'],
        queryFn: () => PrivacyService.getContentByType(TermsTypeKeys.TERMS_FORM_2553),
    });


    const onNextStep = useCallback(() => {

        if (isTermsReaded) {
            setShowModal(false);
            setStep(stepsKeys.PAY);
        } else {
            setShowModal(true);
        }

    },[isTermsReaded, isCharged]);

    useEffect(() => {
        setIsPdfLoaded(false);
        previewDocument.mutateAsync({
            companyId,
            formId
        }).then(() => {
           setIsPdfLoaded(true);
        });
    }, []);

    return (
        <div className="relative h-full bg-white p-4 ">
            <div className="overflow-y-auto" style={{maxHeight: `calc(100% - 75px)`}}>
                <Accordion multiple>
                    <AccordionTab header="Business Information" headerClassName="bg-white">
                        <div className='w-full block'>
                            <div className="row flex w-full p-2">
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Business Name</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.entityName}
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">EIN</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.ein}
                                    </div>
                                </div>
                            </div>
                            <div className="row flex w-full p-2">
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Country</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.address.country}
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Address</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.address.line1}
                                    </div>
                                </div>
                            </div>
                            <div className="row flex w-full p-2">
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">City</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.address.city}
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">State</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.address.state}
                                    </div>
                                </div>
                            </div>
                            <div className="row flex w-full p-2">
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Zip Code</div>
                                    <div className="w-full text-sm font-bold">
                                        {businessInformation.address.zipCode}
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Date of Incorporation</div>
                                    <div className="w-full text-sm font-bold">
                                        {moment(businessInformation.incorporationDate).format('MM/DD/YYYY')}
                                    </div>
                                </div>
                            </div>
                            <div className="row flex w-full p-2">
                                <div className="flex-1 flex flex-column gap-1">
                                    <div className="w-full">Tax Year Start Date.</div>
                                    <div className="w-full text-sm font-bold">
                                        {moment(businessInformation.electionEffectiveDate).format('MM/DD/YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Shareholder Information">
                        {
                            shareHolders.map((shareHolder ) => {
                                return (
                                    <div key={shareHolder.id} className='w-full block'>
                                        <div className="row flex w-full p-2">
                                            <div className="flex-1 flex flex-column gap-1">
                                                <div className="w-full">Shareholder Name</div>
                                                <div className="w-full text-sm font-bold">
                                                    {shareHolder.name}
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-column gap-1">
                                                <div className="w-full">Share</div>
                                                <div className="w-full text-sm font-bold">
                                                    {shareHolder.numberOfShares}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row flex w-full p-2">
                                            <div className="flex-1 flex flex-column gap-1">
                                                <div className="w-full">Address</div>
                                                <div className="w-full text-sm font-bold">
                                                    {`${shareHolder.address.line1}, ${shareHolder.address.line2}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </AccordionTab>
                </Accordion>
            </div>
            <div className="absolute bottom-0 left-0 flex bg-white w-full justify-content-end column-gap-2 p-4">
                <Button
                    label="Back"
                    type="button"
                    severity="secondary"
                    onClick={onPreviousStep}
                />
                <Button
                    label="Next"
                    type="button"
                    disabled={!pdfLoaded}
                    onClick={onNextStep}
                />
            </div>
            <Dialog header="Terms and Condition"
                    visible={showModal}
                    style={{width:'50vw'}}
                    onHide={() => {
                        setShowModal(false);
                    }}
                    footer={
                        <div className="flex justify-content-between align-items-baseline">
                            <div className="pt-4">
                                <Checkbox checked={isTermsReaded}
                                          value={isTermsReaded}
                                          onChange={() => setIsTermsReaded(!isTermsReaded)}/>
                                <label className="ml-2">
                                    I have read and agree to these terms.
                                </label>
                            </div>
                            <Button type="button"
                                    label="Next"
                                    disabled={!isTermsReaded}
                                    onClick={onNextStep}
                            />
                        </div>
                    }>
                {
                    isFetched ? (
                        <div className="w-full pl-4 pr-4">
                            <div className="content" dangerouslySetInnerHTML={{__html: termsContent?.data?.text}}/>
                        </div>
                    ) : (
                        <div className="w-full h-15rem">
                            <ProgressSpinner style={{ width: '50px', height:'50px' }}  strokeWidth="4"/>
                        </div>
                    )
                }

            </Dialog>
        </div>
    )
}

export default PreviewAndTerms;