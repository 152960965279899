import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Avatar} from "primereact/avatar";
import {SplitButton} from "primereact/splitbutton";
import {primeFlexColors} from "./bgColors";

import {Toast} from "primereact/toast";
import {Form2553Service} from "../../../Service";
import {stepsKeys} from "../../../../../uiComponents/StepsControl/StepsControl";
import {useDropzone} from "react-dropzone";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Chip} from "primereact/chip";
import { ProgressBar } from 'primereact/progressbar';
import {classNames} from "primereact/utils";

type TabHeaderProps = {
    index: number;
    formId: string | undefined;
    companyId: number;
    userRole:string;
    handleDownload: (doc:Record<string, any>) => void,
    handleSubmit: (doc:Record<string, any>) => void,
    shareholder:Record<string, any>,
    document:Record<string, any>,
}
function TabHeader(props:TabHeaderProps) {

    const {
        index,
        formId,
        companyId,
        document,
        userRole,
        handleSubmit,
        handleDownload,
        shareholder,
    } = props;

    const toast = useRef<Toast | null>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [isUploadStarted, setUploadStarted] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const getRandomColor = useCallback((index:number) => {
       // return primeFlexColors[Math.floor(Math.random() * primeFlexColors.length)]
        return  primeFlexColors[index]
    }, [index]);

    const handleUpload = useCallback(async (files: any[]) => {
        if (files.length === 0) return alert("No file selected");
        setUploadStarted(true)

        let response;
        try {
            if(shareholder.userType === "SHARE_HOLDER" ) {
                response = await Form2553Service.getShareHolderDocumentUrl({
                    companyId,
                    formId,
                    ...shareholder
                })
            } else if (shareholder.userType === "OFFICER") {
                response = await Form2553Service.getOfficerDocumentUrl({
                    companyId,
                    formId,
                    ...shareholder
                })
            }
            const {
                url,
                confirmUrl
            } = response;
            await fetch(url, {
                method: 'PUT',
                body: files[0],
                headers: {
                    'Content-Type': files[0].type,
                },
            });
            await  Form2553Service.confirmUpload(confirmUrl);
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully uploaded"
            });
            setUploadStarted(false);
            setIsDialogOpen(false);
        } catch (e) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        }

    }, [document]);


    const chipProps = useMemo(()=> {

        if(shareholder.documentSignStatus === "NONE") {
            return {
                label: "Pending",
                icon: "pi pi-hourglass",
                className: "border-round text-sm bg-orange-100 text-orange-400"
            }
        }

        if(shareholder.documentSignStatus === "UPLOADED") {
            return {
                label: "Uploaded",
                icon: "pi pi-bolt",
                className: "border-round text-sm bg-indigo-200 text-indigo-500"
            }
        }

        if(shareholder.documentSignStatus === "SIGNED") {
            return {
                label: "Signed",
                icon: "pi pi-check",
                className: "border-round text-sm bg-green-100 text-green-400"
            }
        }

    }, [shareholder]);

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop:handleUpload,
        accept: {'application/pdf': ['.pdf']},
        multiple:false,
    });

    useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [shareholder]);
    return (
        <div className="w-12 px-2 flex justify-content-betweenm align-items-center">
            <Toast ref={toast} />
            <div className="w-6">
                <div className="flex align-items-center">
                    <Avatar  size="large"
                             shape="circle"
                             label={shareholder.name[0]}
                             className={`text-white uppercase ${getRandomColor(index)}`} />
                    <div className="ml-2">
                        <div className="font-base text-lg text-black-alpha-90">
                            {shareholder.name}
                        </div>
                        <div className="font-medium text-base">
                            {
                                shareholder.userType === "SHARE_HOLDER" ? "Shareholder" : "Officer"
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-6">
                <div className="flex justify-content-end align-items-center w-full">
                    <span className="mr-2">
                                   <Chip {...chipProps}
                                         pt={{label: {className: "text-sm mr-1 font-bold font-semibold"}}}/>
                    </span>

                    {
                        userRole === "ADMIN" ? (
                            <SplitButton
                                label="Submit"
                                appendTo="self"
                                loading={loading}
                                severity="secondary"
                                disabled={shareholder.documentSignStatus === "SIGNED"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (shareholder.documentSignStatus === "UPLOADED") {
                                        setLoading(true);
                                        handleSubmit(shareholder);
                                    }

                                }}
                                model={[
                                    {
                                        label: "Upload",
                                        command:(e) => {
                                            e.originalEvent.stopPropagation();
                                            setLoading(true);
                                            setIsDialogOpen(true);
                                        }
                                    },
                                    {
                                        label: "Download",
                                        command:(e) => {
                                            e.originalEvent.stopPropagation();
                                            setLoading(true);
                                            handleDownload(shareholder);
                                        }
                                    },
                                ]}
                                pt={{
                                    button:{
                                        root:{
                                            className:classNames({
                                                "pointer-events-none opacity-70 text-500": shareholder.documentSignStatus === "NONE"
                                            })
                                        }
                                    },
                                    menu: {
                                        className:"font-semibold text-base",
                                        style: {right:"50%", left:"auto"},
                                    },

                                }}/>
                        ) : null
                    }

                </div>
            </div>
            <Dialog visible={isDialogOpen}
                    style={{width:'50vw'}}
                    onHide={() => setIsDialogOpen(false)}
                    footer={
                        <div className="w-full flex justify-content-center">

                            <Button outlined
                                    label="Cancel"
                                    disabled={isUploadStarted}
                                    severity="secondary"
                                    onClick={() => setIsDialogOpen(false)} />
                            <Button outlined
                                    label="Choose a file"
                                    disabled={isUploadStarted}
                                    onClick={open}/>
                        </div>
                    }
                    header={
                        <>
                            <h2>Upload Shareholder Consent Statement</h2>
                            <span className="font-medium text-gray-600 text-lg">
                                Please upload the consent statement
                                document to proceed. Ensure the file
                                is in the correct format and meets the
                                required guidelines.
                            </span>
                        </>
                    }
            >
                <div {...getRootProps()} className="relative flex flex-wrap justify-content-center align-content-center cursor-pointer p-2 w-ful bg-gray-50 h-15rem border-2 border-dashed border-round border-gray-200">
                    <input {...getInputProps()} />
                    <div>
                        Drop or choose PDF file
                    </div>
                </div>
                {
                    isUploadStarted ? (
                        <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
                    ) : null
                }
            </Dialog>
        </div>
    )
}

export default memo(TabHeader)