import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './creationControl.module.scss';
import StepsControl, {stepsKeys, TStep} from "../../../../uiComponents/StepsControl/StepsControl";
import BusinessInformation from "../steps/BusinessInformation/BusinessInformation";
import LateElection from "../steps/LateElection/LateElection";
import Shareholders from "../steps/Shareholders/Shareholders";
import {TForm2553Information} from "./types";
import {requests} from "../../../../services/http-common";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ReactPdfViewer} from "../../../../components/ReactPdfViewer/ReactPdfViewer";
import OfficerInfo from "../steps/OfficerInfo/OfficerInfo";
import PreviewAndTerms from "../steps/PreviewAndTerms/PreviewAndTerms";
import {Form2553Service} from "../../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../hooks/useToast";
import {ProgressSpinner} from "primereact/progressspinner";
import Publish from "../steps/Publish/Publish";
import Payment from "../steps/Payment/Payment";

interface TCreationControlProps {
    companyId: number;
    isAdmin: boolean;
    hasUserPaid?: boolean;
    companies:any[];
    formId: string;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
}
const CreationControl: FC<TCreationControlProps> = ({
companyId,
formId,
    isAdmin,
companies,
hasUserPaid,
form2553Information,
refetch2553Data,
}) => {
    const { show } = useToast();
    const [step, setStep] = React.useState<TStep>(() => {
       return hasUserPaid ? stepsKeys.PUBLISH : stepsKeys.BUSINESS_INFORMATION;
    });

    const [uuid, setFileUUID] = React.useState<string>();
    const [pdfDocumentUrl, setPdfDocumentUrl] = React.useState<string>('');
    const [isPdfLoaded, setIsPdfLoaded] = React.useState<boolean>(false);
    const isLateElection = form2553Information?.initialFormDetails?.lateElectionQuestionEnabled;

    const { isFetched, data:pdfUrl}  = useQuery({
        queryKey:['pdf-form-2553'],
        enabled: true,
        staleTime: 0, // Makes the data immediately stale
        queryFn:async () => {
            return await requests.get(
                `/api/companies/${companyId}/form2553/${formId}/file/download/template`
            ).then(response => {
                return response.url
            })
        },
    });
    const previewDocument = useMutation({
        mutationFn: Form2553Service.previewDocument,
        onSuccess: (response: AxiosResponse) => {
            const {url, uuid} = response.data;
            setPdfDocumentUrl(url);
            setFileUUID(uuid)
            setIsPdfLoaded(true);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: previewDocument.mutateAsync,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });


    const onInputDataHandler = useCallback(async (name:string, value:string)=> {
        // console.log("name=>", name, "value=>", value);
    },[]);

    const loadPdfDocument = useCallback(async (url:string) => {
        return fetch(url).then(async (file): Promise<Blob> => {
            let bufferedFile: ArrayBuffer = await file.arrayBuffer();
            return new Blob([bufferedFile], { type: "application/pdf" });
        });
    },[]);

    useEffect(() => {
    //get pdf form keys
    if(isFetched) {

        loadPdfDocument(pdfUrl).then(blob =>{
            setPdfDocumentUrl(prev => {
                if(prev !== '') {
                    return prev;
                }
                return URL.createObjectURL(blob)
            });
            setIsPdfLoaded(true);
        })
        requests.get(
            `/api/companies/${companyId}/form2553/config/pdf`
        ).then(response => {
            return response.url
        })
    }
}, [isFetched, loadPdfDocument, pdfUrl]);

    // @ts-ignore
    return (
        <>
            <div className={styles.creationHeader}>
                <StepsControl
                    currentStep={step}
                    isLateElection={isLateElection}
                />
            </div>
            <div className={styles.creationBody}>
                <div className={styles.creationForm}>


                {
                    step === stepsKeys.BUSINESS_INFORMATION && (
                        <BusinessInformation
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                            isLateElection={isLateElection}
                            inputDataHandler={onInputDataHandler}
                            form2553Information={form2553Information}
                            refetch2553Data={refetch2553Data}
                        />
                    )
                }
                {
                    step === stepsKeys.LATE_ELECTION_JUSTIFICATION && (
                        <LateElection
                            isLateElection={isLateElection}
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                            form2553Information={form2553Information}
                            reFetch2553Data={refetch2553Data}
                        />
                    )
                }
                    {
                        step === stepsKeys.OFFICER_INFO && (
                            <OfficerInfo
                                    formId={formId}
                                    setStep={setStep}
                                    isLateElection={isLateElection}
                                    companyId={companyId}
                                    reFetch2553Data={refetch2553Data}
                                    form2553Information={form2553Information} />
                        )
                    }
                {
                    step === stepsKeys.SHAREHOLDER_INFORMATION && (
                        <Shareholders
                            form2553Information={form2553Information}
                            companyId={companyId}
                            formId={formId}
                            refetch2553Data={refetch2553Data}
                            setStep={setStep}
                            isLateElection={isLateElection}
                        />
                    )
                }

                {
                    step === stepsKeys.TERMS_AND_PREVIEW && (
                        <PreviewAndTerms
                            pdfLoaded={isPdfLoaded}
                            companies={companies}
                            form2553Information={form2553Information}
                            previewDocument={previewDocument}
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                            setIsPdfLoaded={setIsPdfLoaded}
                        />
                    )
                }

                {
                    step === stepsKeys.PAY && (
                        <Payment companyId={companyId} uuid={String(uuid)} setStep={setStep}/>
                    )
                }

                {
                    step === stepsKeys.PUBLISH && (
                        <Publish
                            isAdmin={isAdmin}
                            companies={companies}
                            form2553Information={form2553Information}
                            companyId={companyId}
                            formId={formId}
                            setStep={setStep}
                        />
                    )
                }

                </div>
                <div className={styles.creationPdfContent}>
                    {
                        isPdfLoaded ? (
                             <ReactPdfViewer fileUrl={pdfDocumentUrl} isModal={false}/>
                        ) : (
                            <div className="w-full h-full flex flex-column justify-content-center align-items-center">
                                <ProgressSpinner style={{ width: '50px', height:'50px' }}  strokeWidth="4"/>
                                {
                                    step === stepsKeys.TERMS_AND_PREVIEW && (
                                        <div className="mt-2 font-semibold">
                                            We are preparing your document. Hang tight, this might take a few seconds.
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default CreationControl;
