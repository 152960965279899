import {classNames as cx, classNames} from 'primereact/utils';
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';

import { If } from '../If/If';

import classes from './LabelValue.module.scss';
import {Tooltip} from "primereact/tooltip";

interface Props {
  label?: string | ReactNode;
  value?: string | number;
  required?: boolean;
  customClassName?: string;
  comment?: string;
  fieldName?: string;
}

export const LabelValue: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  label,
  value,
  required = false,
  customClassName = '',
  comment,
  fieldName,
}) => {
  return (
    <div className={classNames(classes.container, customClassName)}>
      <div className={classes.label}>
        {label}
        <If condition={required}>
          <span className={classes.required}>*</span>
        </If>
        {
            comment !== '' && (
                <div className={classes.tooltipWrapper}>
                  <Tooltip target={`.${fieldName}`} style={{maxWidth:"30vw"}}/>
                  <i
                      data-pr-tooltip={comment}
                      data-pr-position="left"
                      className={cx(`${fieldName} pi pi-info-circle`, classes.tooltip)}
                  ></i>
                </div>
            )
        }
      </div>
      <div className={classes.value}>
        <If condition={!!children}>
          <>{children}</>
          {value}
        </If>
      </div>
    </div>
  );
};
