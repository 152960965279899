import {useCallback,  useEffect,  useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AxiosError} from "axios";
import {ProgressSpinner} from "primereact/progressspinner";

import {useToast} from "../../../hooks/useToast";
import {HttpService} from "../../../services/HttpService";
import {stepsKeys,} from "../../../components/DocumentGeneration/types";
import StepsControl from "../../../components/DocumentGeneration/components/StepsControl/StepsControl";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";

import Terms from "./Terms";
import Preview from "./Preview";
import Payment from "./Payment";
import SignWell from "./SignWell";
import FormFile15620 from "./FormFile15620";
import DeliveryService from "./CountryBasedTinDialog";
import { TSelectedService, TTransportType } from "../../../@types/global";
import { transportTypes } from "../../../config/config";
import FinishStep from "../../../components/DocumentGeneration/components/FinishStep/FinishStep";


type PagerProps = {
    email?: string;
    price: number;
    uspsPrice: number;
    isPublic: boolean;
    companyId:number;
    isDataLoaded?: boolean;
    paymentEnabled:boolean;
    isPublicOpenAi:boolean;
    templateId:string |undefined;
    deliveryService:string;
    formData: Record<string, string | number>;
}

function  FormWrapper15620(props:PagerProps) {
    const {
        email,
        price,
        uspsPrice,
        isPublic,
        companyId,
        formData,
        templateId,
        isDataLoaded,
        paymentEnabled,
        isPublicOpenAi,
        deliveryService
    
    } = props;
    const { show } = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoaded, setLoaded] = useState(false);
    const [showDeliveryService, setShowDeliveryService] = useState(deliveryService === 'NONE');
    const [fileId, setFieldId] = useState(searchParams.get('fileId'));
    const [isUpdateMode, setUpdateMode] = useState(searchParams.has('fileId'));
    const [isLastStep, setIsLastStep] = useState<boolean>(Boolean(searchParams.get('rdn')));
    const [step, setStep] = useState(isLastStep ? stepsKeys.finish : stepsKeys.filling);
    const [source, setSource] = useState<Record<string, string>>({});
    const [formValues, setFormValues] = useState<Record<string, any>>({});
    const [transportType, setTransportType] = useState<TTransportType>(transportTypes.FEDEX);

    const [selectedService, selectService] = useState<TSelectedService>('paid');
    const isPaidSelected = selectedService === 'paid';
    const submitHandler = useCallback((formData:any) => {

        const params = {
            templateId,
            deliveryService: transportType,
            replacements: { ...formData,  firstRecipientEmail: email }
        };
        setFormValues(formData);

            const url = isUpdateMode ? `/api/companies/${companyId}/file83b/${fileId}/generate-pdf` : `/api/companies/${companyId}/file83b/generate-pdf`;
        
            HttpService.post(url, {...params}).then(res => {
            setSource(res.data);
            setStep(step === stepsKeys.delivery ? stepsKeys.pay : stepsKeys.review);
            setFieldId(res.data.formId);
            setLoaded(true);
        }).catch((error: AxiosError<{ message: string }>) => {
            extractErrorMessage({
                error,
                show: show,
            });
        })

    },[companyId, templateId, step, transportType]);

    return (
        <div className="w-full h-full flex flex-column gap-4">
            {
                isDataLoaded ? (
                    <>
                        <div className="w-full flex flex-column flex-row align-items-center">
                            <StepsControl
                                step={step}
                                isNewStyle
                                isPublic={isPublic}
                                paymentEnabled={paymentEnabled}
                                isPublicOpenAi={isPublicOpenAi}
                                {...(isLastStep ? {
                                    isFLowCompleted:true,
                                    afterEmbeddedPay:true
                                } : {})}
                            />
                        </div>

                        <div className="w-full h-full flex flex-column flex-row align-items-center">
                            {
                                step === stepsKeys.filling && (
                                    <FormFile15620  formData={formData}
                                                    fileId={fileId}
                                                    companyId={companyId}
                                                    onDataSave={submitHandler}/>
                                )
                            }
                            {
                                step === stepsKeys.review && (
                                    <Preview isLoaded
                                             documentUrl={source.fileUrl}
                                             handleNextStep={() => setStep(stepsKeys.terms)}
                                             handlePreviousStep={() => {
                                                setStep(stepsKeys.filling);
                                                navigate(`?fileId=${formData.id || fileId}`)
                                             }}
                                    />
                                )
                            }

                            {
                                step === stepsKeys.terms && (
                                    <Terms handleNextStep={() => setStep(() => {
                                            if(showDeliveryService && paymentEnabled) {
                                                return stepsKeys.delivery;
                                            } else {
                                                return paymentEnabled ? stepsKeys.pay : stepsKeys.finish;
                                            }
                                        })
                                    }
                                    handlePreviousStep={() => setStep(stepsKeys.review)} />
                                )
                            }
                            {
                                (step === stepsKeys.delivery) && (
                                    <FinishStep
                                    isPublic={isPublic}
                                        setStep={setStep}
                                        signAndSubmit={()  => {
                                            setUpdateMode(true);
                                            setTimeout(() => {
                                                submitHandler({ ...formValues });
                                            }, 300);
                                        }}
                                        price={price}
                                        uspsPrice={uspsPrice}
                                        transportType={transportType}
                                        setTransportType={setTransportType}
                                        isPaidSelected={isPaidSelected}
                                        selectedService={selectedService}
                                        selectService={selectService}
                                        isPublicOpenAi={isPublicOpenAi}
                                    />
                                )
                            }
                            {
                                step === stepsKeys.pay && (
                                    <Payment uuid={source.fileUUID}
                                             companyId={companyId}
                                             handlePreviousStep={() => setStep(stepsKeys.filling)}/>
                                )
                            }
                            {
                                 (step === stepsKeys.finish) && (
                                    <SignWell embeddedUrl={source.embeddedSigningUrl} companyId={companyId}/>
                                )
                            }
                        </div>
                    </>
                ) : (
                    <div className="w-full h-full flex flex-column justify-content-center align-items-center">
                        <ProgressSpinner style={{ width: '50px', height:'50px' }}  strokeWidth="4"/>
                    </div>
                )
            }

        </div>
    )
}

export default FormWrapper15620;