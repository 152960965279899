import React, {useMemo} from "react";
import * as Yup from "yup";
import {AxiosError, AxiosResponse} from "axios";
import {InputText} from "primereact/inputtext";
import {useMutation} from "@tanstack/react-query";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {TForm2553Information} from "../../CreationControl/types";

import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";

import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {Form2553Service} from "../../../Service";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../../hooks/useToast";

import styles from '../shared.module.scss';

type propTypes = {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    form2553Information: TForm2553Information;
    reFetch2553Data: () => void;
    isLateElection:boolean;
}

export type officerInfFormTypes = {
    name: string,
    title: string,
    phoneNumber: string
    email: string,
}

const schema = Yup.object().shape({
    name: Yup.string().required("Officer Name is required"),
    title: Yup.string().required("Officer Title is required"),
    phoneNumber: Yup.number().required("Officer Phone Number is required"),
    email: Yup.string().required("Officer Email is required"),
});

function OfficerInfo(props:propTypes) {
    const {
        companyId,
        formId,
        setStep,
        reFetch2553Data,
        isLateElection,
        form2553Information
    } = props;

    const officerInfoFormInitialValues = useMemo(() => {
        return {
            name: form2553Information?.officerInformation?.name || "",
            title: form2553Information?.officerInformation?.title || "",
            phoneNumber: form2553Information?.officerInformation?.phoneNumber || "",
            email: form2553Information?.officerInformation?.email || "",
        }
    }, [form2553Information]);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<officerInfFormTypes>({
        resolver: yupResolver(schema),
        defaultValues: officerInfoFormInitialValues,
    });
    const { show } = useToast();
    const postOfficerInformation = useMutation({
        mutationFn: Form2553Service.postOfficerInformation,
        onSuccess: (response: AxiosResponse) => {
            reFetch2553Data();
            setStep(stepsKeys.SHAREHOLDER_INFORMATION);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postOfficerInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<officerInfFormTypes> = (params) => {
        postOfficerInformation.mutate({
            params,
            companyId,
            formId
        })
    }

    const onPreviousStep = () => {
        setStep(stepsKeys.LATE_ELECTION_JUSTIFICATION);
    }

    return (
        <div className={styles.formColumn}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue
                            label="Name"
                            required={true}
                            fieldName="name"
                            comment=""
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.name ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.name && <small className="p-error">{errors.name.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Title"
                            required={true}
                            fieldName="title"
                            comment=""
                        >
                            <Controller
                                name="title"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.title ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.title && <small className="p-error">{errors.title.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Email"
                            required={true}
                            fieldName="email"
                            comment=""
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.email ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Phone Number"
                            required={true}
                            fieldName="phoneNumber"
                            comment=""
                        >
                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.phoneNumber ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.phoneNumber && <small className="p-error">{errors.phoneNumber.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={onPreviousStep}
                    type="button"
                    label="Back"
                />
                <ButtonPrimary
                    type="submit"
                    label="Next"
                />
            </div>
        </form>
        </div>
    )
}

export  default OfficerInfo;