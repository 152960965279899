import React, { FC } from 'react';
import styles from './questionnaire.module.scss';
import Question1 from "./questions/Question_1";
import Question2 from "./questions/Question_2";
import Question3 from "./questions/Question_3";
import {TQuestionnaireAnswers} from "../Form2553Creation";
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";

interface TQuestionnaireProps {
    enableCreation: () => void;
    setQuestionState: (questionKey: string, value: number | string) => void;
    questionnaireAnswers: TQuestionnaireAnswers;
}
const Questionnaire: FC<TQuestionnaireProps> = ({
enableCreation,
setQuestionState,
questionnaireAnswers,
}) => {
    const { question_1, question_2, question_3 } = questionnaireAnswers;
    const isBtnDisabled = ((question_1 === 2 || question_1 === 4) || question_2 === 'Yes')
        || (question_1 === null || question_2 === null || question_3 === null);
    return (
        <div className={styles.questionnaire}>
            <div className={styles.grid}>
                <div className={styles.gridItem}>
                    <Question1
                        setQuestionState={setQuestionState}
                        initialValue={question_1}
                    />
                </div>
                <div className={styles.gridItem}>
                    <Question2
                        setQuestionState={setQuestionState}
                        initialValue={question_2}
                    />
                </div>
                <div className={styles.gridItem}>
                    <Question3
                        setQuestionState={setQuestionState}
                        initialValue={question_3}
                    />
                </div>
                <div className={styles.nextHolder}>
                    <ButtonPrimary
                        label="Next"
                        onClick={enableCreation}
                        disabled={isBtnDisabled}
                    />
                </div>
            </div>
        </div>
    )
}

export default Questionnaire;
