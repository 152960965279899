import React, {memo, useCallback} from "react";
import {Card} from "primereact/card";

import FedExIndoHeader from "./FedExIndoHeader";
// @ts-ignore
import FileIcon from "../../../../../assets/icons/document-icon.svg?react";

import OrderTracking from "./OrderTracking";
import {Form2553Service} from "../../../Service";
import {downloadFile} from "../../../helpers";


type Props = {
    formId: string | undefined;
    companyId: number;
    trackingNumber: number;
    activities: Record<string, any>[];
}

function FedexInfo(props: Props) {

    const {
        companyId,
        formId,
        activities,
        trackingNumber
    } = props;

    const downloadOriginalDocument = useCallback(async () => {
        const response = await Form2553Service.downloadDocument({
            formId: String(formId),
            companyId
        });
        const {url, fileName} = response;
        downloadFile(url, fileName);
    }, [formId, companyId]);

    return (
        <Card header={<FedExIndoHeader trackingNumber={trackingNumber}/>} pt={{content: {className: "pt-0"}}}>
            <div className="w-full mb-4 border-bottom-1 surface-border">
                <OrderTracking tracking={activities}/>
            </div>
            <div className="w-full pb-3 mb-4 row-gap-3 border-bottom-1 surface-border flex flex-column">
                <div className="w-full font-bold text-black-alpha-90 text-xl">
                    Documents
                </div>
                <div className="w-full">
                    <div className="mb-2 font-base text-gray-700"></div>
                    <span className="font-medium text-black-alpha-90">
                        <div className="flex align-items-center text-underline cursor-pointer" onClick={downloadOriginalDocument}>
                         <FileIcon width={20} height={20}/>
                           Proof of Acceptance
                         <i className="ml-2 text-xs pi pi-external-link"/>
                       </div>
                    </span>
                </div>
            </div>
        </Card>
    )
}

export default memo(FedexInfo);