import React, {memo, useEffect, useState} from "react";
import {HttpService} from "../../../services/HttpService";
import {stepsKeys, TStep} from "../../../components/DocumentGeneration/types";
import EmbeddedPayment from "../../../components/DocumentGeneration/components/EmbeddedPayment/EmbeddedPayment";


type Props = {
    uuid: string;
    companyId:number;
    handlePreviousStep: () => void;
}

function Payment(props: Props): React.ReactElement {
    const {
        uuid,
        companyId,
        handlePreviousStep,
    } = props;

    const [showModal, setShowModal] = useState<boolean>(true);
    const [clientSecret, setClientSecret] = useState<string>();

    useEffect(() => {
        HttpService.get(
            `api/checkout/${companyId}/create-session/${uuid}`
        ).then(res => {
            const {clientSecret} = res.data.embeddedData;
            setClientSecret(clientSecret);
        });
    }, [companyId, uuid]);

    return (
        <div className="w-full">
            {
                (clientSecret && showModal) ? (
                    <EmbeddedPayment clientSecret={clientSecret} onClose={handlePreviousStep}/>
                ) : null
            }
        </div>
    )
}

export default memo(Payment);