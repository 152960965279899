import React, {FC, useState} from 'react';
import styles from "./shareholders.module.scss";
import sharedStyles from '../shared.module.scss';
import ShareholdersForm from "./ShareholdersForm";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError, AxiosResponse} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {useToast} from "../../../../../hooks/useToast";
import {SubmitHandler} from "react-hook-form";
import {
    TAdditionalInformationParams,
    TOwnershipMetric,
    TShareholderInformationFormValues,
    TShareholderInformationParams
} from "./types";
import OwnershipMetricModal from "./OwnershipMetricModal";
import {TForm2553Information} from "../../CreationControl/types";
import {formatFormValues} from "../../../helpers";
import ShareholdersList from "./ShareholdersList";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";

const dateValueKeys = [
    'dateAcquired',
];

interface TShareholdersProps {
    companyId: number;
    formId: string;
    form2553Information: TForm2553Information;
    refetch2553Data: () => void;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    isLateElection: boolean;
}
const Shareholders: FC<TShareholdersProps> = ({
companyId,
formId,
form2553Information ,
refetch2553Data,
setStep,
isLateElection,
}) => {
    const [showOwnershipMetric, setShowOwnershipMetric] = useState<boolean>(true);
    const ownershipMetricInitial = form2553Information?.additionalInfo?.ownershipMetric;
    const shareholders = form2553Information?.shareHolders || [];
    const noShareholderAdded = shareholders.length === 0;
    const [isShareholderCreate, setIsShareholderCreate] = useState(false);
    const [ownershipMetric, setOwnershipMetric] = useState<TOwnershipMetric | ''>(ownershipMetricInitial || '');
    const [resetFormKey, setResetFormKey] = useState<number>(0);
    const [shareholderInitialFormValues, setShareholderInitialValue] = useState<TShareholderInformationFormValues | null>(null);
    const [shareholderUpdateId, setShareholderUpdateId] = useState<number>(0);
    const { show } = useToast();

    const validateShareholderInformation = useMutation({
        mutationFn: Form2553Service.validateShareholderInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: validateShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const validateAndNext = async () => {
        await validateShareholderInformation.mutateAsync({ companyId, formId });
        setStep(stepsKeys.TERMS_AND_PREVIEW)
    }

    const postShareholderInformation = useMutation({
        mutationFn: Form2553Service.postShareholderInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
            setResetFormKey((prev) => prev + 1);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const putShareholderInformation = useMutation({
        mutationFn: Form2553Service.putShareholderInformation,
        onSuccess: (response: AxiosResponse) => {
            refetch2553Data();
            setResetFormKey((prev) => prev + 1);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: putShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const updateShareholderInfo = (info: TShareholderInformationParams) => {
        const formValues = {
            ...info,
            dateAcquired: new Date(info.dateAcquired),
            taxYearEndMonthAndDay: new Date(info.taxYearEndMonthAndDay),
        }
        delete formValues.id;

        setShareholderUpdateId(info?.id as number);
        setShareholderInitialValue(formValues as TShareholderInformationFormValues);
    }

    const onSubmit: SubmitHandler<TShareholderInformationFormValues> = (values) => {
        const params = formatFormValues(
            values as unknown as any[],
            dateValueKeys,
        );

        if (shareholderUpdateId) {
            putShareholderInformation.mutateAsync({
                params: params as TShareholderInformationParams,
                companyId: companyId,
                formId: formId,
                shareholderId: shareholderUpdateId,
            }).then(res => {
                setIsShareholderCreate(false);
                setShareholderInitialValue(null);
                setShareholderUpdateId(0);
            })

        } else {
            postShareholderInformation.mutateAsync({
                params: params as TShareholderInformationParams,
                companyId: companyId,
                formId: formId,
            }).then(() =>{
                   setIsShareholderCreate(false);
                   setShareholderInitialValue(null);
                   setShareholderUpdateId(0);
            });
        }
    }

    const patchAdditionalInformation = useMutation({
        mutationFn: Form2553Service.patchAdditionalInformation,
        onSuccess: (response: AxiosResponse, variables: {
            params: TAdditionalInformationParams,
            formId: string,
            companyId: number,
        }) => {
            refetch2553Data();
            setOwnershipMetric(variables.params.ownershipMetric);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: validateShareholderInformation.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const deleteShareholder = useMutation({
        mutationFn: Form2553Service.deleteShareholder,
        onSuccess: () => {
            refetch2553Data();
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: deleteShareholder.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const deleteLoading = deleteShareholder?.isPending;
    const onDeleteShareholder = (id: number) => {
        deleteShareholder.mutate({ formId, companyId, id });
    }

    const onPatchAdditionalInformation = (params: TAdditionalInformationParams) => {
        patchAdditionalInformation.mutateAsync(
            { params, companyId, formId }
        ).then(() => {
            setShowOwnershipMetric(false);
        });
    }

    const onCancelFormAction = () => {
        setIsShareholderCreate(false);
        setShareholderInitialValue(null);
        setShareholderUpdateId(0);
    }

    const onPrev = () => {
        setShowOwnershipMetric(true);
    }

    const prevFromOwnerShipModal = () => {
        setStep(stepsKeys.OFFICER_INFO);
    }

    const nextFromOwnerShipModal = () => {
        setStep(stepsKeys.OFFICER_INFO);
    }
    return (
        <div className={styles.shareholders}>
            {
                !showOwnershipMetric ? (
                    <>
                        <div className={sharedStyles.formColumn}>
                            {
                                (isShareholderCreate || shareholderUpdateId) ? (
                                    <ShareholdersForm
                                        onSubmit={onSubmit}
                                        officerInfo={form2553Information.officerInformation}
                                        isCreateMode={isShareholderCreate}
                                        resetFormKey={resetFormKey}
                                        onCancel={onCancelFormAction}
                                        ownershipMetric={ownershipMetric}
                                        initialValues={shareholderInitialFormValues as TShareholderInformationFormValues}
                                        isShareholderUpdate={Boolean(shareholderUpdateId)}
                                    />
                                ) : (
                                    <ShareholdersList
                                        shareholders={shareholders}
                                        onPrev={onPrev}
                                        noShareholderAdded={noShareholderAdded}
                                        validateAndNext={validateAndNext}
                                        setIsShareholderCreate={setIsShareholderCreate}
                                        onDeleteShareholder={onDeleteShareholder}
                                        deleteLoading={deleteLoading}
                                        updateShareholderInfo={updateShareholderInfo}
                                    />
                                )
                            }
                        </div>
                    </>
                ) : (
                    <OwnershipMetricModal
                        onPrev={prevFromOwnerShipModal}
                        selectedValue={ownershipMetric}
                        onPatchAdditionalInformation={onPatchAdditionalInformation}
                    />
                )
            }
        </div>
    )
}

export default Shareholders;
