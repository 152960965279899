import React, { FC, useState, useMemo } from 'react';

import styles from './finishStep.module.scss';
import { stepsKeys, TStep } from '../../types';
import { RadioButton } from 'primereact/radiobutton';
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import fileOnlineIcon from '../../../../assets/icons/mailing/file_online.svg';
import freeServiceIcon from '../../../../assets/icons/mailing/free_service.svg';
import checkPointIcon from '../../../../assets/icons/mailing/check_point_icon.svg';
import { classNames } from "primereact/utils";
import { TSelectedService, TTransportType } from "../../../../@types/global";
import { transportTypes } from "../../../../config/config";
import RadioCheckboxLabel from "../../../../uiComponents/RadioCheckboxLabel/RadioCheckboxLabel";
import TooltipAndLabel from "../../../../uiComponents/Tooltip/Tooltip";

type TPrintStepItem = {
  heading: string;
  description: string;
};

interface TFinishStepProps {
  isPublic: boolean;
  setStep: React.Dispatch<React.SetStateAction<TStep>>;
  signAndSubmit: (email?: string) => void;
  price?: number;
  uspsPrice?: number;
  transportType: TTransportType;
  setTransportType: React.Dispatch<React.SetStateAction<TTransportType>>;
  isPaidSelected: boolean;
  selectedService: TSelectedService;
  selectService: React.Dispatch<React.SetStateAction<TSelectedService>>;
  isPublicOpenAi?: boolean;
}

const FinishStep: FC<TFinishStepProps> = ({
  setStep,
  isPublic,
  signAndSubmit,
  price = 0,
  uspsPrice = 0,
  transportType,
  setTransportType,
  isPaidSelected,
  selectedService,
  selectService,
  isPublicOpenAi,
}) => {

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false)
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setIsValid(emailRegex.test(inputEmail));
  };

  const showEmailError = !isFocused && email && !isValid;
  const onSelectService = (service: TSelectedService) => {
    if (service === 'paid') {
      setEmail('');
      setIsValid(false);
      setIsFocused(false);
    }
    selectService(service);
  }
  const handleNext = () => {
    setLoading(true)
    if (isPaidSelected) {
      signAndSubmit();
    } else {
      signAndSubmit(email);
    }
  }
  const printStepsConfig: TPrintStepItem[] = useMemo(
    () => {
      return (
        [
          {
            heading: 'Send your documents to the IRS',
            description: `We will send your signed cover letter, two copies of your signed 83(b) election form, and a postage-paid return envelope to the IRS.`,
          },
          {
            heading: 'Provide a tracking number and proof of delivery',
            description: `Once your documents are shipped, we will provide you with a tracking number and proof of delivery, if and once available. ${isPublicOpenAi ? '' : 'If you select "USPS Certified Mail", we will also provide you with the postmarked Certified Mail Receipt.'}`,
          },
          {
            heading: 'Provide IRS date-stamped copy',
            description: 'If and once received, we will provide you with the IRS date-stamped copy of your filing.',
          },
          {
            heading: 'Store your documents for 90 days',
            description: `We will store any originals or digital copies of your documents for 90 days after placing your order. You may request that we mail you the originals within this period.`,
          },
        ]
      )
    }, [isPublicOpenAi]
  );
  const priceObject = useMemo(
    () => {
      return {
        [transportTypes.FEDEX]: price,
        [transportTypes.USPS]: uspsPrice,
      }
    },
    [transportTypes, price]
  );
  const priceUponTransportType = priceObject[transportType];
  return (
    <div className={styles.finishWidget}>
      <div className={styles.finishContainer}>
        {
          !isPublicOpenAi && (
            <div className={styles.paymentServicesWrapper}>
              <div className={classNames(styles.paymentService, {
                [styles.active]: selectedService === 'paid'
              })} onClick={() => onSelectService('paid')}>
                <img src={fileOnlineIcon} alt="" className={styles.serviceIcon} />
                <div className={styles.serviceInfo}>
                  <p className={styles.txt1}>I would like Corpora to help me file it.</p>
                  <p className={styles.txt2}>We will send your document to the IRS and track it for you. We will also
                    email you a
                    copy of your document.</p>
                  <div className={styles.price}>
                    <span>{`$${priceUponTransportType}`}</span>
                    One-time payment
                  </div>
                  {
                    transportType === transportTypes.USPS && (
                      <div className={styles.priceIncreaseLabel}>
                        <i className="bi bi-info-circle" />
                        USPS is priced higher due to additional handling requirements and occasional reliability
                        issues.
                        For a more reliable and faster shipping experience, we recommend choosing FedEx.
                      </div>
                    )
                  }
                </div>
              </div>
              {
                isPublic ? (
                  <div className={classNames(styles.paymentService, { [styles.active]: selectedService === 'free' })} onClick={() => onSelectService('free')}>
                    <img src={freeServiceIcon} alt="" className={styles.serviceIcon} />
                    <div className={styles.serviceInfo}>
                      <p className={styles.txt1}>I will file it manually myself.</p>
                      <p className={styles.txt2}>
                        We will email you the form you created, and you will send your document to the IRS yourself.
                        <span style={{ fontWeight: 900, textDecoration: 'underline' }}>
                          {` We will not take any action for you.`}
                        </span>
                      </p>
                      <div className={styles.fieldWrapper}>
                        <input
                          className={styles.inputText}
                          onChange={handleEmailChange}
                          type="email"
                          placeholder="Your email address"
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          value={email}
                        />
                        <span className={styles.emailValidationMsg}>
                          {
                            showEmailError && 'Invalid email address'
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null
              }

            </div>
          )
        }
        {
          isPaidSelected && (
            <>
              {
                !isPublicOpenAi && (
                  <>
                    <TooltipAndLabel
                      label="Please select your preferred delivery service:"
                      className={styles.customTooltip}
                      tooltipMessage="While USPS Certified Mail is the conventional choice for mailing 83(b) elections, we also offer FedEx 2Day, which tends to be faster and more reliable.  As an IRS-designated private delivery service, it’s legally equivalent to USPS Certified Mail for purposes of proving timely mailing and delivery."
                    />
                    <div className={styles.transportation}>
                      <div className={styles.checkboxItem}>
                        <RadioButton
                          inputId="transport1"
                          name="fedex"
                          value={transportTypes.FEDEX}
                          onChange={(e) => setTransportType(e.value)}
                          checked={transportType === transportTypes.FEDEX}
                        />
                        <RadioCheckboxLabel
                          label="FedEx 2Day (recommended)"
                          htmlFor="transport1"
                        />
                      </div>
                      <div className={styles.checkboxItem}>
                        <RadioButton
                          inputId="transport2"
                          name="usps"
                          value={transportTypes.USPS}
                          onChange={(e) => setTransportType(e.value)}
                          checked={transportType === transportTypes.USPS}
                        />
                        <RadioCheckboxLabel
                          label="USPS Certified Mail (conventional)"
                          htmlFor="transport2"
                        />
                      </div>
                    </div>
                  </>
                )
              }
              <div className={styles.pointInfoWrapper}>
                <div className={styles.infoHeading}>
                  <i className="pi pi-info-circle" />
                  <p>After you sign the cover letter and 83(b) election form, we will:</p>
                </div>
                <div className={styles.checkPointsWrapper}>
                  {
                    printStepsConfig.map((item, index) => {
                      const { heading, description } = item;
                      return (
                        <div key={index} className={styles.checkPointItem}>
                          <img src={checkPointIcon} alt="" className={styles.checkIcon} />
                          <div className={styles.textInfos}>
                            <p>{heading}</p>
                            <p>{description}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </>
          )
        }
      </div>
      <div className={styles.footerHolder}>
        <ButtonPrimary
          label="Back to Terms"
          styleType="secondaryOutline"
          type="button"
          onClick={() => setStep(stepsKeys.terms)}
        />
        <ButtonPrimary
          type="button"
          isLoading={isLoading}
          onClick={handleNext}
          label={isPaidSelected ? 'Pay' : 'Email'}
          disabled={!isPaidSelected && !isValid}
        />
      </div>
    </div>
  );
};

export default FinishStep;
