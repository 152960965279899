import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {requests} from "../../services/http-common";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import {Button} from "primereact/button";

const stripePromise = loadStripe("your_publishable_key_here"); // Replace with your Publishable Key

// @ts-ignore
const CheckoutForm = ({clientSecret,companyId}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e:any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
        });

        if (error) {
            console.error("Setup failed:", error.message);
        } else if (setupIntent && setupIntent.status === "succeeded") {
            await requests.post(
                `/api/companies/${companyId}/payment-methods`, {
                    paymentMethodId:setupIntent.payment_method
                }
            )
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <PaymentElement />
            <Button type="submit" disabled={!stripe} label='Save Payment Method'/>
        </form>
    )
};

export default CheckoutForm;
