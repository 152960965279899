import React, {FC, useCallback, useMemo} from 'react';
import * as Yup from "yup";
import {InputTextarea} from 'primereact/inputtextarea';
import {Controller, FormSubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import styles from "../shared.module.scss";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {SubmitHandler} from "react-hook-form";
import {TLateElectionFormValues} from "./types";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";



interface TLateElectionFormProps {
    onSubmit: SubmitHandler<TLateElectionFormValues>;
    initialValues: TLateElectionFormValues;
    isLateElection: boolean
    onPrev: () => void;
    onChange: (election: string) => void
}
const elections = [
    { name: 'Late election', code: 'Late' },
    { name: 'Timely election', code: 'Timely' },
]
const LateElectionForm: FC<TLateElectionFormProps> = ({
                                                          onSubmit,
                                                          initialValues,
                                                          onPrev,
                                                          onChange,
                                                          isLateElection
                                                      }) => {



    const [isElectedLate, setElectedLate] = React.useState(isLateElection);
    const [selectedLate, setSelectedLate] = React.useState(elections[isLateElection ? 0 : 1]);
    const validationSchema = useMemo(()=> {
        return Yup.object().shape({
            lateElectionQuestionEnabled: Yup.boolean(),
            ...(isElectedLate ? {
                justificationText: Yup.string()
                    .required('Justification is required')
                    .min(10, 'Justification must be at least 10 characters long')
                    .max(500, 'Justification must not exceed 500 characters'),
            } : {})
        })
    },[isElectedLate])

    const {
        control,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<TLateElectionFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialValues,
            lateElectionQuestionEnabled:isLateElection
        },
    });


    const handleChange = (e:DropdownChangeEvent) => {
        setElectedLate(() => {
            return e.value.code === "Late";
        });
        setSelectedLate(e.value);
        onChange(e.value.code);
    }

    const  onSubmitHandler:SubmitHandler<TLateElectionFormValues> =  useCallback((values:any)=> {
        if(isElectedLate) {
            onSubmit(values)
        } else {
            onSubmit({
                lateElectionQuestionEnabled: false
            })
        }
    }, [isElectedLate])

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmitHandler)}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className="card flex justify-content-center">
                        <Controller
                            name="lateElectionQuestionEnabled"
                            control={control}
                            render={({field}) => (
                                // @ts-ignore
                                <input type='hidden' {...field} value={selectedLate.code}/>
                            )}
                        />
                        <Dropdown value={selectedLate}
                                  onChange={handleChange}
                                  options={elections}
                                  optionLabel="name"
                                  placeholder="Select a City"
                                  className={styles.electionDropdown}/>
                    </div>
                    {
                        isElectedLate ? (
                            <div className={styles.fieldsGrid}>
                                <LabelValue
                                    label="Explanation of reasons election was not timely made"
                                    required={true}
                                    fieldName="justificationText"
                                    comment=""
                                >
                                    <Controller
                                        name="justificationText"
                                        control={control}
                                        render={({field}) => (
                                            <InputTextarea
                                                {...field}
                                                id="justificationText"
                                                style={{width: '100%'}}
                                                className={`p-inputtext ${errors.justificationText ? 'p-invalid' : ''}`}
                                                rows={10}
                                                cols={30}
                                                placeholder="Enter explanation"
                                                onChange={(e) => setValue("justificationText", e.target.value, { shouldValidate: true })}
                                            />
                                        )}
                                    />
                                    {errors.justificationText && (
                                        <small className="p-error">{errors.justificationText.message}</small>
                                    )}
                                </LabelValue>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={onPrev}
                    type="button"
                    label="Back"
                />
                <ButtonPrimary
                    type="submit"
                    label="Next"
                />
            </div>
        </form>
    )
}

export default LateElectionForm;
