import { classNames } from "primereact/utils";
import React, {FC, memo, useMemo, useState} from "react";

import DocumentIconActive from "../../assets/icons/sidebar/document-icon-active.svg";
import DocumentIcon from "../../assets/icons/sidebar/document-icon.svg";
import PersonsIconActive from "../../assets/icons/sidebar/person-icon-active.svg";
import PersonsIcon from "../../assets/icons/sidebar/person-icon.svg";
import SubscriptionsIconActive from "../../assets/icons/sidebar/subscription-icon-active.svg";
import SubscriptionsIcon from "../../assets/icons/sidebar/subscription-icon.svg";
import TemplatesIconActive from "../../assets/icons/sidebar/template-icon-active.svg";
import TemplatesIcon from "../../assets/icons/sidebar/template-icon.svg";
import styles from "./sidebar.module.scss";
import NavItem from "./NavItem";

const manageUsersNavItem = {
  to: `/manage-user`,
  label: "Manage Users",
  icon: PersonsIcon,
  iconActive: PersonsIconActive,
};

const subscriptionNavItem = {
  to: `/subscriptions`,
  label: "Subscription",
  icon: SubscriptionsIcon,
  iconActive: SubscriptionsIconActive,
};

const dashboardNavItem = {
  to: `/admin-dashboard`,
  label: "Dashboard",
  icon: DocumentIcon,
  iconActive: DocumentIconActive,
};

const grantsNavItem = {
  to: `/grants`,
  label: "Grants",
  icon: DocumentIcon,
  iconActive: DocumentIconActive,
};

const filingNavItem = {
  to: `/filing`,
  label: "File 83(b) election",
  icon: TemplatesIcon,
  iconActive: TemplatesIconActive,
};

const formNavigation15620 = [
  {
    to: `/form-15620`,
    label: "form 15620",
    icon: TemplatesIcon,
    iconActive: TemplatesIconActive,
  }
]


const adminNavConfig = [
  dashboardNavItem,
  grantsNavItem,
];

enum dropdownKeys {
  isFile83bNavOpened = 'isFile83bNavOpened',
  is2553FormNavOpened = 'is2553FormNavOpened',
  is15620NavOpened = 'is15620NavOpened',
}

type TDropdownStates = {
  [dropdownKeys.is15620NavOpened]: boolean
  [dropdownKeys.isFile83bNavOpened]: boolean;
  [dropdownKeys.is2553FormNavOpened]: boolean;
}

interface INavigationSectionProps {
  isNeedSignature:boolean;
  companyId:number
  isAdmin: boolean;
  isOpened: boolean;
  isAnimate: boolean;
  isForm15620Enabled:boolean
  subscriptionMenuVisible: boolean;
  isFile83bFeatureEnabled: boolean;
  isFillForm2553FeatureEnabled: boolean;
}

const NavigationSection: FC<INavigationSectionProps> = ({
    companyId,
  isAdmin,
  isOpened,
  isAnimate,
  isNeedSignature,
 isForm15620Enabled,
  subscriptionMenuVisible,
  isFile83bFeatureEnabled,
  isFillForm2553FeatureEnabled,
}) => {
  const [dropdownStates, setDropdownStates] = useState<TDropdownStates>({
    isFile83bNavOpened: true,
    is2553FormNavOpened: true,
    is15620NavOpened: true,

  });
  const { isFile83bNavOpened, is2553FormNavOpened, is15620NavOpened} = dropdownStates;
  const mainNavItems = [];

  if (isAdmin) {
    mainNavItems.push(manageUsersNavItem);
  }

  if (subscriptionMenuVisible) {
    mainNavItems.push(subscriptionNavItem);
  }

  const navItems83B = [
    ...(isAdmin ? adminNavConfig : []),
    filingNavItem,
  ];

  const toggleNavSection = (key: dropdownKeys.isFile83bNavOpened | dropdownKeys.is2553FormNavOpened | dropdownKeys.is15620NavOpened) => {
    setDropdownStates((prev) => {
      return {
        ...prev,
        [key]: !prev[key],
      }
    });
  }

  const navItems2553 = useMemo(() => {
    const navItems = []

    if (isAdmin) {
      navItems.push(
          {
            to: `/form-2553-dashboard`,
            label: "Dashboard",
            icon: DocumentIcon,
            iconActive: DocumentIconActive,
          },
      );
    }

    if (isNeedSignature) {
      navItems.push(
          {
            to: `/form-2553-signature`,
            label: "Signature page",
            icon: DocumentIcon,
            iconActive: DocumentIconActive,
          },
      )
    }

    return navItems;
  }, [isAdmin, isNeedSignature]);

  return (
    <div className={styles.navigationWrapper}>
      <nav className={styles.navWrapper}>
        {
            mainNavItems.length > 0 && (
                <div className={styles.navGroup}>
                  {mainNavItems.map((config, index) => {
                    const {to, icon, iconActive, label} = config;
                    return (
                        <NavItem
                            key={index}
                            iconActive={iconActive}
                            icon={icon}
                            isOpened={isOpened}
                            isAnimate={isAnimate}
                            label={label}
                            to={to}
                            index={index}
                        />
                    );
                  })}
                </div>
            )
        }
        {
          isFile83bFeatureEnabled && (
                <div className={styles.groupWrapper}>
                  <div className={styles.groupLabelRow} role="presentation"
                       onClick={() => toggleNavSection(dropdownKeys.isFile83bNavOpened)}>
                    {
                      isOpened && (<p className={styles.labelText}>83(b) election</p>)
                    }
                    <i
                        className={classNames("bi bi-chevron-down", styles.arrowBtn, {[styles.opened]: isFile83bNavOpened})}
                    />
                  </div>
                  {
                      isFile83bNavOpened && (
                          <div className={styles.navGroup}>
                            {navItems83B.map((config, index) => {
                              const {to, icon, iconActive, label} = config;
                              return (
                                  <NavItem
                                      iconActive={iconActive}
                                      icon={icon}
                                      isOpened={isOpened}
                                      isAnimate={isAnimate}
                                      label={label}
                                      to={to}
                                      index={index}
                                      key={index}
                                  />
                              );
                            })}
                          </div>
                      )
                  }
                </div>
            )
        }
        {
          isFillForm2553FeatureEnabled && (
                <div className={styles.groupWrapper}>
                  <div className={styles.groupLabelRow} role="presentation"
                       onClick={() => toggleNavSection(dropdownKeys.is2553FormNavOpened)}>
                    {
                      isOpened && (<p className={styles.labelText}>S Corp Election</p>)
                    }
                    <i
                        className={classNames("bi bi-chevron-down", styles.arrowBtn, {[styles.opened]: is2553FormNavOpened})}
                    />
                  </div>
                  {
                      is2553FormNavOpened && (
                          <div className={styles.navGroup}>
                            {navItems2553.map((config, index) => {
                              const {to, icon, iconActive, label} = config;
                              return (
                                  <NavItem
                                      iconActive={iconActive}
                                      icon={icon}
                                      isOpened={isOpened}
                                      isAnimate={isAnimate}
                                      label={label}
                                      to={to}
                                      index={index}
                                      key={index}
                                  />
                              );
                            })}
                          </div>
                      )
                  }
                </div>
            )
        }

      </nav>
    </div>
  );
};

export default memo(NavigationSection);
