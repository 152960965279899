export const primeFlexColors = [
    // Core Colors
    'bg-primary',
    // Red Shades
    'bg-red-600',
    // Green Shades
    'bg-green-600',
    // Yellow Shades
    'bg-yellow-600',
    // Purple Shades
    'bg-purple-600',
    // Blue Shades
    'bg-blue-600',

    'bg-red-700',
    'bg-red-800',
    'bg-red-900',

    // Blue Shades
    'bg-blue-600',
    'bg-blue-700',
    'bg-blue-800',
    'bg-blue-900',

    // Green Shades
    'bg-green-600',
    'bg-green-700',
    'bg-green-800',
    'bg-green-900',

    // Yellow Shades
    'bg-yellow-600',
    'bg-yellow-700',
    'bg-yellow-800',
    'bg-yellow-900',

    // Purple Shades
    'bg-purple-600',
    'bg-purple-700',
    'bg-purple-800',
    'bg-purple-900',

    // Cyan Shades
    'bg-cyan-600',
    'bg-cyan-700',
    'bg-cyan-800',
    'bg-cyan-900',

    // Pink Shades
    'bg-pink-600',
    'bg-pink-700',
    'bg-pink-800',
    'bg-pink-900',

    // Orange Shades
    'bg-orange-600',
    'bg-orange-700',
    'bg-orange-800',
    'bg-orange-900',

    // Indigo Shades
    'bg-indigo-600',
    'bg-indigo-700',
    'bg-indigo-800',
    'bg-indigo-900',

    // Teal Shades
    'bg-teal-600',
    'bg-teal-700',
    'bg-teal-800',
    'bg-teal-900',

    // Gray Shades
    'bg-gray-600',
    'bg-gray-700',
    'bg-gray-800',
    'bg-gray-900'
];
