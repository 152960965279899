import React, { FC } from 'react';
import styles from './public83b.module.scss';
import DocumentGeneration from "../../components/DocumentGeneration/DocumentGeneration";
import Loading from "../../components/Loading/Loading";
import {useQuery} from "@tanstack/react-query";
import {TemplatesService} from "./TemplateService";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import {getYupDynamicScheme} from "../../components/DocumentGeneration/helpers/yupValidationHelpers";
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

interface TPublicOAIFile83bProps {
    disableFiling?: boolean;
}
const PublicOAIFile83b: FC<TPublicOAIFile83bProps> = ({ disableFiling }) => {
    const navigate  = useNavigate();
    const { data: initialInfo } = useQuery({
        queryKey: TemplatesService.fetchInitialInfo.queryKey,
        queryFn: () => TemplatesService.fetchInitialInfo.queryFn(),
    });
    const { templateCode, templateSpouseCode, price } = initialInfo || {};
    const template = templateCode;
    const isPublicOpenAi = true;

    const { data: fieldsConfiguration, isLoading: requiredFieldsLoading } = useQuery({
        queryKey: [...TemplatesService.fetchTemplateConfiguration.queryKey],
        queryFn: () => TemplatesService.fetchTemplateConfiguration.queryFn(template),
        enabled: Boolean(templateCode),
        gcTime: 0,
    });

    const { data: docAsHtml, isLoading: docAsHtmlLoading } = useQuery({
        queryKey: [...TemplatesService.exportDocAsHtml.queryKey],
        queryFn: () => TemplatesService.exportDocAsHtml.queryFn(template),
        enabled: Boolean(templateCode),
        gcTime: 0,
    });

    const formSchema = fieldsConfiguration?.configs ? getYupDynamicScheme(fieldsConfiguration?.configs, isPublicOpenAi) : undefined;
    if(true) {
        return (
            <Dialog visible 
                    header="Link no longer active"
                    style={{ width: '30vw' }}
                    onHide={() => navigate('/')}
                    footer={<Button label="OK" onClick={() => navigate('/')} />}>
                Please sign up or log into our platform to create an 83(b) election.  
                If you are expecting an invite from your employer,
                make sure you sign up or log in with the email
                address your employer has used.
            </Dialog> 
        )
    }
    return (
        <div className={styles.public83b}>
            {
                disableFiling && (
                    <div className={styles.disabledFilingModalOverlay}>
                        <div className={styles.modal}>
                            <div className={styles.modalHeader}>
                                <i className="bi bi-info-circle" />
                                <p className={styles.heading}>
                                    Batch Closed
                                </p>
                            </div>
                            <p className={styles.description}>
                                The 21-day window for submitting 83(b) election orders for the most recent batch of grants is currently closed.  Please check back in later for the next batch.
                            </p>
                        </div>
                    </div>
                )
            }
            {
                ((!templateCode)) ? (
                    <div className={styles.loadingWrapper}>
                        <Loading horizontalSpacing={250} />
                    </div>
                ) : (
                    <>
                        <PublicHeader />
                        <DocumentGeneration
                            isSupposeExists={false}
                            setSuppose={() => null}
                            template={template}
                            data={fieldsConfiguration}
                            requiredFieldsLoading={requiredFieldsLoading}
                            docAsHtml={docAsHtml}
                            docAsHtmlLoading={docAsHtmlLoading}
                            isPublic
                            price={price}
                            showSelectTemplate={Boolean(templateSpouseCode)}
                            formSchema={formSchema}
                            isPublicOpenAi
                            disableFiling={disableFiling}
                        />
                    </>
                )
            }
        </div>
    )
}

export default PublicOAIFile83b;
