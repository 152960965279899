import React from "react";

function GuidForSignUpload() {

    return (
        <>
            <div className="flex-grow-1">
                <h2 className="mb-3">How to Sign and Upload</h2>
                <ul className="details-help m-0 p-0">
                    <li className="font-medium text-lg">Review the Consent Statement</li>
                    <li className="font-medium text-lg">Download the Consent Statement</li>
                    <li className="font-medium text-lg">Sign the Consent Statement manually</li>
                    <li className="font-medium text-lg">Scan the signed Consent Statement</li>
                    <li className="font-medium text-lg">Return to the dashboard</li>
                    <li className="font-medium text-lg">Upload the signed Consent Statement</li>
                </ul>
            </div>
            <div className="flex-grow-1 border-left-3 border-left-solid border-100">sdsf</div>
        </>
    )
}

export default GuidForSignUpload;