import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ReactPdfViewer} from "../../../components/ReactPdfViewer/ReactPdfViewer";
import React, {useCallback, useEffect, useState} from "react";
import styles from './preview.module.scss'
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {HttpService} from "../../../services/HttpService";
type pageProps = {
    companyId: number;
}
function Preview(props:pageProps) {
    const {companyId} = props;

    const {formId} = useParams();
    const [searchParams] = useSearchParams();

    const [pdfDocumentUrl, setPdfDocumentUrl] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState(false);

    const navigate = useNavigate();
    const applyDocument = useCallback(() => {
        HttpService.post(
            `/api/companies/${companyId}/form2553/${formId}/apply`,
            {
                formId,
                companyId,
            }
        );
    },[]);

    const updateDocument = useCallback(() => {
        navigate(`/form2553/update/${formId}`);
    },[formId, companyId]);

    useEffect(() => {
        const urlFromLocal = localStorage.getItem("pdf-url");
        // @ts-ignore
        setPdfDocumentUrl(urlFromLocal);
        setIsLoaded(true);
    }, [searchParams]);

    return (
        <>
            {
                isLoaded ? (
                    <div className={styles.content}>
                        <div className={styles.actionBlock}>
                            <ButtonPrimary label="Update" onClick={updateDocument}/>
                            <ButtonPrimary label="Applay" onClick={applyDocument}/>
                        </div>
                        <div className={styles.preview}>
                            <ReactPdfViewer fileUrl={pdfDocumentUrl} isModal={false}/>
                        </div>


                    </div>

                ) :  null
            }
        </>
    )
}

export  default Preview