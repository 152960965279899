import React, { FC, useState, useEffect } from 'react';
import styles from '../questionnaire.module.scss';
import { RadioButton } from "primereact/radiobutton";
import RadioCheckboxLabel from "../../../../../uiComponents/RadioCheckboxLabel/RadioCheckboxLabel";
import {Nullable} from "primereact/ts-helpers";

const message = 'Unfortunately, we currently do not support making the Qualified Subchapter S Trust (QSST) Election.';
interface TQuestion2Props {
    setQuestionState: (questionKey: string, value: number | string) => void;
    initialValue: Nullable<'Yes' | 'No'>;
}
const Question2: FC<TQuestion2Props> = ({ initialValue, setQuestionState }) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(initialValue || null);
    const [blockerMessage, setBlockerMessage] = useState('');
    const isYesSelected = selectedValue === "Yes";

    const handleChange = (value: string) => {
        setSelectedValue(value);
        setQuestionState('question_2', value);
    };

    useEffect(() => {
        if (isYesSelected) {
            setBlockerMessage(message);
        }
    }, [isYesSelected]);

    return (
        <div className={styles.questionFrame}>
            <p className={styles.question}>
                Question 2: Will you be making a Qualified Subchapter S Trust (QSST) Election?
            </p>
            <div className={styles.options}>
                <div className={styles.optionRow}>
                    <RadioButton
                        inputId="yes"
                        name="yesNoGroup"
                        value="Yes"
                        onChange={(e) => handleChange(e.value)}
                        checked={selectedValue === "Yes"}
                    />
                    <RadioCheckboxLabel
                        label="Yes"
                        htmlFor="yes"
                    />
                </div>
                <div className={styles.optionRow}>
                    <RadioButton
                        inputId="no"
                        name="yesNoGroup"
                        value="No"
                        onChange={(e) => {
                            if (isYesSelected) {
                                setBlockerMessage('');
                            }
                            handleChange(e.value);
                        }}
                        checked={selectedValue === "No"}
                    />
                    <RadioCheckboxLabel
                        label="No"
                        htmlFor="no"
                    />
                </div>
            </div>
            {
                Boolean(blockerMessage) && (
                    <p className={styles.blockerMessage}>{blockerMessage}</p>
                )
            }
        </div>
    )
}

export default Question2;