import React, {memo, useCallback, useMemo} from "react";
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
type PropTypes = {
    // onDownload: (doc:Record<string, any>, willStepChange: boolean) => void;
    document:Record<string, any>,
    onDownload: () => Promise<void>;
}
function ShareholderCardHeader(props:PropTypes) {
    const {
        document,
        onDownload
    } = props;

    const getFilename = useCallback(() => {
        let fileName:string = "";
        if(document.documentType === "OFFICER_DOCUMENT") {
            fileName = `${document.name}'s Officer Consent Statement`
        } else if(document.documentType === "SHAREHOLDER_DOCUMENT") {
            fileName = `${document.name}'s Shareholder Consent Statement`
        } else if(document.documentType === "COVER_LETTER_DOCUMENT") {
            fileName = `${document.name}'s Cover Letter`
        }
        return fileName
    }, [document]);

    const getChipProps = useMemo(()=> {

        if(document.uploadDate === null) {
            return {
                label: "Not Uploaded Yet",
                icon: "pi pi-bolt",
                className: "border-round text-sm bg-gray-300"
            }
        } else if(document.signedDate === null) {
            return {
                label: "Uploaded",
                icon: "pi pi-hourglass",
                className: "border-round text-sm bg-indigo-200 text-indigo-500"
            }
        } else {
            return {
                label: "Submitted",
                icon: "pi pi-send",
                className: "border-round text-sm bg-green-100 text-green-400"
            }
        }

    }, [document]);

    return (
        <div className="w-full p-3 pb-4 flex justify-content-between border-bottom-1 surface-border">
            <div className="flex-1">
                <div className="flex align-items-center">
                    <span className="mr-2 font-semibold text-2xl">
                        {getFilename()}
                    </span>
                    <Chip {...getChipProps}
                          pt={{label: {className: "text-sm font-bold font-semibold"}}}/>
                </div>
                <div className="">
                    Here is the document you need to sign for the 2553 election.
                </div>
            </div>
            <div className="flex-1 flex justify-content-end">
                <Button
                    severity="secondary"
                    label="View Original Document"
                    onClick={onDownload}/>
            </div>

        </div>
    );
}

export default memo(ShareholderCardHeader);