import React, {useCallback, useEffect, useRef, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import {Accordion, AccordionTab} from "primereact/accordion";

import TabHeader from "./components/TabHeader";
import TabContent from "./components/TabContent";
import FedexInfo from "./components/FedexInfo";
import {Form2553Service} from "../../Service";
import {downloadFile} from "../../helpers";
import DashboardContent from "./components/DashboardContent";
import {HttpService} from "../../../../services/HttpService";
import {useQuery} from "@tanstack/react-query";
import NoSubmissionWidget from "../FilingList/NoSubmissionWidget";
import {Button} from "primereact/button";

type Props = {
    companyId: number;
}
function Dashboard(props:Props) {

    const {
        companyId
    } = props;
    const {formId:Id} = useParams();
    const navigate = useNavigate();
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [formId, setFormId] = useState<string>(String(Id));
    const [document, setDocument] = useState<Record<string, any>>({});
    const [shareHolders, setShareHolders] = useState<any[]>([]);
    const [orderActivities, setOrderActivities] = useState<Record<string, any>[]>([]);
    const [userRole, setUserRole] = useState<string>('');

    const toast = useRef<Toast | null>(null);

    const {data, isLoading, isFetched, isSuccess} = useQuery({
        queryKey:["form-2553-dashboard"],
        refetchInterval: 1500,
        gcTime:0,
        queryFn: async () => Form2553Service.getSignedInfo(companyId),
    });

    useEffect(() => {

        if(!isLoading && isSuccess && isFetched) {
            if(data && data.id) {

                setFormId(prev => !prev ? prev : data.id);
                setOrderActivities([...data?.activities]);
                setShareHolders(
                    data.signInfo.sort((a:any, b:any) => {
                        if(a.id > b.id) {
                            return -1;
                        }
                        if (a.id < b.id) {
                            return 1;
                        }
                        return 0;
                    })
                );

                setDocument({
                    id: data.id,
                    status: data.status,
                    orderNumber: data.orderNumber,
                    companyName: data.companyName,
                    companyEmail: data.email,
                    trackingNumber: data.trackingNumber,
                    createdDateTime: data.createdDateTime
                });
                setIsEmpty(false);

            } else {
                setIsEmpty(true);
            }
        }

    }, [isSuccess, isFetched, data]);

    const handleDownload = useCallback(async (shareholder:any) => {

        let response;
        if(shareholder.userType === "SHARE_HOLDER" ) {
            response = await Form2553Service.downloadShareHolderDocumentFromDashboard({
                companyId,
                formId,
                ...shareholder
            })
        } else if (shareholder.userType === "OFFICER") {
            response = await Form2553Service.downloadOfficerDocumentFromDashboard({
                companyId,
                formId,
                ...shareholder
            })
        }

        const {url, fileName} = response;
        downloadFile(url, fileName);

    }, [companyId, formId]);

    const handleSubmit = useCallback(async  (shareholder:any) => {
        const {userType, shareholderId, documentType} = shareholder;
        try {
            if(userType === "SHARE_HOLDER") {
                await HttpService.post(
                    `/api/companies/${companyId}/form2553/${formId}/file/shareholder/${shareholderId}/sign`
                )
            } else if(userType === "OFFICER") {
                const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER"
                await HttpService.post(
                    `/api/companies/${companyId}/form2553/${formId}/file/sign/${isCoverLetter}`
                );
            }
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully submitted"
            });
        } catch (e) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        }

    }, [companyId, formId]);

    const handleConfirm = useCallback(() => {
        HttpService.post(`/api/companies/${companyId}/form2553/${formId}/confirm`, {
            formId,
            companyId,
        }).then(res => {
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully Confirmed"
            });
        }).catch(err => {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        });
    }, [companyId, formId]);

    useEffect(() => {

        HttpService.get('/api/users/info').then(res => {
            res.data.companies?.map((company:any) => {
                if(company.companyId === companyId) {
                    setUserRole(company.role);
                }
            })
        })
    },[companyId]);

    return (
        <div className="w-full px-6 py-5">
            <Toast ref={toast}/>
            {
                !isEmpty ? (
                    <div className="grid align-items-start">
                        <div className="col col-12 md:col-9">
                            <DashboardContent document={document} handleConfirm={handleConfirm}>
                                <Accordion className="mt-4">
                                    {
                                        shareHolders?.map((shareholder: any, index: number) => {
                                            return (
                                                <AccordionTab key={shareholder.id}
                                                              headerTemplate={
                                                                  <TabHeader index={index}
                                                                             formId={formId}
                                                                             userRole={userRole}
                                                                             companyId={companyId}
                                                                             document={document}
                                                                             shareholder={shareholder}
                                                                             handleSubmit={handleSubmit}
                                                                             handleDownload={handleDownload}
                                                                  />}
                                                              pt={{content: {style: {backgroundColor: "#fafafa"}}}}
                                                >
                                                    <TabContent document={document}
                                                                shareholder={shareholder}
                                                                handleDownload={handleDownload}/>
                                                </AccordionTab>
                                            )
                                        })
                                    }
                                </Accordion>
                            </DashboardContent>

                        </div>
                        <div className="col col-12 md:col-3">
                            {
                                !isLoading ? (
                                    <FedexInfo formId={formId}
                                               companyId={companyId}
                                               activities={orderActivities}
                                               trackingNumber={document.trackingNumber}/>
                                ) : null
                            }

                        </div>
                    </div>
                ) : (
                    <div className="w-full px-6 py-5 flex justify-content-between">
                        <NoSubmissionWidget goToCreation={() => navigate('/form2553/create')}/>
                    </div>
                )
            }
        </div>
    )
}

export default Dashboard