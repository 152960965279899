import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import sharedStyles from "../../assets/sharedStyles.module.scss";
import React from "react";

type ReactPdfViewerProps = {
  fileUrl: string;
  isModal?: boolean;
  closePreview?: () => void;
};

export const ReactPdfViewer = (props: ReactPdfViewerProps) => {
    const {
        fileUrl,
        closePreview,
        isModal=false
    } = props;
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
          <div
              style={{
                  height: '100%',
                  position: 'relative',
              }}
          >
              {
                  isModal ? (
                      <button className={sharedStyles.closeDocPreview} onClick={closePreview}>
                          <i className="bi bi-x"/>
                      </button>
                  ) : null
              }
              <Viewer fileUrl={fileUrl} {...(isModal && {plugins:[defaultLayoutPluginInstance]})}/>
          </div>
      </Worker>
  );
};
