import React, {useState, FC} from 'react';
import axios from "axios";
import logo from '../../assets/images/83b-logo-corpora.png';
import styles from './authentication.module.scss';
import ConfirmSignUpForm, {TRequestRecoverPasswordValues} from "./components/RequestPasswordRecoveryForm";
import {useToast} from "../../hooks/useToast";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import SetNewPasswordForm, {TSetNewPasswordValues} from "./components/SetNewPasswordForm";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const baseUrl = import.meta.env.VITE_CORPORA_SERVICE_URL;

const RecoverPassword: FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [isRequested, setRequested] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const navigate = useNavigate();
    const { show } = useToast();

    const onRequestRecoverPassword = (values: TRequestRecoverPasswordValues) => {
        setUsername(values.username);
        axios.post(`${baseUrl}api/auth/forget-password`, values)
            .then(() => {
                setRequested(true);
            })
            .catch((error) => {
                const errorData = {
                    error: error,
                    show: show,
                    cb: () => {
                        setLoading(false);
                    }
                };
                extractErrorMessage(errorData);
            });
    }

    const onSetNewPassword = (values: TSetNewPasswordValues) => {
        axios.post(`${baseUrl}api/auth/confirm/forget-password`, values)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                const errorData = {
                    error: error,
                    show: show,
                    cb: () => {
                        setLoading(false);
                    }
                };
                extractErrorMessage(errorData);
            });
    }

    return (
        <div className={styles.authentication}>
            <div className={styles.holder}>
                <div className={styles.logoRow}>
                    <img src={logo} alt="" />
                </div>
                {
                    isRequested ? (
                            <SetNewPasswordForm
                                onSubmit={onSetNewPassword}
                                isLoading={isLoading}
                                email={username}
                            />
                        )
                        : (
                            <ConfirmSignUpForm
                                isLoading={isLoading}
                                onSubmit={onRequestRecoverPassword}
                            />
                    )
                }
                <p className={styles.terms}>
                    © {moment().format("YYYY")} Corpora, Inc.
                </p>
            </div>
        </div>
    )
}

export default RecoverPassword;
