import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import {requests} from "../../services/http-common";
import * as stripeJs from "@stripe/stripe-js";

import styles from './subscriptions.module.scss';

const isStaging = import.meta.env.VITE_PROFILE === 'staging';
const paymentKey = isStaging ?
    "pk_test_51NT2e4IivIfmPuEIxcPIdXQmsLAufjVevAxDGLCLLAmnXA72qzeEJlomIBICXI0CrpZM36yD7hc6FZnUEeUb12NX00eQjZpa4V" :
    "pk_live_51NT2e4IivIfmPuEIRJehwqQSK6gjUNLmMVUUbe0DrJajVHqpzb8wDaCVmjIuyodfvKBKzbheXi3Om8eTqixQhek4007QmuWiN4"

const stripePromise = loadStripe(paymentKey);

// @ts-ignore
function SubscribeIntent({companyId}){
    const [clientSecret, setClientSecret] = useState('');
    const [isClientSecretLoaded, setIsClientSecretLoaded] = useState(false);
    useEffect(() => {
        requests.post(
            `/api/companies/${companyId}/payment-methods/setup-intent`,
            {}
        ).then(secret => {
            setClientSecret(secret.clientSecret);
            setIsClientSecretLoaded(true);
        })
    }, []);

    const options:stripeJs.StripeElementsOptions = {
        clientSecret,
    };

    return (
        <div className={styles.subscriptions}>
            {(clientSecret && isClientSecretLoaded) ? (
                <div className='flex container mt-8 w-full'>
                    <Elements stripe={stripePromise} options={options} >
                        <CheckoutForm clientSecret={clientSecret} companyId={companyId}/>
                    </Elements>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}

export default SubscribeIntent;
