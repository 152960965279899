import React, { FC, useState } from 'react';
import styles from '../questionnaire.module.scss';
import { RadioButton } from "primereact/radiobutton";
import RadioCheckboxLabel from "../../../../../uiComponents/RadioCheckboxLabel/RadioCheckboxLabel";
import {Nullable} from "primereact/ts-helpers";

const message = 'You will need to explain the reasonable cause for failure to timely file the election.';
const keys = {
    timelyElection: 'timelyElection',
    lateElection: 'lateElection',
}
interface TQuestion2Props {
    setQuestionState: (questionKey: string, value: number | string) => void;
    initialValue: Nullable<'timelyElection' | 'lateElection'>;
}
const Question3: FC<TQuestion2Props> = ({ setQuestionState, initialValue }) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(initialValue || null);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);

    const handleChange = (value: string) => {
        setSelectedValue(value);
        setQuestionState('question_3', value as string);
    };

    return (
        <div className={styles.questionFrame}>
            <p className={styles.question}>
                Question 3: Are you making a timely or late S corporation election?
            </p>
            <div className={styles.options}>
                <div className={styles.optionRow}>
                    <RadioButton
                        inputId={keys.timelyElection}
                        name="yesNoGroup"
                        value={keys.timelyElection}
                        onChange={(e) => {
                            handleChange(e.value);
                            setWarningMessage('');
                        }}
                        checked={selectedValue === keys.timelyElection}
                    />
                    <RadioCheckboxLabel
                        label="Timely election"
                        htmlFor={keys.timelyElection}
                    />
                </div>
                <div className={styles.optionRow}>
                    <RadioButton
                        inputId={keys.lateElection}
                        name="yesNoGroup"
                        value={keys.lateElection}
                        onChange={(e) => {
                            handleChange(e.value);
                            setWarningMessage(message);
                        }}
                        checked={selectedValue === keys.lateElection}
                    />
                    <RadioCheckboxLabel
                        label="Late election"
                        htmlFor={keys.lateElection}
                    />
                </div>
            </div>
            {
                Boolean(warningMessage) && (
                    <p className={styles.warningMessage}><i className="bi bi-info-circle"/> {warningMessage}</p>
                )
            }
        </div>
    )
}

export default Question3;