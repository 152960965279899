import React, {memo, useEffect, useState} from "react";
import {classNames} from "primereact/utils";
import {formatDate} from "../../../helpers";
import styles from './orderTracking.module.scss';
import moment from "moment";
type Props = {
    tracking:Record<string, any>[],
}
function OrderTracking(props:Props) {
    const {tracking} = props;
    const [timeLines, setTimeLines] = useState<Record<string, any>>([])

    useEffect(() => {
        const timeLines = tracking.sort((a, b) => {
            const dateA = new Date(a.createdDateTime).getTime();
            const dateB = new Date(b.createdDateTime).getTime();
            if (dateA < dateB) {
                return 1;
            }
            if (dateA > dateB) {
                return -1;
            }
            return 0;
        });
        setTimeLines(timeLines)
    }, [tracking]);


    return (
        <ul className="list-none">
            {
                timeLines.map((item:Record<string, any>, index:number) => {
                    return (
                        <li key={index} className={classNames("pb-4", styles.trackItem, {
                            [styles.current]: index === 0,
                        })}>
                            <div className="ml-4 flex flex-column">
                                <div className="w-full flex justify-content-between">
                                    <span className="font-bold text-black-alpha-90">{item.owner}</span>
                                    <span className="font-bold text-black-alpha-90">
                                        { moment(item.createdDateTime).format('YYYY-MM-DD hh:mm')}
                                    </span>
                                </div>
                                <div>
                                    {item.description}
                                </div>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default memo(OrderTracking);