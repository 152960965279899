import React, { FC, useMemo } from 'react';
import styles from './stepsControl.module.scss';
import {classNames} from "primereact/utils";

export type TStep =
    'BUSINESS_INFORMATION' |
    'LATE_ELECTION_JUSTIFICATION' |
    'SHAREHOLDER_INFORMATION' |
    'SIGNER_INFORMATION' |
    'TERMS_AND_PREVIEW' |
    'PUBLISH' |
    'OFFICER_INFO' |
    'DOWNLOAD' |
    'UPLOAD' |
    'SUBMIT' |
    'SIGNED' |
    'PAY'

export const stepsKeys: Record<TStep, TStep> = {
    BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
    LATE_ELECTION_JUSTIFICATION: 'LATE_ELECTION_JUSTIFICATION',
    SHAREHOLDER_INFORMATION: 'SHAREHOLDER_INFORMATION',
    SIGNER_INFORMATION: 'SIGNER_INFORMATION',
    TERMS_AND_PREVIEW: 'TERMS_AND_PREVIEW',
    PUBLISH: 'PUBLISH',
    OFFICER_INFO:'OFFICER_INFO',
    DOWNLOAD:'DOWNLOAD',
    UPLOAD:'UPLOAD',
    SUBMIT:'SUBMIT',
    SIGNED:'SIGNED',
    PAY: 'PAY',
};

interface TStepsControlProps {
    useCustomSteps?:boolean,
    currentStep: TStep,
    isLateElection?: boolean;
}
const StepsControl: FC<TStepsControlProps> = (
{
    useCustomSteps=false,
    currentStep,
    isLateElection,
}) => {
    const steps = useMemo(() => {
        if(useCustomSteps) {
            return [
                {
                    name: stepsKeys.DOWNLOAD,
                    label: 'Download',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.UPLOAD,
                    label: 'Upload',
                    iconClassName: 'pi pi-file',
                },
                {
                    ...((currentStep === stepsKeys.SIGNED) ? {
                        name: stepsKeys.SIGNED,
                        label: 'Submitted',
                        iconClassName: 'pi pi-file',
                    } : {
                        name: stepsKeys.SUBMIT,
                        label: 'Submit',
                        iconClassName: 'pi pi-file',
                    })
                }
            ]
        }
        return (
            [
                {
                    name: stepsKeys.BUSINESS_INFORMATION,
                    label: 'Business Info',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.LATE_ELECTION_JUSTIFICATION,
                    label: 'Election Type',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.OFFICER_INFO,
                    label: 'Officer info',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.SHAREHOLDER_INFORMATION,
                    label: 'Shareholders',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.TERMS_AND_PREVIEW,
                    label: 'Preview & Terms',
                    iconClassName: 'pi pi-file',
                },
                {
                    name: stepsKeys.PAY,
                    label: 'Pay',
                    iconClassName: 'pi pi-money-bill',
                },
                {
                    name: stepsKeys.PUBLISH,
                    label: 'Publish',
                    iconClassName: 'pi pi-file',
                },
            ]
        )
    }, [isLateElection, useCustomSteps]);

    return (
        <div className={classNames(styles.stepsControl, {
            [styles.bgAndBorder]:!useCustomSteps
        })} >
            {
                steps.map((item, index) => {
                    const { label, iconClassName, name } = item;
                    const isCurrent = name === currentStep;
                    const currentStepIndex = steps.findIndex(item => item.name === currentStep);
                    const progressFilled = currentStepIndex >= index - 1;
                    const progressGreen = currentStepIndex >= index;
                    let isCompleted = currentStepIndex > index;
                    if(useCustomSteps && currentStep === stepsKeys.SIGNED) {
                        isCompleted = true;
                    }
                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <div className={styles.lineItem}>
                                    <span className={styles.track}>
                                        <span className={classNames(styles.bar, {
                                            [styles.barInProgress]: progressFilled,
                                            [styles.barCompleted]: progressGreen,
                                        })}
                                        />
                                    </span>
                                </div>
                            )}
                            <div className={classNames(styles.stepItem, {
                                [styles.current]: !isCompleted && isCurrent,
                                [styles.completed]: isCompleted,
                            })}>
                                <div className={styles.stepWidget}>
                                    <div className={styles.iconHolder}>
                                        <i className={isCompleted ? 'pi pi-check' : iconClassName} />
                                    </div>
                                </div>
                                <div className={styles.labels}>
                                    <p>{`STEP ${index + 1}`}</p>
                                    <p>{label}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

export default StepsControl;
