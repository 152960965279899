import React, {memo, ReactElement, useCallback, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {HttpService} from "../../../../services/HttpService";
import {AxiosError} from "axios";
import ConfirmDialogCustom from "../../../../uiComponents/ConfirmDianlogCustom/ConfirmDialogCustom";
import deleteIcon from "../../../../assets/images/delete-icon.svg";
type Props = {
    toastRef:Function
    document: any,
}

type statusType = {
    name:string,
    code:string,

}
const statuses:statusType[] = [
    { name: 'Shipped', code: "SHIPPED" },
    { name: 'Delivered', code: "DELIVERED" },
    { name: 'Acknowledged', code: 'ACKNOWLEDGED' },
    { name: 'Completed', code: 'COMPLETED' },
    { name: 'Overdue', code: 'OVERDUE' },
    { name: 'Rejected', code: 'REJECTED' }
];

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;
function ActionsBlock(props:Props):ReactElement {
    const {
        document,
        toastRef,
    } = props;

    const [status, setSelectedStatus] = useState<statusType | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isVisibleUploadDialog, setVisibleUploadDialog] = useState<boolean>(false);
    const openUploadDialog = useCallback(() => {
        setVisibleUploadDialog(true);
    }, [document]);

    const handleDelete = useCallback(() => {
        HttpService.delete(
            `/api/admin/form2553/${document.id}`,
            {baseURL}
        ).then(res => {
            toastRef({
                severity: "success",
                summary: "Success",
                detail: "Document successfully deleted"
            });
            setShowDeleteDialog(false);
        }).catch(err => {
            toastRef({
                severity: 'error',
                detail: err.message.data.detail
            });
            setShowDeleteDialog(false);
        })
    },[document]);

    const handleUpload = useCallback(async (event:FileUploadHandlerEvent) => {

        const selectedFile= event.files[0];

        try {
            const {data}  = await HttpService.get(`/api/admin/form2553/${document.id}/upload-url/${status?.code}`, {baseURL});
            await fetch(data.url, {
                method: 'PUT',
                body: selectedFile,
                headers: {
                    'Content-Type': selectedFile.type,
                },
            });

            await  HttpService.put(`/api/admin/form2553/${document.id}/upload-confirm/${status?.code}`, {}, {baseURL});
            toastRef({
                severity: "success",
                summary: "Success",
                detail: "Document successfully uploaded"
            });
            // @ts-ignore
        } catch (error: AxiosError) {
            const {response:{data}} = error;
            let message: string = '';
           if(Object.keys(data.fieldErrors).length > 0) {
               Object.values(data.fieldErrors).forEach((errorText) => {
                   message = message.concat(`${errorText}\n`)
               })
           } else {
               message = data.detail;
           }
            toastRef({
                severity: 'error',
                detail: message
            });
        }
    }, [document, status]);

    return (
        <div className="w-full">
            <Button text
                    rounded
                    severity="help"
                    icon="pi pi-upload"
                    onClick={openUploadDialog}/>
            <Button text
                    rounded
                    icon="pi pi-trash"
                    severity="danger"
                    onClick={() => setShowDeleteDialog(true)}/>

            <Dialog header="Upload Document"
                    onHide={() => {
                        setVisibleUploadDialog(false);
                        setSelectedStatus(undefined);
                    }}
                    visible={isVisibleUploadDialog}
                    style={{ width: '30vw'}}>
                <div className="w-full">
                    <Dropdown value={status}
                              required
                              onChange={(e) => setSelectedStatus(e.value)}
                              options={statuses}
                              optionLabel="name"
                              className="w-full"
                              placeholder="Select a Status" />
                    <div className="my-3">
                        <FileUpload mode="basic"
                                    name="file"
                                    accept="application/pdf"
                                    chooseOptions={{label:"Choose a file", icon: "pi pi-file", className: "p-button-primary w-full" }}
                                    customUpload
                                    uploadHandler={handleUpload} />
                    </div>

                </div>
            </Dialog>

            <ConfirmDialogCustom
                header="Deleting the document"
                acceptLabel="Confirm"
                rejectLabel="Cancel"
                message="Are you sure you want to delete the document?"
                visible={showDeleteDialog}
                onHide={() => setShowDeleteDialog(false)}
                accept={handleDelete}
                iconSrc={deleteIcon}
            />
        </div>
    )
}

export default memo(ActionsBlock);