import React, {FC, useState} from 'react';
import usePagination from "../../../../hooks/usePagination";
import generateUrlParams from "../../../../helpers/generateUrlParams";
import {useQuery} from "@tanstack/react-query";
import {Form2553Service} from "../../Service";
import styles from './fillingList.module.scss';
import Loading from "../../../../components/Loading/Loading";
import ButtonPrimary from "../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import {NavLink, useNavigate} from "react-router-dom";
import sharedStyles from "../../../../assets/sharedStyles.module.scss";
import ReactPaginate from "react-paginate";
import {Dropdown} from "primereact/dropdown";
import {perPageOptions} from "../../../../config/config";
import NoSubmissionWidget from "./NoSubmissionWidget";
import {classNames} from "primereact/utils";
import ThElement from "../../../../uiComponents/TableTypeA/ThElement";
import TdElement from "../../../../uiComponents/TableTypeA/TdElement";
import {TForm2553Information} from "../../Form2553Creation/CreationControl/types";
import AvatarFrame from "../../../../uiComponents/AvatarFrame/AvatarFrame";
import {Button} from "primereact/button";

interface TFilingListProps {
    companyId: number;
}
const FilingList: FC<TFilingListProps> = ({ companyId }) => {
    const { params, changePage, changePerPage } = usePagination();
    const { page, size} = params;
    const navigate = useNavigate();

    const urlParams = generateUrlParams({
        page,
        size,
    });

    const { data: filesData, isLoading, refetch} = useQuery({
        queryKey: ['form-2553-files', page, size],
        queryFn: () =>  Form2553Service.getForm2553Files(urlParams, companyId),
        gcTime: 0,
    });
    const goToCreation = () => {
        navigate('/form2553/create');
    }
    const goToUpdate = (id: number) => {
        navigate(`/form2553/update/${id}`);
    }
    const goToDetails = (id: number) => {
        navigate(`/form2553/details/${id}`);
    }
    const files = filesData?.data?.content || [];
    const isZeroRecords = files.length === 0;
    const totalElements = filesData?.data?.totalElements || 0;
    const pageCount = Math.ceil(totalElements / size);
    const onPageChange = ({ selected }: { selected: number }) => {
        changePage(selected);
    }
    return (
        <div className={styles.filings}>
            {
                !isZeroRecords && (
                    <div className={styles.header}>
                        <div></div>
                        <div>
                            <ButtonPrimary
                                styleType="secondaryOutline"
                                onClick={goToCreation}
                                iconClassName="bi-plus"
                                label="Add new Submission"
                            />
                        </div>
                    </div>
                )
            }
            <div className={styles.tableHolder}>
                {
                    isLoading ? (
                        <div className={styles.loadingWrapper}>
                            <Loading horizontalSpacing={191}/>
                        </div>
                    ) : (
                        <div className={styles.tableWrapper}>
                            {
                                isZeroRecords ? (
                                    <NoSubmissionWidget goToCreation={goToCreation}/>
                                ) : (
                                    <table className={classNames(sharedStyles.tableTypeA)}>
                                        <thead>
                                        <tr className={sharedStyles.tableRow}>
                                            <ThElement label="Name"/>
                                            <ThElement label="Status"/>
                                            <ThElement label="Stock number"/>
                                            <ThElement label="Email"/>
                                            <ThElement label="Uploaded document"/>
                                            <ThElement/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            files.map((item: TForm2553Information) => {
                                                const {status} = item;
                                                const businessInformation = item?.businessInformation || {};
                                                const {entityName} = businessInformation;
                                                return (
                                                    <>
                                                        <tr className={classNames(sharedStyles.tableRow)} key={item.id}>
                                                            <TdElement>
                                                                <AvatarFrame fullName={entityName || '-'}/>
                                                                <div className={styles.names}>
                                                                    <p>{entityName || '-'}</p>
                                                                </div>
                                                            </TdElement>
                                                            <TdElement value={status}/>
                                                            <TdElement/>
                                                            <TdElement/>
                                                            <TdElement/>
                                                            <TdElement>
                                                                <div className={styles.actionButtons}>
                                                                    <NavLink to={`/form-2553-dashboard/${item.id}`}
                                                                             className="p-button p-component p-button-outlined p-button-link p-button-secondary">
                                                                    View
                                                                    </NavLink>
                                                                    <ButtonPrimary
                                                                        disabled={(item.status === "SUBMITTED")}
                                                                        onClick={() => goToUpdate(item.id)}
                                                                        label="Update"
                                                                    />
                                                                    <ButtonPrimary
                                                                        onClick={() => goToDetails(item.id)}
                                                                        label="Details"
                                                                    />
                                                                </div>
                                                            </TdElement>
                                                        </tr>
                                                        {/*<tr>*/}
                                                        {/*    <TdElement colSpan={6}>*/}
                                                        {/*        <div className={styles.infoSection}>*/}
                                                        {/*            <div className={styles.infoColumn}>*/}
                                                        {/*                <p className={styles.head}>Shareholder address</p>*/}
                                                        {/*                <div className={styles.infoGrid}>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Country</p>*/}
                                                        {/*                        <p>Indonesia</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Address</p>*/}
                                                        {/*                        <p>H. Sahyan</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>City</p>*/}
                                                        {/*                        <p>Yerevan</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>State</p>*/}
                                                        {/*                        <p>123456</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Zip code</p>*/}
                                                        {/*                        <p>1232</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className={styles.infoColumn}>*/}
                                                        {/*                <p className={styles.head}>Other Information</p>*/}
                                                        {/*                <div className={styles.infoGrid}>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Social Security Number (SSN)</p>*/}
                                                        {/*                        <p>423-34-23444</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Date acquired</p>*/}
                                                        {/*                        <p>02/15/25</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                    <div className={styles.gridItem}>*/}
                                                        {/*                        <p>Tax year end</p>*/}
                                                        {/*                        <p>11/24</p>*/}
                                                        {/*                    </div>*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </TdElement>*/}
                                                        {/*</tr>*/}
                                                    </>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div>
                {
                    !isZeroRecords && (
                        <div className={sharedStyles.paginationControl}>
                            <div className={sharedStyles.perPageRow}>
                                {`Showing ${(page) * size + 1} to ${((page) * size) + files.length} of ${totalElements} entries`}
                            </div>
                            <div className={sharedStyles.changeControl}>
                                <ReactPaginate
                                    className={sharedStyles.paginationWrapper}
                                    breakLabel="..."
                                    nextLabel={<div className={sharedStyles.pItem}>{`>`}</div>}
                                    onPageChange={onPageChange}
                                    pageRangeDisplayed={size}
                                    pageCount={pageCount}
                                    previousLabel={<div className={sharedStyles.pItem}>{`<`}</div>}
                                    renderOnZeroPageCount={null}
                                    pageLabelBuilder={(page) => (
                                        <div className={sharedStyles.pItem}>{page}</div>
                                    )}
                                    activeClassName={sharedStyles.pItemActive}
                                    forcePage={page}
                                />
                                <div className={sharedStyles.perPageHolder}>
                                    <Dropdown
                                        options={perPageOptions}
                                        value={size}
                                        onChange={(e) => {
                                            changePerPage(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default FilingList;
