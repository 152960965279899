import React, { FC } from 'react';
import {TShareholderInformationParams} from "./types";
import sharedStyles from '../shared.module.scss';
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import AvatarFrame from "../../../../../uiComponents/AvatarFrame/AvatarFrame";
import styles from "./shareholders.module.scss";

interface TShareholdersTableProps {
    shareholders: TShareholderInformationParams[];
    validateAndNext: () => void;
    noShareholderAdded: boolean;
    onPrev: () => void;
    setIsShareholderCreate: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteShareholder: (id: number) => void;
    deleteLoading: boolean;
    updateShareholderInfo: (info: TShareholderInformationParams) => void;
}
const ShareholdersList: FC<TShareholdersTableProps> = ({
shareholders,
validateAndNext,
noShareholderAdded,
onPrev,
setIsShareholderCreate,
onDeleteShareholder,
deleteLoading,
updateShareholderInfo,
}) => {
    return (
        <div className={sharedStyles.form}>
            <div className={sharedStyles.fieldsHolder}>
                <div className={sharedStyles.scroll}>
                    <div className={styles.shareholders}>
                        <div className={styles.topRow}>
                            <p>Shareholders</p>
                            <button type="button" onClick={() => setIsShareholderCreate(true)}>
                                + New Shareholder
                            </button>
                        </div>
                        {
                            shareholders.length > 0 ? (
                                <div className={styles.shareholdersGrid} style={{ opacity: deleteLoading ? '0.3' : 1 }}>
                                    {
                                        shareholders.map((shareholder: TShareholderInformationParams, index) => {
                                            const {
                                                id,
                                                name,
                                                ownershipPercentage,
                                                numberOfShares,
                                                ein,
                                                dateAcquired,
                                                taxYearEndMonthAndDay,
                                                email,
                                            } = shareholder;
                                            return (
                                                <div key={id} className={styles.shareholderItem}>
                                                    <div className={styles.col_1}>
                                                        <div className={styles.hd}>
                                                            <AvatarFrame fullName={name}/>
                                                            <div className={styles.hdLabels}>
                                                                <p>{name}</p>
                                                                <p>{email}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className={styles.col_2}>
                                                        <div className={styles.hd}>
                                                            <div className={styles.hdLabels}>
                                                                <p>{numberOfShares}</p>
                                                                <p>Shares</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.col_2}>
                                                        <div className={styles.hd} style={{ justifyContent: 'flex-end' }}>
                                                            <button type="button" className={styles.removeBtn} onClick={() => updateShareholderInfo(shareholder)}>
                                                                <i className="bi bi-pencil"></i>
                                                            </button>
                                                            <button type="button" className={styles.removeBtn} onClick={() => onDeleteShareholder(id as number)}>
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <p className={styles.noShareholders} style={{padding: '48px 0'}}>
                                    No shareholders added.
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={sharedStyles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={onPrev}
                    type="button"
                    label="Back"
                />
                <ButtonPrimary
                    disabled={noShareholderAdded}
                    onClick={validateAndNext}
                    type="button"
                    label="Next"
                />
            </div>
        </div>
    )
}

export default ShareholdersList;
