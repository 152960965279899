import React, {FC, useMemo} from 'react';
import styles from '../shared.module.scss';
import LateElectionForm from "./LateElectionForm";
import {SubmitHandler} from "react-hook-form";
import {TLateElectionFormValues} from "./types";
import {useToast} from "../../../../../hooks/useToast";
import {useMutation} from "@tanstack/react-query";
import {Form2553Service} from "../../../Service";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";
import {TForm2553Information} from "../../CreationControl/types";
import {requests} from "../../../../../services/http-common";

interface TLateElectionProps {
    companyId: number;
    formId: string;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
    form2553Information: TForm2553Information;
    reFetch2553Data: () => void;
    isLateElection:boolean;
}

const LateElection: FC<TLateElectionProps> = ({
companyId,
formId ,
setStep,
form2553Information,
reFetch2553Data,
isLateElection
}) => {
    const { show } = useToast();
    const postLateElectionJustification = useMutation({
        mutationFn: Form2553Service.postLateElectionJustification,
        onSuccess: () => {
            reFetch2553Data();
           setStep(stepsKeys.OFFICER_INFO);
        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: postLateElectionJustification.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit: SubmitHandler<TLateElectionFormValues> = (values) => {
        postLateElectionJustification.mutate({ params: values, companyId, formId });
    }

    const lateJustificationFormInitialValues = useMemo(
        () => {
            const isLateElection = form2553Information?.initialFormDetails?.lateElectionQuestionEnabled;
            const justificationText = form2553Information?.lateElectionJustification?.justificationText;
            return {
                lateElectionQuestionEnabled:isLateElection,
                justificationText: justificationText || '',
            }
        }, [form2553Information]
    );

    const onPrev = () => {
        setStep(stepsKeys.BUSINESS_INFORMATION);
    }

    const mutateElectionType = useMutation({
        mutationFn:Form2553Service.mutateElection,
        onSuccess: () => {

        },
        onError: (error: AxiosError<{ detail: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: mutateElectionType.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        }
    });
    const handleChangeLateElection = (election:string)=> {
        let isLateElection = false;
        if(election === 'Late'){
            isLateElection = true;
        }
        mutateElectionType.mutate({
            formId,
            companyId,
            lateElectionQuestionEnabled:isLateElection,
        })
    };


    return (
        <div className={styles.stepSection}>
            <div className={styles.formColumn}>
                <LateElectionForm
                    isLateElection={isLateElection}
                    onSubmit={onSubmit}
                    onChange={handleChangeLateElection}
                    // @ts-ignore
                    initialValues={lateJustificationFormInitialValues}
                    onPrev={onPrev}
                />
            </div>
        </div>
    )
}

export default LateElection;
