import {useMutation, useQuery} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, {useEffect, useMemo, useRef, useState, FC} from 'react';

import Loading from '../Loading/Loading';
import { extractErrorMessage } from '../../helpers/extractErrorMessage';
import { useToast } from '../../hooks/useToast';
import {requests} from '../../services/http-common';
import Form from './components/Form';
import ReviewStep from './components/ReviewStep';
import TermsStep from './components/TermsStep';
import styles from './styles.module.scss';
import {
  stepsKeys,
  TDataItem,
  TFormStep,
} from './types';
import StepsControl from "./components/StepsControl/StepsControl";
import SubmissionCreatedModal from "./components/SubmissionCreatedModal/SubmissionCreatedModal";
import useDocumentGenerationDetails from "./useDocumentGenerationDetails";
import {useForm} from "react-hook-form";
import ProcessingLoading from "./components/ProcessingLoading";
import FileView from "./components/FileView";
import FinishStep from "./components/FinishStep/FinishStep";
import {TemplatesService} from "../../pages/PublicFile83b/TemplateService";
import {useNavigate} from "react-router-dom";
import {TSelectedService, TTransportType} from "../../@types/global";
import {transportTypes} from "../../config/config";
import EmbeddedPayment from "./components/EmbeddedPayment/EmbeddedPayment";
import {Nullable} from "primereact/ts-helpers";
import { yupResolver } from '@hookform/resolvers/yup';

const formStepInitialState = {
  active: 1,
  total: 2,
};

interface TDocumentGenerationProps {
  setSuppose: (isExists: boolean) => void;
  isSupposeExists: boolean;
  template: string;
  data: {configs: TDataItem[]};
  requiredFieldsLoading: boolean;
  docAsHtml: any;
  docAsHtmlLoading: boolean;
  formSchema: any;
  showSelectTemplate?: boolean;
  companyId?: number;
  email?: string;
  fullName?: string;
  isPublic?: boolean;
  price?: number;
  uspsPrice?: number;
  preFilledFormValues?: Record<string, string | number>;
  file83bUpdate?: boolean;
  preFilledFormValuesLoading?: boolean;
  fileId?: string;
  signAfterEmbeddedPaymentOrderNumber?: Nullable<string>;
  paymentEnabled?: boolean;
  isPublicOpenAi?: boolean;
  disableFiling?: boolean;
  isPublicDraft?: boolean;
  isEveryCompany?: boolean;
  showDeliveryService?: boolean;
}

const DocumentGeneration: FC<TDocumentGenerationProps> = ({
  companyId = 0,
  email,
  fullName,
  isSupposeExists,
  setSuppose,
  template,
  data,
  requiredFieldsLoading,
  docAsHtml,
  docAsHtmlLoading,
  showSelectTemplate,
  isPublic,
  price,
  uspsPrice,
  preFilledFormValues,
  file83bUpdate,
  preFilledFormValuesLoading,
  fileId,
  signAfterEmbeddedPaymentOrderNumber,
  paymentEnabled,
  formSchema,
  isPublicOpenAi,
  disableFiling,
  isPublicDraft,
  isEveryCompany,
  showDeliveryService
}) => {
  const [step, setStep] = useState(stepsKeys.filling);
  const [isTermsAccepted, setTermsState] = useState(false);
  const [isGlobalLoading, setGlobalLoading] = useState<boolean>(false);
  const [requestedEmail, setRequestedWithEmail] = useState<string>('');
  const [showSuccessPopup, setShowSuccess] = useState<boolean>(false);
  const [negativeValueWarning, setNegativeValueWarning] = useState(false);
  const [capturedEmailList, setCapturedEmailList] = useState<string[]>([]);
  const submissionCompletedRef = useRef(false);
  const [showSubmissionSuccessModal, setSubmissionSuccessModal] = useState(false);
  const { show } = useToast();
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState<TFormStep>(formStepInitialState);
  const { active, total } = formStep;
  const [transportType, setTransportType] = useState<TTransportType>(transportTypes.FEDEX);
  const [clientSecret, setClientSecret] = useState('');
  const [selectedService, selectService] = useState<TSelectedService>('paid');
  const isPaidSelected = selectedService === 'paid';
  const currentStepYubValidator = (formSchema || {})[`${active}`];
  const form = useForm({
    resolver: yupResolver(currentStepYubValidator),
    mode: 'onChange',
    defaultValues: {},
  });
  const { reset, watch } = form;
  const formValues: Record<string, string | number | Date | undefined> = watch();
  const {
    formatNumberWithCommas,
    formatSSN,
    calculateFormula,
    formatDate,
  } = useDocumentGenerationDetails();

  const { data: embeddedSignatureData, isLoading: embeddedSignatureDataLoading } = useQuery({
    queryKey: ['embedded-url-after-payment', isSupposeExists, companyId],
    queryFn: async () => {
      return requests.get(`/api/companies/${companyId}/file83b/embedded-url/${signAfterEmbeddedPaymentOrderNumber}`);
    },
    gcTime: 0,
    enabled: Boolean(signAfterEmbeddedPaymentOrderNumber),
  });
  const embeddedSigningUrl = embeddedSignatureData?.embeddedSigningUrl;

  const setInitialState = () => {
      setSuppose(isSupposeExists);
    setStep(stepsKeys.filling);
    setTermsState(false);
    setGlobalLoading(false);
    setRequestedWithEmail('');
    setShowSuccess(false);
    setCapturedEmailList([]);
    setFormStep(formStepInitialState);
  };

  const showFrame = (url: string) => {
    // @ts-ignore
    const signWellEmbed = new SignWellEmbed({
      url: url,
      events: {
        completed: () => {
          submissionCompletedRef.current = true;
        },
        closed: () => {
          localStorage.removeItem("selectedFormType")
          if (submissionCompletedRef.current) {
            setSubmissionSuccessModal(true);
          } else {
            navigate('/filing');
          }
        },
      }
    })
    signWellEmbed.open();
  }

  const createAndRedirectToToPayment = useMutation({
    onError: (error: AxiosError<{ message: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: createAndRedirectToToPayment.mutate,
        show: show,
        cb: () => {
          setInitialState();
        }
      };
      extractErrorMessage(errorData);
    },
    onSuccess: (res) => {
      const { sessionUrl } = res;
      if (requestedEmail) {
        setGlobalLoading(false);
        setShowSuccess(true);
      } else {
        window.location.href = sessionUrl;
      }
    },
    mutationFn: async (uuid: string) => await requests.get(`/api/public/checkout/create-session/${uuid}`),
  });

  const signOrDownload =
      useMutation({
        onError: (error: AxiosError<{ message: string }>, variables) => {
          const errorData = {
            error: error,
            variables: variables,
            retryFn: signOrDownload.mutate,
            show: show,
            cb: () => {
              setInitialState();
            }
          };
          extractErrorMessage(errorData);
        },
        onSuccess: (res: { fileUUID: string }) => {
          const { fileUUID } = res;
          createAndRedirectToToPayment.mutate(fileUUID);
        },
        mutationFn: TemplatesService.signOrDownload.queryFn,
      });

  const getEmbeddedPaymentUrl = useMutation({
    onError: (error: AxiosError<{ message: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: getEmbeddedPaymentUrl.mutate,
        show: show,
        cb: () => {
          setInitialState();
        }
      };
      extractErrorMessage(errorData);
    },
    onSuccess: (res) => {
      setGlobalLoading(false);
      const secret = res?.embeddedData?.clientSecret;
      if (secret) {
        setClientSecret(secret);
      }
    },
    mutationFn: async (uuid: string) => await requests.get(`/api/checkout/${companyId}/create-session/${uuid}`),
  });

  const signEmbedded = useMutation({
    mutationFn: async (params: {
      replacements: Record<string, string>;
      templateId: string;
    }) => {
      return await requests.post(`/api/companies/${companyId}/file83b/generate-pdf`, params);
    },
    onSuccess: (res: { embeddedSigningUrl: string; fileUUID: string }) => {
      const { embeddedSigningUrl, fileUUID } = res;
      if (paymentEnabled) {
        getEmbeddedPaymentUrl.mutate(fileUUID);
      } else {
        showFrame(embeddedSigningUrl);
        setGlobalLoading(false);
      }
    },
    onError: (error: AxiosError<{ message: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: signEmbedded.mutate,
        show: show,
        cb: () => {
          setInitialState();
        }
      };
      extractErrorMessage(errorData);
    },
  });

  const signEmbeddedForExistingFile = useMutation({
    mutationFn: async (params: {
      replacements: Record<string, string>;
      templateId: string;
    }) => {
      return await requests.post(`/api/companies/${companyId}/file83b/${fileId}/generate-pdf`, params);
    },
    onSuccess: (res: { embeddedSigningUrl: string; fileUUID: string }) => {
      const { embeddedSigningUrl, fileUUID } = res;
      if (embeddedSigningUrl || !paymentEnabled) {
        showFrame(embeddedSigningUrl);
        setGlobalLoading(false);
      } else {
        getEmbeddedPaymentUrl.mutate(fileUUID);
      }
    },
    onError: (error: AxiosError<{ message: string }>, variables) => {
      const errorData = {
        error: error,
        variables: variables,
        retryFn: signEmbedded.mutate,
        show: show,
        cb: () => {
          setInitialState();
        }
      };
      extractErrorMessage(errorData);
    },
  });

  const generatePdfLog =
      useMutation({
        onError: (error: AxiosError<{ message: string }>, variables) => {
          const errorData = {
            error: error,
            variables: variables,
            retryFn: signEmbedded.mutate,
            show: show,
            logErrorMessage: true,
          };
          extractErrorMessage(errorData);
        },
        onSuccess: () => {},
        mutationFn: TemplatesService.generatePdfLog.queryFn,
      });

  const requiredFieldsConfiguration = data?.configs || [];

  const configuredFieldsConfiguration = useMemo(() => {
    const autoFillFields: TDataItem[] = [];
    const tempManualFields: TDataItem[] = [];
    let defaultValuesFromConfig: Record<string, string | number | undefined | Date> = {};
    const manualFields: TDataItem[] = [];
    const addressGroupFields: Record<string, TDataItem[]> = {};
    (requiredFieldsConfiguration || []).forEach((item: TDataItem) => {
      if (item.action) {
        autoFillFields.push(item);
      } else {
        if (item.configGroup) {
          if (
            Object.prototype.hasOwnProperty.call(
              addressGroupFields,
              item.configGroup,
            )
          ) {
            addressGroupFields[item.configGroup].push(item);
          } else {
            addressGroupFields[item.configGroup] = [item];
          }
        }
        tempManualFields.push(item);
        defaultValuesFromConfig[item.configName] = item.defaultValue
          ? item.defaultValue
          : item.configType === 'STRING'
          ? ''
          : undefined;
      }
    });
    const copyOfAddressGroupFields: Record<string, TDataItem[]> = JSON.parse(
      JSON.stringify(addressGroupFields),
    );
    (tempManualFields || []).forEach((item: TDataItem) => {
      if (
        Object.prototype.hasOwnProperty.call(
          copyOfAddressGroupFields,
          item.configGroup as string,
        )
      ) {
        if (copyOfAddressGroupFields[item.configGroup as string].length > 0) {
          manualFields.push(item);
          copyOfAddressGroupFields[item.configGroup as string].length = 0;
        }
      } else {
        manualFields.push(item);
      }
    });
    defaultValuesFromConfig.firstRecipientEmail = email;
    defaultValuesFromConfig.taxpayer_name = fullName;
    if (file83bUpdate) {
      defaultValuesFromConfig = {
        ...defaultValuesFromConfig,
        ...preFilledFormValues,
        ...(preFilledFormValues?.date_of_transfer ? { date_of_transfer: new Date(preFilledFormValues.date_of_transfer) } : {})
      }
    }
    return {
      autoFillFields,
      manualFields,
      defaultValuesFromConfig,
      addressGroupFields,
    };
  }, [requiredFieldsConfiguration, preFilledFormValues]);

  const {
    autoFillFields,
    manualFields,
    defaultValuesFromConfig,
    addressGroupFields,
  } = configuredFieldsConfiguration;
  const valuesToFormat = useMemo(() => {
    const result: Record<string, string> = {};
    (requiredFieldsConfiguration || []).forEach((item: TDataItem) => {
      const { configName, configType } = item;
      if (configType === 'DATE') {
        result[configName] = configType;
      }
    });
    return result;
  }, [requiredFieldsConfiguration]);

  const isLoading = requiredFieldsLoading || docAsHtmlLoading || Boolean(file83bUpdate && preFilledFormValuesLoading);
  const autofillFormValues = useMemo(() => {
    const autoFillFormValues: Record<string, string> = {};
    (autoFillFields || []).forEach((item: TDataItem) => {
      const { action, configName } = item;
      const val = calculateFormula(action as string, formValues, isEveryCompany);
      if (val !== '') {
        autoFillFormValues[configName] = String(val);
      }
    });
    return autoFillFormValues;
  }, [autoFillFields, formValues, isEveryCompany]);

  const formattedFormValues = useMemo(() => {
    const result: Record<string, string> = {};
    const combinedFieldsValues: Record<
      string,
      string | Date | number | undefined
    > = {
      ...formValues,
      ...autofillFormValues,
    };
    Object.keys(combinedFieldsValues).forEach((key) => {
      const fieldConfig = (requiredFieldsConfiguration || []).find(
        (config: TDataItem) => key === config.configName,
      );
      if (fieldConfig?.configType === 'DATE' && combinedFieldsValues[key]) {
        result[key] = formatDate(combinedFieldsValues[key] as Date);
        return;
      }
      if (
        fieldConfig?.configType === 'NUMERIC' &&
        typeof combinedFieldsValues[key] === 'number'
      ) {
        result[key] = formatNumberWithCommas(
          combinedFieldsValues[key] as number,
        );
        return;
      }
      if (
        fieldConfig?.configType === 'DECIMAL' &&
        typeof combinedFieldsValues[key] === 'number'
      ) {
        result[key] = (combinedFieldsValues[key] as number).toLocaleString(
          'en-US',
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 15,
          },
        );
        return;
      }
      if (fieldConfig?.configType === 'SSN' && combinedFieldsValues[key]) {
        const ssnNumberCodeLength = 9;
        const ssnStrValue = combinedFieldsValues[key];
        const numberedValue = Number(ssnStrValue);
        if (
          !isNaN(numberedValue) &&
          (ssnStrValue as string).length <= ssnNumberCodeLength
        ) {
          result[key] = formatSSN(ssnStrValue as string);
          return;
        }
        result[key] = ssnStrValue as string;
        return;
      }
      if (fieldConfig?.configName === 'state') {
        if (combinedFieldsValues[key]) {
          const preFix = `, `;
          result[key] = `${preFix}${combinedFieldsValues[key]}`;
          return;
        } else {
          result[key] = '';
          return;
        }
      }

      if (
        fieldConfig?.configName === 'address_line2' &&
        !combinedFieldsValues[key]
      ) {
        result[key] = step === stepsKeys.filling ? '' : ' ';
        return;
      }
      const formattedFieldNames = {
        FMV: true,
        Total_Paid: true,
        Income: true,
      };
      if (
        Object.prototype.hasOwnProperty.call(
          formattedFieldNames,
          fieldConfig?.configName as string,
        )
      ) {
        const numberedVal = Number(combinedFieldsValues[key]);
        if (!isNaN(numberedVal)) {
          result[key] = (numberedVal as number).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 15,
          });
          return;
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = combinedFieldsValues[key];
    });
    return result;
  }, [formValues, autofillFormValues, requiredFieldsConfiguration, step]);
  const differenceVal = formattedFormValues?.Income;

  const logAfterNextActionSucceed = (isEmailCapture?: boolean) => {
    const params = {
      replacements: {
        ...formattedFormValues,
        firstRecipientFullName: formattedFormValues.purchaser_name,
        ...(isSupposeExists
          ? { secondRecipientFullName: formattedFormValues.spouse_name }
          : {}),
        ...(isEmailCapture ? { emailCapture: 'true' } : {}),
      },
      templateId: template,
    };
    generatePdfLog.mutate(params);
  };

  const captureFirstRecipientEmailAfterEmailFieldOnBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const capturingEmail = formattedFormValues?.firstRecipientEmail;
    const isEmailValid = emailRegex.test(capturingEmail);
    if (
      capturingEmail &&
      isEmailValid &&
      !capturedEmailList.find((cptEm) => cptEm === capturingEmail)
    ) {
      setCapturedEmailList([...capturedEmailList, capturingEmail]);
      logAfterNextActionSucceed(true);
    }
  };

  const onSubmit = (
    values: Record<string, string | number | Date | undefined>,
  ) => {
    if (active === 1) {
      setFormStep((prev) => ({...prev, active: 2}));
      return;
    }
    const differenceValue = formattedFormValues?.Income;
    const isMinus = differenceValue ? differenceValue[0] === '-' : false;
    if (isMinus) {
      setNegativeValueWarning(true);
      return;
    }
    if (isPublic) {
      logAfterNextActionSucceed();
    }
    setStep(stepsKeys.review);
    setFormStep((prev) => ({ ...prev, active: 1 }));
    Object.keys(values).forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(valuesToFormat, item)) {
        if (valuesToFormat[item] === 'DATE') {
          const currentDate = values[item];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          values[item] = formatDate(currentDate);
        } else {
          if (typeof values[item] === 'number') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            values[item] = (values[item] || '').toString;
          }
        }
      }
    });
  };

  const formValuesMergedDoc = useMemo(() => {
    let isFormEmpty = true;
    let mergedDoc = '';
    Object.keys(formattedFormValues).forEach((key) => {
      const fieldValue = formattedFormValues[key];
      const regex = new RegExp(`{{${key}}}`, 'g');
      const fieldConfig = (requiredFieldsConfiguration || []).find(
        (config: TDataItem) => config.configName === key,
      );
      const titleStr = `title="${fieldConfig?.label}"`;
      const fillingSpanHolder = `<span style="color: #000000; background-color: rgba(0, 0, 0, .2)" ${titleStr}>${fieldValue}</span>`;
      const spanHolder = `<span style="color: #000000;">${fieldValue}</span>`;
      const fieldValueInsideAnchorSpan =
        step === stepsKeys.filling ? fillingSpanHolder : spanHolder;
      if (fieldValue || (step === stepsKeys.review && key === 'state')) {
        if (!(step === stepsKeys.review && key === 'state')) {
          isFormEmpty = false;
        }
        if (!mergedDoc) {
          mergedDoc = (docAsHtml || '').replace(
            regex,
            fieldValueInsideAnchorSpan,
          );
        } else {
          mergedDoc = mergedDoc.replace(regex, fieldValueInsideAnchorSpan);
        }
      }
    });
    return isFormEmpty ? docAsHtml : mergedDoc;
  }, [docAsHtml, step, requiredFieldsConfiguration, formattedFormValues]);

  const signAndSubmit = (email?: string) => {
    if (email) {
      setRequestedWithEmail(email);
    } else {
      setStep(stepsKeys.signature);
    }
    setGlobalLoading(true);
    const params = {
      replacements: {
        ...formattedFormValues,
        firstRecipientFullName: formattedFormValues.purchaser_name,
        ...(isSupposeExists
          ? { secondRecipientFullName: formattedFormValues.spouse_name }
          : {}),
      },
      templateId: template,
      deliveryService: transportType,
      ...(email
          ? {
            isSendEmail: true,
            email,
          }
          : {}),

    };
    if (isPublic) {
      if (file83bUpdate) {
        // @ts-ignore
        params.replacements.file83bId = preFilledFormValues.id;
      }
      signOrDownload.mutate(params);
    } else {

      if (file83bUpdate) {
        signEmbeddedForExistingFile.mutate(params);
      } else {
        signEmbedded.mutate(params);
      }
    }
  };

  const closeSuccessPopup = () => {
    setShowSuccess(false);
    setRequestedWithEmail('');
  };

  useEffect(() => {
    if (negativeValueWarning && (differenceVal || '')[0] !== '-') {
      setNegativeValueWarning(false);
    }
  }, [differenceVal, negativeValueWarning]);

  useEffect(() => {
    if (embeddedSigningUrl) {
      showFrame(embeddedSigningUrl);
    }
  }, [embeddedSigningUrl]);

  useEffect(() => {
    if (Object.keys(defaultValuesFromConfig).length > 0) {
      reset(defaultValuesFromConfig);
    }
  }, [JSON.stringify(defaultValuesFromConfig)]);

  useEffect(
      () => {
        setFormStep((prev) => ({ ...prev, active: 1 }));
        setInitialState();

      }, [companyId]
  );


  if (signAfterEmbeddedPaymentOrderNumber) {
    return (
        <div className={styles.tool83b}>
          <div className={styles.docGeneration}>
            <StepsControl
                step={stepsKeys.finish}
                isPublic={isPublic}
                isFLowCompleted={showSubmissionSuccessModal}
                afterEmbeddedPay
            />
            {
                embeddedSignatureDataLoading && (
                    <div className={styles.loadingHolderForEmbeddedUrl}>
                      <Loading/>
                    </div>
                )
            }
            <SubmissionCreatedModal
                isSupposeExists={false}
                showSubmissionSuccessModal={showSubmissionSuccessModal}
            />
          </div>
        </div>
    );
  }

  return (
      <div className={styles.tool83b}>
        {
            clientSecret && (
                <EmbeddedPayment clientSecret={clientSecret} />
            )
        }
        <div className={styles.docGeneration}>
          <div className={styles.generationBody}>
            <StepsControl
                step={step}
                isPublic={isPublic}
                paymentEnabled={paymentEnabled}
                isPublicOpenAi={isPublicOpenAi}
            />
            <div className={styles.docContainer}>
              {
                step === stepsKeys.filling && (
                  <div className={styles.formWidget}>
                    {isLoading ? (
                        <div className={styles.loadingHolder}>
                          <Loading/>
                        </div>
                    ) : (
                        <Form
                            fieldsConfig={manualFields}
                            onSubmit={onSubmit}
                            addressGroupFields={addressGroupFields}
                            negativeValueWarning={negativeValueWarning}
                            isSupposeExists={isSupposeExists}
                            setSuppose={setSuppose}
                            captureFirstRecipientEmailAfterEmailFieldOnBlur={
                              captureFirstRecipientEmailAfterEmailFieldOnBlur
                            }
                            form={form} 
                            active={active}
                            total={total}
                            setFormStep={setFormStep}
                            isPublic={isPublic}
                            showSelectTemplate={Boolean(showSelectTemplate)}
                            disableFiling={disableFiling}
                            isPublicDraft={isPublicDraft}
                        />
                    )}
                  </div>
              )}
              {step === stepsKeys.review && (
                  <ReviewStep
                      fieldsConfig={requiredFieldsConfiguration}
                      data={formattedFormValues}
                      setStep={setStep}
                  />
              )}
              {step === stepsKeys.terms && (
                  <TermsStep
                      setStep={setStep}
                      isTermsAccepted={isTermsAccepted}
                      setTermsState={setTermsState}
                      signAndSubmit={signAndSubmit}
                      isPublic={isPublic}
                      isDeliveryNext={Boolean(showDeliveryService)}
                      paymentEnabled={(Boolean(paymentEnabled))}
                  />
              )}
              {(step === stepsKeys.finish || step === stepsKeys.delivery) && (
                  <FinishStep
                      isPublic={Boolean(isPublic)}
                      setStep={setStep}
                      signAndSubmit={signAndSubmit}
                      price={price}
                      uspsPrice={uspsPrice}
                      transportType={transportType}
                      setTransportType={setTransportType}
                      isPaidSelected={isPaidSelected}
                      selectedService={selectedService}
                      selectService={selectService}
                      isPublicOpenAi={isPublicOpenAi}
                  />
              )}
              {(step === stepsKeys.filling || step === stepsKeys.review) && (
                  <FileView
                      isLoading={isLoading}
                      formValuesMergedDoc={formValuesMergedDoc}
                  />
              )}
            </div>
          </div>
          <ProcessingLoading
              requestedEmail={requestedEmail}
              isGlobalLoading={isGlobalLoading}
              isPublic={isPublic}
              paymentEnabled={Boolean(paymentEnabled)}
          />
          <SubmissionCreatedModal
              isSupposeExists={isSupposeExists}
              showSubmissionSuccessModal={showSubmissionSuccessModal}
          />
          <ConfirmDialog
              visible={showSuccessPopup}
              onHide={closeSuccessPopup}
              message={`You will receive an email at ${requestedEmail} with your 83(b) election form attached.`}
              header="Success"
              icon="pi pi-check"
              acceptClassName="p-button-danger"
              footer={() => <Button label="OK" onClick={closeSuccessPopup} />}
          />
        </div>
      </div>
  );
};

export default DocumentGeneration;
