import React, { FC } from 'react';
import { useMutation } from '@tanstack/react-query';
import {requests} from "../../../services/http-common";
import { useToast } from '../../../hooks/useToast';
import styles from './createUserModal.module.scss';
import CloseButton from "../../../uiComponents/CloseButton/CloseButton";
import ButtonPrimary from "../../../uiComponents/ButtonPrimary/ButtonPrimary";
import { AxiosError } from 'axios';
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FieldWrapper from "../../../uiComponents/FieldWrapper/FieldWrapper";
import { Dropdown } from 'primereact/dropdown';
import {InputText} from "primereact/inputtext";
import {extractErrorMessage} from "../../../helpers/extractErrorMessage";
import {classNames} from "primereact/utils";

type TFieldName = 'email' | 'name' | 'role';
type TFieldType = 'text' | 'select';

const fieldTypes: Record<TFieldType, TFieldType> = {
    text: 'text',
    select: 'select',
}

type TFieldConfig = {
    fieldName: TFieldName;
    label: string;
    placeholder: string;
    fieldType: TFieldType;
};

type TCreateUserValues = Record<TFieldName, string>;

const fieldsConfig: TFieldConfig[] = [
    {
        fieldName: 'email',
        label: 'Email',
        placeholder: 'example@gmail.com',
        fieldType: fieldTypes.text,
    },
    {
        fieldName: 'role',
        label: 'Role',
        placeholder: 'Select role',
        fieldType: fieldTypes.select,
    },
];

const roleOptions = [
    {
        value: 'ADMIN',
        label: 'Admin',
    },
    {
        value: 'EMPLOYEE',
        label: 'Employee',
    }
];

const initialFormValues = {
    email: '',
    name: '',
    role: '',
};

interface ICreateUserModalProps {
    closeModal: () => void;
    reFetchUsers: () => void;
    companyId: number;
}
const CreateUserModal: FC<ICreateUserModalProps> = ({ closeModal, reFetchUsers, companyId }) => {
    const { show } = useToast();
    const formSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        role: Yup.string().required('Field is required'),
    });
    const form = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: initialFormValues,
    });
    const { control, handleSubmit } = form;
    const formErrors = form.formState.errors;

    const createUser = useMutation({
        mutationFn: async (params: TCreateUserValues) => {
            return await requests.post(
                `api/companies/${companyId}/users`,
                params,
            );
        },
        onSuccess: () => {
            reFetchUsers();
            closeModal();
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: createUser.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });

    const onSubmit = (values: TCreateUserValues) => {
        createUser.mutate(values);
    }

    return (
        <div className={styles.modalOverlay}>
            <form className={styles.modal} onSubmit={handleSubmit(onSubmit)}>
                <CloseButton onClick={closeModal} className={styles.btn} />
                <div className={styles.modalBody}>
                    <div className={styles.formLabel}>Add User</div>
                    <div className={styles.fields}>
                        {fieldsConfig.map((fieldConfig: TFieldConfig) => {
                            const { fieldName, placeholder, label, fieldType } = fieldConfig;
                            const fieldError = formErrors[fieldName]?.message;
                            if (fieldType === fieldTypes.text) {
                                return (
                                    <div className={classNames(styles.fieldItem, {
                                        [styles.fullWidth]: fieldName === 'name',
                                    })} key={fieldName}>
                                        <Controller
                                            name={fieldName as TFieldName}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <FieldWrapper
                                                        className={styles.fieldWide}
                                                        label={label}
                                                        errorMessage={fieldError}
                                                        required
                                                    >
                                                        <InputText
                                                            className={styles.fieldWide}
                                                            value={field.value as string}
                                                            name={fieldName}
                                                            placeholder={placeholder}
                                                            autoComplete="new-password"
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                            }}
                                                        />
                                                    </FieldWrapper>
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (fieldType === fieldTypes.select) {
                                return (
                                    <div className={styles.fieldItem} key={fieldName}>
                                        <Controller
                                            name={fieldName as TFieldName}
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <FieldWrapper
                                                        className={styles.fieldWide}
                                                        label={label}
                                                        errorMessage={fieldError}
                                                        required
                                                    >
                                                        <Dropdown
                                                            placeholder={placeholder}
                                                            id={field.name}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                const newValue = e.value;
                                                                field.onChange(newValue);
                                                            }}
                                                            options={roleOptions}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </FieldWrapper>
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <ButtonPrimary label="Invite" type="submit" />
                </div>
            </form>
        </div>
    )
}

export default CreateUserModal;
