import React, {memo, ReactElement, useCallback, useState} from "react";
import {uuid} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";

import FilePreviewOverlay from "../../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import {HttpService} from "../../../../services/HttpService";
import {AxiosError} from "axios";

type Props = {
    toastRef:Function
    document: any
}

const statusKeys = {
    DRAFT:"Draft",
    PENDING: "pending",
    PARTIALLY_SIGNED: "Partially Signed",
    FULLY_SIGNED: "Fully Signed",
    PROCESSING: "Processing",
    SHIPPED: "Shipped",
    DELIVERED: "Delivered",
    ACKNOWLEDGED: "Acknowledged",
    REJECTED: "Rejected",
    COMPLETED: "Completed",
    OVERDUE: "Overdue",
};

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;
function StatusList(props: Props): ReactElement {

    const {document, toastRef} = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const [documentUrl, setDocumentUrl] = useState<string>('');

    const handleViewDocumentByStatus = useCallback(async (status: string) => {
            setIsLoading(true);
            const formId = document.id;

            try {
                const {data} = await HttpService.get(
                    `/api/admin/form2553/${formId}/download-url/${status}`,
                    {baseURL}
                );
                setShowPreview(true)
                setDocumentUrl(data.url);
                setIsLoading(false);
                // @ts-ignore
            } catch (error: AxiosError) {
                setIsLoading(false);
                setShowPreview(false)
                toastRef({
                    severity: 'error',
                    detail: error?.response.data.detail,
                });
            }
        },
        [document]);
    return (
        <>
            <ul className="list-none w-full">
                {
                    Object.entries(statusKeys).map(([key, value]) => {

                        // @ts-ignore
                        let label: string = value;
                        const hasValue: boolean = document.statuses[key] && document.statuses[key] !== null;
                        return (
                            <li key={uuid('status_')} className="w-9 flex justify-content-between align-items-center">
                                <div className="font-semibold text-base flex justify-content-between align-items-end">
                                    <i className={classNames("pi mr-2", {
                                        "pi-circle": !hasValue,
                                        "pi-circle-fill": hasValue,
                                        "text-green-200": hasValue
                                    })}/>
                                    <span className={classNames({"text-green-200": hasValue})}>
                                    {label}
                                </span>
                                </div>
                                {
                                    hasValue ? (
                                        <Button text
                                                rounded
                                                size="small"
                                                icon="pi pi-eye"
                                                onClick={() => handleViewDocumentByStatus(key)}
                                                className="p-1 w-2rem h-1rem"/>
                                    ) : null
                                }
                            </li>
                        )
                    })
                }
            </ul>
            {
                showPreview && (
                    <FilePreviewOverlay
                        isLoading={isLoading}
                        preview={documentUrl}
                        closePreview={() => {
                            setIsLoading(false);
                            setShowPreview(false)
                            setDocumentUrl('')
                        }}
                    />
                )
            }

        </>
    )
}

export default memo(StatusList)