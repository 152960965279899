import React, {FC, useCallback, useEffect} from "react";
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import RadioCheckboxLabel from "../../../../../uiComponents/RadioCheckboxLabel/RadioCheckboxLabel";
import {TBusinessInformationFormValues} from "./types";
import styles from '../shared.module.scss';
import {InputMask} from "primereact/inputmask";
import {Dropdown} from "primereact/dropdown";
import {HttpService} from "../../../../../services/HttpService";

const schema = Yup.object().shape({
    entityName: Yup.string().required("Entity Name is required"),
    ein: Yup.string()
        .matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX')
        .required('EIN is required'),
    address: Yup.object().shape({
        country: Yup.string().required("Country is required"),
        line1: Yup.string().required("Address Line 1 is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string().required("Zip Code is required"),
    }),
    incorporationDate: Yup.date().required("Incorporation Date is required"),
    stateOfIncorporation: Yup.string().required("State of Incorporation is required"),
    electionEffectiveDate: Yup.date().required("Election Effective Date is required"),
    nameCheckbox: Yup.boolean(),
    addressCheckbox: Yup.boolean(),
    moreThan100ShareholdersCheckbox: Yup.boolean(),

});

interface TBusinessInformationProps {
    onSubmit: SubmitHandler<TBusinessInformationFormValues>;
    initialValues: TBusinessInformationFormValues;
    onInputChange: (name:string,value:string) => void;
}
const BusinessInformationForm: FC<TBusinessInformationProps> = ({
onSubmit,
initialValues,
onInputChange
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register
    } = useForm<TBusinessInformationFormValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });


    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [countryStates, setCountryStates] = React.useState([]);
    const getCountriesANdStates = useCallback(async () => {
        const states = await HttpService.get('/api/ref/state').then(res => res.data);
        const countries = await HttpService.get('/api/ref/country').then(res => res.data);
        return {countries, states};
    },[]);
    useEffect(() => {
        getCountriesANdStates().then(data => {
            setCountryStates(data.states);
            setIsDataLoaded(true);
        });
    }, []);
    const onChangeHandler = (e:any) => {
        onInputChange(e.target.name, e.target.value);
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.fieldsHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <LabelValue label="Name" required={true} fieldName="entityName" comment="">
                            <Controller
                                name="entityName"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        // onChange={handleSubmit(onChangeHandler)}
                                        className={errors.entityName ? "p-invalid" : ""}
                                        placeholder="Enter company name"
                                    />
                                )}
                            />
                            {errors.entityName && <small className="p-error">{errors.entityName.message}</small>}
                        </LabelValue>
                        <LabelValue label="Employer Identification Number" required={true} fieldName="ein" comment="">
                            <Controller
                                name="ein"
                                control={control}
                                render={({field}) => (
                                    <InputMask
                                        {...field}
                                        style={{width: '100%'}}
                                        // onChange={onChangeHandler}
                                        className={errors.ein ? "p-invalid" : ""}
                                        placeholder="99-9999999"
                                        mask="99-9999999"
                                    />
                                )}
                            />
                            {errors.ein && <small className="p-error">{errors.ein.message}</small>}
                        </LabelValue>
                        <LabelValue label="Country" required={true} fieldName="address.country" comment="">
                            <Controller
                                name="address.country"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        disabled
                                        style={{width: '100%'}}
                                        onChange={onChangeHandler}
                                        className={errors.address?.country ? "p-invalid" : ""}
                                        placeholder="Enter country"
                                    />
                                )}
                            />
                            {errors.address?.country &&
                                <small className="p-error">{errors.address.country.message}</small>}
                        </LabelValue>

                        <LabelValue
                            label="Address"
                            required={true}
                            fieldName="address.line1"
                            comment=""
                        >
                            <Controller
                                name="address.line1"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        // onChange={onChangeHandler}
                                        className={errors.address?.line1 ? "p-invalid" : ""}
                                        placeholder="Enter address"
                                    />
                                )}
                            />
                            {errors.address?.line1 && <small className="p-error">{errors.address.line1.message}</small>}
                        </LabelValue>
                        <div className={styles.addressLineGrid}>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="City"
                                    required={true}
                                    fieldName="address.city"
                                    comment=""
                                >
                                    <Controller
                                        name="address.city"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                placeholder="Enter city"
                                                // onChange={onChangeHandler}
                                                className={errors.address?.city ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.city && <small className="p-error">{errors.address.city.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="State"
                                    required={true}
                                    fieldName="address.state"
                                    comment=""
                                >
                                    <Controller
                                        name="address.state"
                                        control={control}
                                        render={({field}) => (
                                            <Dropdown
                                                {...field}
                                                filter
                                                style={{width: '100%'}}
                                                options={countryStates}
                                                disabled={!isDataLoaded}
                                                optionLabel="label"
                                                optionValue="code"
                                                placeholder="Select a State"
                                                className={errors.address?.state ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.state && <small className="p-error">{errors.address.state.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Zip Code"
                                    required={true}
                                    fieldName="address.zipCode"
                                    comment=""
                                >
                                    <Controller
                                        name="address.zipCode"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                // onChange={onChangeHandler}
                                                placeholder="Enter Zip Code"
                                                className={errors.address?.zipCode ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {errors.address?.zipCode &&
                                        <small className="p-error">{errors.address.zipCode.message}</small>}
                                </LabelValue>
                            </div>
                        </div>

                        <LabelValue
                            label="Date Incorporated"
                            required={true}
                            fieldName="incorporationDate"
                            comment=""
                        >
                            <Controller
                                name="incorporationDate"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="mm/dd/yy"
                                        showIcon
                                        // onChange={onChangeHandler}
                                        style={{width: '100%'}}
                                        className={errors.incorporationDate ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.incorporationDate &&
                                <small className="p-error">{errors.incorporationDate.message}</small>}
                        </LabelValue>
                        <LabelValue label="State of incorporation" comment='' required fieldName="stateOfIncorporation">
                            <Controller
                                name="stateOfIncorporation"
                                control={control}
                                render={({field}) => (
                                    <Dropdown
                                        {...field}
                                        filter
                                        style={{width: '100%'}}
                                        options={countryStates}
                                        // disabled={!isDataLoaded}
                                        optionLabel="label"
                                        optionValue="code"
                                        placeholder="Select a State"
                                        className={errors.address?.state ? "p-invalid" : ""}
                                    />
                                )}
                            />
                            {errors.stateOfIncorporation &&
                                <small className="p-error">{errors.stateOfIncorporation.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Election is to be effective for tax year beginning (month, day, year)"
                            required={true}
                            fieldName="electionEffectiveDate"
                            comment=""
                        >
                            <Controller
                                name="electionEffectiveDate"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="mm/dd/yy"
                                        showIcon
                                        style={{width: '100%'}}
                                        // onChange={onChangeHandler}
                                        className={errors.electionEffectiveDate ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.electionEffectiveDate &&
                                <small className="p-error">{errors.electionEffectiveDate.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtonHolder}>
                <ButtonPrimary
                    type="submit"
                    label="Next"
                />
            </div>
        </form>
    );
};

export default BusinessInformationForm;

