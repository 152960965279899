import React, {FC} from 'react';
import { classNames } from 'primereact/utils';
import styles from './stepsControl.module.scss';
import {
    publicOpenAIStepsConfig,
    publicStepsConfig,
    stepsConfig,
    stepsConfigWithEmbeddedSignature,
    stepsKeys
} from "../../types";

interface IStepsControl {
    step: string;
    isNewStyle?:boolean
    isPublic?: boolean;
    paymentEnabled?: boolean;
    afterEmbeddedPay?: boolean;
    isFLowCompleted?: boolean;
    isPublicOpenAi?: boolean;
}

const StepsControl:FC<IStepsControl> = ({
step,
isPublic,
isNewStyle = false,
paymentEnabled,
afterEmbeddedPay,
isFLowCompleted,
isPublicOpenAi,
}) => {

    let steps = isPublic ? (isPublicOpenAi ? publicOpenAIStepsConfig : publicStepsConfig) : (paymentEnabled || afterEmbeddedPay) ? stepsConfigWithEmbeddedSignature : stepsConfig;
    const currentStepIndex = steps.findIndex((item) => item.name === step);
    // if(isNewStyle) {
    //     steps = steps.filter(step => step.name !== stepsKeys.terms);
    // }

    return (
        <div className={styles.stepsControl}>
            {
                steps.map((item, index) => {
                    const { label, iconClassName, subLabel } = item;
                    const isCurrent = currentStepIndex === index;
                    const isCompleted = currentStepIndex > index || isFLowCompleted;
                    const progressFilled = currentStepIndex >= index - 1;
                    const progressGreen = currentStepIndex >= index;
                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <div className={styles.lineItem}>
                                    <span className={styles.track}>
                                        <span className={classNames(styles.bar, {
                                                [styles.barInProgress]: progressFilled,
                                                [styles.barCompleted]: progressGreen,
                                            })}
                                        />
                                    </span>
                                </div>
                            )}
                            <div className={classNames(styles.stepItem, {
                                [styles.current]: !isCompleted && isCurrent,
                                [styles.completed]: isCompleted,
                            })}>
                                <div className={styles.stepWidget}>
                                    <div className={styles.iconHolder}>
                                        <i className={isCompleted ? 'pi pi-check' : iconClassName} />
                                    </div>
                                </div>
                                <div className={styles.labels}>
                                    <p>{`STEP ${index + 1}`}</p>
                                    <p>{label}</p>
                                    <p>{subLabel}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

export default StepsControl;
