import { HttpService } from "../../services/HttpService";
import {TBusinessInformationRequestParams} from "./Form2553Creation/steps/BusinessInformation/types";
import {TLateElectionFormValues} from "./Form2553Creation/steps/LateElection/types";
import {TSignerInformationFormValues} from "./Form2553Creation/steps/SignerInformation/types";
import {InitFormParams} from "./types";
import {TAdditionalInformationParams, TShareholderInformationParams} from "./Form2553Creation/steps/Shareholders/types";
import {officerInfFormTypes} from "./Form2553Creation/steps/OfficerInfo/OfficerInfo";

const baseURL = `${import.meta.env.VITE_CORPORA_SERVICE_URL}`;

const postBusinessInformation = ({ params, companyId, formId }: { params: TBusinessInformationRequestParams, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/business-information`,
        params,
        { baseURL }
    );

const postLateElectionJustification = ({ params, companyId, formId }: { params: TLateElectionFormValues, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/late-election-justification`,
        params,
        { baseURL }
    );

const postSignerInformation = ({ params, companyId, formId }: { params: TSignerInformationFormValues, companyId: number, formId: string }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/signer-information`,
        params,
        { baseURL }
    );

const initForm2553 = ({ params, companyId }: { params: InitFormParams, companyId: number }) =>
    HttpService.post<string>(
        `/api/companies/${companyId}/form2553/init`,
        params,
        { baseURL }
    );

const getForm2553Files = (urlParams: string, companyId: number) => {
    return HttpService.get(`api/companies/${companyId}/form2553/search?${urlParams}`, {
        baseURL,
    });
}

const getForm2553Data = async (formId: string, companyId: number) => {
    const res = await HttpService.get(`api/companies/${companyId}/form2553/${formId}`, {
        baseURL,
    });

    return res.data;
}

const validateShareholderInformation = ({ formId, companyId }: { formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/shareholder-information/validate`,
        {
            companyId,
            formId
        },
        { baseURL }
    );
}

const postShareholderInformation = ({ params, formId, companyId }: { params: TShareholderInformationParams, formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/shareHolder`,
        params,
        { baseURL }
        );
}

const putShareholderInformation = ({ params, formId, companyId, shareholderId }: {
    params: TShareholderInformationParams,
    formId: string,
    companyId: number,
    shareholderId: number,
}) => {
    return HttpService.put<string>(
        `api/companies/${companyId}/form2553/${formId}/shareHolder/${shareholderId}`,
        params,
        { baseURL }
    );
}

const patchAdditionalInformation = ({ params, formId, companyId }: { params: TAdditionalInformationParams, formId: string, companyId: number }) => {
    return HttpService.patch<string>(
        `api/companies/${companyId}/form2553/${formId}/additional-info`,
        params,
        { baseURL }
    );
}

const postProcess = ({ formId, companyId }: { formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `api/companies/${companyId}/form2553/${formId}/process`,
        {},
        { baseURL }
    );
}

const deleteShareholder = ({ formId, companyId, id }: { formId: string, companyId: number, id: number }) =>
    HttpService.delete(`api/companies/${companyId}/form2553/${formId}/shareHolder/${id}`, { baseURL });

const mutateElection = (
        {formId, companyId, lateElectionQuestionEnabled}: {
            formId: string,
            companyId: number,
            lateElectionQuestionEnabled:boolean
        }) => {
        return HttpService.post(
            `api/companies/${companyId}/form2553/update-init/${formId}`,
            {
                formId,
                companyId,
                lateElectionQuestionEnabled
            }
        )
    }
const postOfficerInformation = (
    { params, companyId, formId }: {
        params:officerInfFormTypes,
        companyId: number,
        formId: string

}) => {
    return HttpService.post(
        `/api/companies/${companyId}/form2553/${formId}/officer-information`,
        {...params}
    );
}

const previewDocument = ({ formId, companyId }: { formId: string, companyId: number }) => {
    return HttpService.post<string>(
        `/api/companies/${companyId}/form2553/${formId}/process`,
        {formId, companyId},
    );
}

const downloadDocument = (
    { formId, companyId }: {
        formId: string,
        companyId: number
    }
) => {
    return HttpService.get(
        `/api/companies/${companyId}/form2553/${formId}/file/download/current-file`,
    ).then(res => res.data);
}

const getShareHolderDocumentUrl = (params:Record<string, any>) => {
    const {
        formId,
        companyId,
        shareholderId,
    } = params;

    const url = `/api/companies/${companyId}/form2553/${formId}/file/shareholder/${shareholderId}/upload/SIGNED`;

    return HttpService.get(url).then(res => {
        return {
            confirmUrl:url,
            ...res.data
        };
    });
}
const getOfficerDocumentUrl = (params:Record<string, any>) => {
    const {
        formId,
        companyId,
        signedDate,
        documentType,
    } = params

    const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER"

    const url =  `/api/companies/${companyId}/form2553/${formId}/file/upload/${isCoverLetter}/SIGNED`;
    return HttpService.get(url).then(res => {
        return {
            confirmUrl:url,
            ...res.data
        };
    });
}

const uploadDocument =  (url: string, formData: FormData, p0: { headers: { "Content-Type": any; }; })=> {
    return HttpService.post(url, formData).then(res => res.data);
}

const confirmUpload = (url:string) => {
    return HttpService.post(url).then(res => res.data);
}

const  loadAllDocuments = async (formId: string, companyId: number) => {
    const res = await HttpService.get(
        `/api/companies/${companyId}/form2553/status/${formId}`);
    return res.data;
}
const downloadShareHolderDocumentFromDashboard = async (params:Record<string, any>) => {

    const {
        formId,
        companyId,
        documentSignStatus,
        shareholderId,
        documentType,
    } = params
    const isSigned = documentSignStatus === "NONE" ? "NON_SIGNED" :"SIGNED";
    const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER";
    return HttpService.get(
        `/api/companies/${companyId}/form2553/${formId}/file/shareholder/${shareholderId}/${isSigned}`
    ).then(res => res.data);
}
const downloadShareHolderDocument = async (params:Record<string, any>) => {

    const {
        formId,
        companyId,
        signedDate,
        shareholderId,
        documentType,
    } = params
    const isSigned = signedDate === null ? "NON_SIGNED" :"SIGNED";
    const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER";
    return HttpService.get(
        `/api/companies/${companyId}/form2553/${formId}/file/shareholder/${shareholderId}/${isSigned}`
    ).then(res => res.data);
}

const downloadOfficerDocumentFromDashboard = async (params:Record<string, any>) => {
    const {
        formId,
        companyId,
        documentSignStatus,
        documentType,
    } = params
    const isSigned = documentSignStatus === "NONE" ? "NON_SIGNED" :"SIGNED";
    const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER"
    return HttpService.get(
        `/api/companies/${companyId}/form2553/${formId}/file/download/${isCoverLetter}/${isSigned}`
    ).then(res => res.data);
}

const downloadOfficerDocument = async (params:Record<string, any>) => {
    const {
        formId,
        companyId,
        signedDate,
        documentType,
    } = params
    const isSigned = signedDate !== null ? "SIGNED" :"NON_SIGNED"
    const isCoverLetter = documentType === "COVER_LETTER_DOCUMENT" ? "COVER_LETTER" : "OFFICER"
    return HttpService.get(
        `/api/companies/${companyId}/form2553/${formId}/file/download/${isCoverLetter}/${isSigned}`
    ).then(res => res.data);
}

const  getSignedInfo = async ( companyId: number) => {
    const res = await HttpService.get(
        `/api/companies/${companyId}/form2553/last-sign-info`);
    return res.data;
}

const getLastForm2553ByCompanyId = async (companyId: number) => {
    const res = await HttpService.get(
        `api/companies/${companyId}/form2553/last`);
    return res.data;
}

export const Form2553Service = {
    getSignedInfo,
    uploadDocument,
    getLastForm2553ByCompanyId,
    getOfficerDocumentUrl,
    downloadOfficerDocument,
    downloadShareHolderDocument,
    loadAllDocuments,
    confirmUpload,
    getShareHolderDocumentUrl,
    downloadDocument,
    previewDocument,
    postOfficerInformation,
    mutateElection,
    getForm2553Files,
    postBusinessInformation,
    postLateElectionJustification,
    postSignerInformation,
    initForm2553,
    getForm2553Data,
    validateShareholderInformation,
    postShareholderInformation,
    patchAdditionalInformation,
    postProcess,
    deleteShareholder,
    putShareholderInformation,
    downloadOfficerDocumentFromDashboard,
    downloadShareHolderDocumentFromDashboard
}