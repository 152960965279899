import React, {memo, ReactElement, useCallback, useState} from "react";
import {Button} from "primereact/button";
import {HttpService} from "../../../../services/HttpService";
import {AxiosError} from "axios";
import FilePreviewOverlay from "../../../../uiComponents/FilePreviewOverlay/FilePreviewOverlay";
import {downloadFile} from "../../../../pages/Form2553/helpers";

type Props = {
    toastRef:Function
    document: any
}

const baseURL = `${import.meta.env.VITE_CORPORA_ADMIN_URL}`;
function FedExLabel(props:Props):ReactElement {

    const {
        document,
        toastRef
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const [documentUrl, setDocumentUrl] = useState<string>('');

    const handleViewDocument = useCallback(async () => {
            setIsLoading(true);
            const formId = document.id;

            try {
                const {data} = await HttpService.get(
                    `/api/admin/form2553/${formId}/download-url/fedex-label`,
                    {baseURL}
                );
                setShowPreview(true)
                setDocumentUrl(data.url);
                setIsLoading(false);
                // @ts-ignore
            } catch (error: AxiosError) {
                setIsLoading(false);
                setShowPreview(false)
                toastRef({
                    severity: 'error',
                    detail: error?.response.data.detail,
                });
            }
        },
        [document]);

    const handleDownload = useCallback(async () => {
        const formId = document.id;
        try {
            const {data} = await HttpService.get(
                `/api/admin/form2553/${formId}/download-url/fedex-label`,
                {baseURL}
            );
            const {url, fileName} = data;
            downloadFile(url, fileName);
            // @ts-ignore
        } catch (error: AxiosError) {

            toastRef({
                severity: 'error',
                detail: error?.response.data.detail,
            });
        }
    }, [document])

    return (
        <div className="w-full">
            <Button text
                    rounded
                    icon="pi pi-file-pdf"
                    onClick={handleViewDocument}
                    disabled={!document.trackingNumber} />
            <Button text
                    rounded
                    icon="pi pi-download"
                    onClick={handleDownload}
                    disabled={!document.trackingNumber} />
            {
                showPreview && (
                    <FilePreviewOverlay
                        isLoading={isLoading}
                        preview={documentUrl}
                        closePreview={() => {
                            setIsLoading(false);
                            setShowPreview(false)
                            setDocumentUrl('')
                        }}
                    />
                )
            }
        </div>
    )
}

export default memo(FedExLabel)