import React, { useState, FC, useEffect } from 'react';
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import {Navigate, Route, Routes} from "react-router-dom";
import AdminDashboard from "../../pages/AdminDashboard/AdminDashboard";
import ManageUser from "../../pages/ManageUser/ManageUser";
import Subscriptions from "../../pages/Subscriptions/Subscriptions";
import SubscribeIntent from "../../pages/Subscriptions/SubscribeIntent";

import {DocumentsList} from "../../modules/Document/components/DocumentsList/DocumentsList";

import styles from "./layout.module.scss";
import {TPolicyVersion, TUserCompanyConfigItem} from "../../@types/global";
import BulkUploadUsers from "../../pages/BulkUploadAndDraftUsers/BulkUploadUsers/BulkUploadUsers";
import {classNames} from "primereact/utils";
import Acknowledgements from "../../pages/Acknowledgement/Acknowledgements";
import TermsAdminRoutes from "../../pages/TermsUpdate/TermsAdminRoutes";
import PolicyAcceptModal from "../PolicyAcceptModal/PolicyAcceptModal";
import {useMutation} from "@tanstack/react-query";
import {requests} from "../../services/http-common";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../helpers/extractErrorMessage";
import {useToast} from "../../hooks/useToast";
import Form2553Routes from "../../pages/Form2553/Form2553Routes";
import Dashboard from "../../pages/Form2553/pages/Dashboard/Dashboard";
import Details from "../../pages/Form2553/pages/Details/Details";
import Form2553Documents from "../../modules/Document/Form2553Documents";
import File83bRoutes from '../../pages/File83bDashboard/File83bRoutes';



interface TLayout {
    defaultCompany: TUserCompanyConfigItem;
    companies: TUserCompanyConfigItem[];
    email: string;
    userLogOut: () => void;
    userName: string;
    userId: number;
    logOutLoading: boolean;
    policyVersion: TPolicyVersion;
    refetchUserPerCompanyConfig: () => void;
}

const Layout:FC<TLayout> = ({
    defaultCompany,
    companies,
    email,
    userLogOut,
    userName,
    userId,
    logOutLoading,
    policyVersion,
    refetchUserPerCompanyConfig,
}) => {
    const [companyInfo, updateCompanyInfo] = useState(defaultCompany);
    const [acceptTermsModalOpened, setAcceptTermsModalOpened] = useState<boolean>(false);
    const { show } = useToast();
    const {
        companyId,
        companyName,
        file83bLimit,
        remainingCount,
        role,
        templateCode,
        templateCrypto,
        templateSpouseCode,
        companyCode,
        filingCreationEnabled,
        paymentEnabled,
        subscriptionMenuVisible,
        skipGrantHeaderRecognizing,
        details,
        formPricing:{
            FILE_83B:{
                deliveryService
            }
        },
        enabledFeatures: features,
    } = companyInfo;

    const isEveryCompany = companyCode === 'EVR';
    const isAdmin = role === "ADMIN";
    const hasSpouseFeature = Boolean(templateSpouseCode);
    const {
        currentPolicyVersion,
        currentTermsVersion,
        forceAccept,
        userPolicyVersion,
        userTermsVersion,
    } = policyVersion;
    const justLoggedIn = sessionStorage.getItem('login') === 'login';
    const privacyPolicyNotAccepted = currentPolicyVersion !== userPolicyVersion;
    const termsNotAccepted = currentTermsVersion !== userTermsVersion;
    const enabledFeatures = features || [];
    const isFile83bFeatureEnabled = Boolean(enabledFeatures.find((f) => f === "FILE_83B"));
    const isFillForm2553FeatureEnabled = Boolean(enabledFeatures.find((f) => f === "FILL_FORM_2553"));
    const isForm15620Enabled = Boolean(enabledFeatures.find((f) => f === "FILL_FORM_15620"));

    const acceptRequest = useMutation({
        mutationFn: async () => {
            return await requests.post(
                `api/term-policy/accept`,
                {}
            );
        },
        onSuccess: () => {
            refetchUserPerCompanyConfig();
            setAcceptTermsModalOpened(false);
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: acceptRequest.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const acceptRequestLoading = acceptRequest?.isPending;

    const onCloseModal = () => {
        setAcceptTermsModalOpened(false);
        sessionStorage.removeItem('login');
    }

    const onAccept = () => {
        acceptRequest.mutate();
    }

    useEffect(() => {

        if ((acceptTermsModalOpened || !termsNotAccepted ||  forceAccept) && justLoggedIn) {
            setAcceptTermsModalOpened(true);
        }
    }, [forceAccept]);

    return (
        <div className={classNames(styles.layout, {
            [styles.loading]: logOutLoading,
        })}>
            {
                acceptTermsModalOpened && (privacyPolicyNotAccepted || termsNotAccepted) && (
                    <PolicyAcceptModal
                        policyVersion={policyVersion}
                        privacyPolicyNotAccepted={privacyPolicyNotAccepted}
                        termsNotAccepted={termsNotAccepted}
                        onCloseModal={onCloseModal}
                        onAccept={onAccept}
                        loading={acceptRequestLoading}
                    />
                )
            }
            <Sidebar
                isAdmin={Boolean(isAdmin)}
                role={role}
                companyName={companyName}
                companies={companies}
                selectedCompanyId={companyId}
                updateCompanyInfo={updateCompanyInfo}
                companyCode={companyCode}
                isForm15620Enabled={isForm15620Enabled}
                subscriptionMenuVisible={subscriptionMenuVisible}
                isFile83bFeatureEnabled={isFile83bFeatureEnabled}
                isFillForm2553FeatureEnabled={isFillForm2553FeatureEnabled}
            />
            <Header userLogOut={userLogOut} fullName={userName} email={email}/>
            <main className={styles.content}>
                <Routes>
                    <Route
                        path="/filing/*"
                        element={
                            <File83bRoutes
                            deliveryService={deliveryService}
                                companyId={companyId}
                                templateCode={templateCode}
                                templateCrypto={templateCrypto}
                                templateSpouseCode={templateSpouseCode}
                                email={email}
                                fullName={userName}
                                userId={userId}
                                key={companyId}
                                companies={companies}
                                paymentEnabled={paymentEnabled}
                                filingCreationEnabled={filingCreationEnabled}
                                isEveryCompany={isEveryCompany}
                            />
                        }
                    />
                    <Route path="/admin/form-2553/*" element={<Form2553Documents companyId={companyId} />}/>
                    <Route path="/documents/*" element={<DocumentsList />}/>
                    <Route path="/acknowledgements/*" element={<Acknowledgements />} />
                    <Route path="/form2553/*" element={<Form2553Routes companyId={companyId} companies={companies} isAdmin={isAdmin} />} />
                    <Route path="/admin-terms/*" element={<TermsAdminRoutes />} />
                    <Route path="/form-2553-signature"  element={<Details companyId={companyId} />} />
                    {isAdmin ? (
                        <>
                            <Route
                                path="/admin-dashboard"
                                element={
                                    <AdminDashboard
                                        companyId={companyId}
                                        key={companyId}
                                        hasSpouseFeature={hasSpouseFeature}
                                        email={email}
                                    />
                                }
                            />
                            <Route
                                path="/manage-user"
                                element={<ManageUser companyId={companyId} key={companyId} />}
                            />
                            <Route
                                path="/grants"
                                element={<BulkUploadUsers companyId={companyId} key={companyId} skipGrantHeaderRecognizing={skipGrantHeaderRecognizing} />}
                            />

                            <Route
                                path="/subscriptions"
                                element={
                                    <Subscriptions
                                        file83bLimit={file83bLimit}
                                        remainingCount={remainingCount}
                                        key={companyId}
                                        companyId={companyId}
                                        details={details}
                                    />
                                }
                            />
                            <Route
                                path="/subscription-intent"
                                element={
                                    <SubscribeIntent companyId={companyId} />
                                }
                            />
                            <Route
                                path="/*"
                                element={<Navigate to="/admin-dashboard" replace/>}
                            />

                            <Route path="/form-2553-dashboard/:formId?"  element={<Dashboard companyId={companyId} />} />
                        </>
                    ) : (
                        <>
                            <Route path="/*" element={<Navigate to="/filing" replace/>}/>
                        </>
                    )}
                </Routes>
            </main>
        </div>
    )
}

export default Layout;
