import React, {memo, useEffect, useState} from "react";

import {HttpService} from "../../../../../services/HttpService";
import EmbeddedPayment from "../../../../../components/DocumentGeneration/components/EmbeddedPayment/EmbeddedPayment";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";

type Props = {
    uuid: string;
    companyId: number;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
}

function Payment(props: Props): React.ReactElement {
    const {
        uuid,
        setStep,
        companyId,
    } = props;

    const [showModal, setShowModal] = useState<boolean>(true);
    const [clientSecret, setClientSecret] = useState<string>();
    useEffect(() => {
        HttpService.get(
            `/api/checkout/${companyId}/create-session/${uuid}/type/FORM_2553`
        ).then(res => {
            const {clientSecret} = res.data.embeddedData;
            setClientSecret(clientSecret);
        });
    }, [companyId, uuid]);
    return (
        <div className="w-full">
            {
                (clientSecret && showModal) ? (
                    <EmbeddedPayment clientSecret={clientSecret} onClose={() => setStep(stepsKeys.TERMS_AND_PREVIEW)}/>
                ) : null
            }
        </div>
    )
}

export default memo(Payment);