
export enum TermsTypeKeys {
    TERMS_FOR_83B = 'TERMS_FOR_83B',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    TERMS_FORM_2553='TERMS_FORM_2553',
    TERMS_OF_SERVICE = 'TERMS_OF_SERVICE'
}

export const TermsKeysLabels = {
    [TermsTypeKeys.TERMS_FOR_83B]: 'Terms for 83b',
    [TermsTypeKeys.PRIVACY_POLICY]: 'Privacy Policy',
    [TermsTypeKeys.TERMS_OF_SERVICE]: 'Terms of Service',
    [TermsTypeKeys.TERMS_FORM_2553]: 'Terms of 2553',
}

export type TTermTypes = TermsTypeKeys.TERMS_FOR_83B
    | TermsTypeKeys.PRIVACY_POLICY
    | TermsTypeKeys.TERMS_OF_SERVICE
    | TermsTypeKeys.TERMS_FORM_2553;

export type TPolicyVersions = {
    currentPolicyVersion: string;
    currentTermsVersion: string;
    forceAccept: boolean;
    userPolicyVersion: string;
    userTermsVersion: string;
}