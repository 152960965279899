import React, { FC } from 'react';
import DropdownWrapper from "../../uiComponents/DropdownWrapper/DropdownWrapper";
import styles from './header.module.scss';
import {classNames} from "primereact/utils";
import AvatarFrame from "../../uiComponents/AvatarFrame/AvatarFrame";
import {useIsSuperAdmin} from "../../hooks/useIsSuperAdmin";
import {useNavigate} from 'react-router-dom';

interface TAccountProps {
    userLogOut: () => void;
    fullName: string;
    email: string;
}

const Account: FC<TAccountProps> = ({ userLogOut, fullName, email }) => {
    const navigate = useNavigate();
    const isSuperAdmin = useIsSuperAdmin();

    const options = [
        {
            value: 'logout',
            label: 'Log out',
        },
        ...(isSuperAdmin ? [
            {
                value: 'admin-form83b',
                label: 'Admin panel 83b',
            },
            {
                value: 'admin-form2253',
                label: 'Admin panel 2553',
            },
            {
                value: 'acknowledgements',
                label: 'Acknowledgements',
            },
            {
                value: 'terms-update',
                label: 'Terms and conditions update',
            }
        ] : [])
    ];
    const onSelect = (action: string) => {
        if (action === 'logout') {
            userLogOut();
        }
        if (action === 'acknowledgements') {
            navigate('/acknowledgements');
        }
        if (action === 'terms-update') {
            navigate('/admin-terms');
        }
        if (action === 'admin-form83b') {
            window.open('/documents', '_blank');
        }
        if (action === 'admin-form2253') {
            window.open('/admin/form-2553', '_blank');
        }
    }
    return (
        <div className={styles.account}>
            <AvatarFrame
                fullName={fullName}
                frameSize={40}
            />
            <div className={styles.info}>
                <p>{fullName}</p>
                <p>{email}</p>
            </div>
            <DropdownWrapper
                options={options}
                onSelect={onSelect}
                className={styles.dropdownCustom}
            >
                <i className={classNames('bi bi-chevron-down', styles.toggleBtn)}></i>
            </DropdownWrapper>
        </div>
    )
}

export default Account;
