import React, {
    useCallback,
    useEffect,
    useRef
} from "react";
import {useNavigate} from "react-router-dom";

import {
    Accordion,
    AccordionTab
} from "primereact/accordion";
import {Button} from "primereact/button";
import { Toast } from "primereact/toast";
import {Dialog} from "primereact/dialog";

import {TForm2553Information} from "../../CreationControl/types";
import {stepsKeys, TStep} from "../../../../../uiComponents/StepsControl/StepsControl";
import {HttpService} from "../../../../../services/HttpService";
import applyConfirmIcon from "../../../../../assets/images/apply-confirm-modal-icon.png";

import styles from './Publish.module.scss';

type pageProps = {
    companyId: number;
    formId: string;
    companies:any[];
    isAdmin: boolean;
    form2553Information: TForm2553Information;
    setStep: React.Dispatch<React.SetStateAction<TStep>>;
}
function Publish(props:pageProps) {
    const {
        form2553Information,
        companyId,
        companies,
        isAdmin,
        formId
    } = props;

    const {
        businessInformation,
        shareHolders,
    } = form2553Information;
    const toast = useRef<Toast | null>(null)
    const [showPaymentModal, setShowPaymentModal] = React.useState(false);

    const [isCharged] = React.useState(() => {
        let charged = false
        companies.forEach(companie => {
            if (companie.companyId === companyId) {
                charged = companie.formPricing.FILE_83B.allowedLimit > 0
            }
        })
        return charged;
    });

    const navigate = useNavigate();

    const applyDocument = useCallback(() => {
        return HttpService.post(
            `/api/companies/${companyId}/form2553/${formId}/apply`,
            {
                formId,
                companyId,
            }
        ).then((res) => {
            return res;
        });
    },[formId, companyId]);

    const navigateToPaymentPage = useCallback(() => {
            navigate("/subscriptions")
    },[navigate]);

    useEffect(() => {
        applyDocument().then(res => {
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Document successfully saved"
            });
            setTimeout(() => {
                if (isAdmin) {
                    navigate("/form-2553-dashboard", {replace: true});
                } else {
                    navigate("/form-2553-signature", {replace: true});
                }

            }, 500);

        }).catch(reason => {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong"
            });
        });

    }, [isCharged, applyDocument, isAdmin]);

    return (
        <div className={styles.previewTerms}>
            <Toast ref={toast} />
            <div className={styles.previewHolder}>
                <div className={styles.scroll}>
                    <div className={styles.fieldsGrid}>
                        <Accordion multiple>
                            <AccordionTab header="Business Information" headerClassName="bg-white">
                                <div className='w-full block'>
                                    <div className="row flex w-full p-2">
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Business Name</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.entityName}
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">EIN</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.ein}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex w-full p-2">
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Country</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.address.country}
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">EIN</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.address.line1}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex w-full p-2">
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Address</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.address.city}
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">State</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.address.state}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex w-full p-2">
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Zip Code</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.address.zipCode}
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Date of Incorporation</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.incorporationDate}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row flex w-full p-2">
                                        <div className="flex-1 flex flex-column gap-1">
                                            <div className="w-full">Tax Year Start Date.</div>
                                            <div className="w-full text-sm font-bold">
                                                {businessInformation?.electionEffectiveDate}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Shareholder Information">
                                {
                                    shareHolders?.map((shareHolder ) => {
                                        return (
                                            <div key={shareHolder.id} className='w-full block'>
                                                <div className="row flex w-full p-2">
                                                    <div className="flex-1 flex flex-column gap-1">
                                                        <div className="w-full">Shareholder Name</div>
                                                        <div className="w-full text-sm font-bold">
                                                            {shareHolder.name}
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 flex flex-column gap-1">
                                                        <div className="w-full">Share</div>
                                                        <div className="w-full text-sm font-bold">
                                                            {shareHolder.numberOfShares}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row flex w-full p-2">
                                                    <div className="flex-1 flex flex-column gap-1">
                                                        <div className="w-full">Address</div>
                                                        <div className="w-full text-sm font-bold">
                                                            {`${shareHolder.address.line1}, ${shareHolder.address.line2}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Dialog style={{width:'30vw'}}
                    visible={showPaymentModal}
                    footer={
                        <div className="w-full pb-4 justify-content-center flex flex-wrap column-gap-3">
                            <Button label="Cancel"
                                    severity="secondary" onClick={() => setShowPaymentModal(false)}/>
                            <Button label="Pay now" onClick={navigateToPaymentPage}/>
                        </div>
                    }
                    onHide={() => setShowPaymentModal(false)} >
                <div className="w-full flex flex-column align-items-center gap-3">
                    <div className="w-5rem p-2 border-round border-solid border-primary-500">
                        <img src={applyConfirmIcon} className="max-w-full h-auto" alt="dd"/>
                    </div>
                    <h2 className="w-full m-0 p-0 text-black-alpha-90 text-center">
                        Payment is required before publish
                    </h2>
                    <div className="w-full text-center font-medium text-black-70 line-height-3">
                        You need to complete the payment before publishing this document. Do you want to proceed?
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Publish;