import React, {FC, useCallback, useEffect} from 'react';
import {Dropdown} from 'primereact/dropdown';
import ButtonPrimary from "../../../../../uiComponents/ButtonPrimary/ButtonPrimary";
import styles from "../shared.module.scss";
import sharedStyles from "../shared.module.scss";
import * as Yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {InputNumber} from 'primereact/inputnumber';
import {yupResolver} from "@hookform/resolvers/yup";
import {InputText} from "primereact/inputtext";
import {LabelValue} from "../../../../../components/LabelValue/LabelValue";
import {
    OwnershipMetric,
    OwnershipTypes,
    TOwnershipMetric,
    TOwnershipType,
    TShareholderInformationFormValues
} from "./types";
import {Calendar} from "primereact/calendar";
import {InputMask} from "primereact/inputmask";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import {HttpService} from "../../../../../services/HttpService";
import {Nullable} from "primereact/ts-helpers";
import OfficerInfo from "../OfficerInfo/OfficerInfo";
import moment from "moment";

const ownershipTypesOptions: { label: string; value: TOwnershipType }[] = [
    {
        label: 'Individual',
        value: OwnershipTypes.INDIVIDUAL
    },
    {
        label: 'Entity',
        value: OwnershipTypes.ENTITY
    },
];

const mock = {
    name: 'Narek Pedanyan',
    email: 'naropedan@gmail.com',
    address: {
        line1: 'H. Sahyan',
        city: 'Yerevan',
        state: 'Wash',
        zipCode: '0012',
        country: 'Armenia',
    },
    ownershipPercentage: 12,
    numberOfShares: 24,
    ownershipType: 'INDIVIDUAL',
    ein: '123-23-3457',
    dateAcquired: new Date('2025-01-14'),
    taxYearEndMonthAndDay: new Date('2025-01-14'),
}

interface TShareholdersFormProps {
    isCreateMode: boolean;
    officerInfo: Record<string, any>;
    onSubmit: SubmitHandler<TShareholderInformationFormValues>;
    resetFormKey: number;
    ownershipMetric: TOwnershipMetric | string;
    initialValues?: TShareholderInformationFormValues;
    isShareholderUpdate?: boolean;
    onCancel: () => void;
}

const ShareholdersForm: FC<TShareholdersFormProps> = ({
onSubmit,
resetFormKey,
ownershipMetric,
initialValues,
isShareholderUpdate,
onCancel,
officerInfo,
isCreateMode
}) => {
    const [ownershipValue, setOwnershipValue] = React.useState(OwnershipTypes.INDIVIDUAL);
    const [countries, setCountries] = React.useState([]);
    const [countryStates, setCountryStates] = React.useState([]);
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [isOwnershipSelected, setOwnershipSelected] = React.useState(false);
    const [checked, setChecked] = React.useState<boolean>(false);

    const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        address: Yup.object({
            line1: Yup.string().required('Address line 1 is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().when("country",{
                is: 'US',
                then: Yup.string().required('State is required'),
                otherwise: Yup.string().nullable(),
            }),
            zipCode: Yup.string().required('Zip code is required'),
            country: Yup.string().required('Country is required'),
        }),
        ownershipPercentage: Yup
            .number()
            .nullable()
            .min(0, 'Ownership percentage cannot be less than 0')
            .max(100, 'Ownership percentage cannot exceed 100'),
        numberOfShares: Yup
            .number()
            .nullable()
            .min(0, 'Number of shares cannot be negative'),
        ownershipType: Yup
            .string()
            .oneOf([OwnershipTypes.INDIVIDUAL, OwnershipTypes.ENTITY], `Ownership type must be ${OwnershipTypes.INDIVIDUAL} or ${OwnershipTypes.ENTITY}`)
            .required('Ownership type is required'),
        ein:Yup.string().when("ownershipType", (ownershipType, schema) =>
            ownershipType
                ? ownershipType === OwnershipTypes.INDIVIDUAL
                    ? schema.matches(/^\d{3}-\d{2}-\d{4}$/, 'SNN must be in the format XXX-XX-XXXX')
                        .required('EIN is required for INDIVIDUAL ownership')
                    : schema.matches(/^\d{2}-\d{7}$/, 'EIN must be in the format XX-XXXXXXX')
                        .required('EIN is required for ENTITY ownership')
                : schema.notRequired()
        ),
        dateAcquired: Yup.date().required('Date acquired is required'),
        taxYearEndMonthAndDay: Yup.date().required("Shareholder's tax year end is required")
    });

    const defaultValues: TShareholderInformationFormValues = {
        name: '',
        email: '',
        address: {
            line1: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
        },
        ...(
            ownershipMetric === OwnershipMetric.PERCENTAGE ? {
                ownershipPercentage: undefined
            } : {}
        ),
        ...(
            ownershipMetric === OwnershipMetric.NUMBER_OF_SHARES ? {
                numberOfShares: undefined
            } : {}
        ),
        ein: '',
        dateAcquired: undefined,
        taxYearEndMonthAndDay: undefined,
        ownershipType: '',
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        reset,

    } = useForm<TShareholderInformationFormValues>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: initialValues || defaultValues,
    });

    const [selectedCountry , setSelectedCountry] = React.useState(watch('address.country'));
    const submitHandler: SubmitHandler<TShareholderInformationFormValues> = (values) => {
        onSubmit(values);
    }

    const getCountriesANdStates = useCallback(async () => {
       const states = await HttpService.get('/api/ref/state').then(res => res.data);
       const countries = await HttpService.get('/api/ref/country').then(res => res.data);
       return {countries, states};
    },[]);

    const handleUseOfficerData = useCallback((e: InputSwitchChangeEvent) => {
        const isChecked:boolean = Boolean(e.value);
        setChecked(isChecked);
        if(isChecked) {
            reset({
                ...defaultValues,
                name:officerInfo.name,
                email:officerInfo.email,
            });
        } else  {
            reset(defaultValues)
        }

    }, [reset, defaultValues, officerInfo]);

    useEffect(() => {
        getCountriesANdStates().then(data => {
            setCountryStates(data.states);
            setCountries(data.countries);
            setIsDataLoaded(true);
        });
        return  () => reset()
    }, [resetFormKey]);

    return (
        <form className={sharedStyles.form} onSubmit={handleSubmit(submitHandler)}>
            <div className={sharedStyles.fieldsHolder}>
                <div className={sharedStyles.scroll}>

                    <div className={styles.fieldsGrid}>
                        {
                            isCreateMode ? (
                                <div className="flex">
                                    <span className="font-semibold mr-4">The officer is also a shareholder</span>
                                    <InputSwitch checked={checked} onChange={handleUseOfficerData} />
                                </div>
                            ) : null
                        }

                        <LabelValue
                            label="Name"
                            required={true}
                            fieldName="name"
                            comment=""
                        >
                            <Controller
                                name="name"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.name ? "p-invalid" : ""}
                                        placeholder="Enter shareholder name"
                                    />
                                )}
                            />
                            {errors.name && <small className="p-error">{errors.name.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Email"
                            required={true}
                            fieldName="email"
                            comment=""
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.email ? "p-invalid" : ""}
                                        placeholder="Enter shareholder email"
                                    />
                                )}
                            />
                            {errors.email && <small className="p-error">{errors.email.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Country"
                            required={true}
                            fieldName="address.country"
                            comment=""
                        >
                            <Controller
                                name="address.country"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <Dropdown
                                            {...field}
                                            filter
                                            value={field.value}
                                            style={{width: '100%'}}
                                            options={countries}
                                            disabled={!isDataLoaded}
                                            optionLabel="label"
                                            optionValue="code"
                                            placeholder="Select a Country"
                                            className={errors.address?.country ? "p-invalid" : ""}
                                            onChange={e => {
                                                const value:string = e.value;
                                                setSelectedCountry(value)
                                                field.onChange(value)
                                            }}
                                        />
                                    )
                                }
                                }
                            />
                            {errors.address?.country &&
                                <small className="p-error">{errors.address.country.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Address Line 1"
                            required={true}
                            fieldName="address.line1"
                            comment=""
                        >
                            <Controller
                                name="address.line1"
                                control={control}
                                render={({field}) => (
                                    <InputText
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.address?.line1 ? "p-invalid" : ""}
                                        placeholder="Enter address line 1"
                                    />
                                )}
                            />
                            {errors.address?.line1 && <small className="p-error">{errors.address.line1.message}</small>}
                        </LabelValue>
                        <div className={styles.addressLineGrid}>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="City"
                                    required={true}
                                    fieldName="address.city"
                                    comment=""
                                >
                                    <Controller
                                        name="address.city"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.city ? "p-invalid" : ""}
                                                placeholder="Enter city"
                                            />
                                        )}
                                    />
                                    {errors.address?.city && <small className="p-error">{errors.address.city.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue label="State" required={true} fieldName="address.state" comment="">
                                    <Controller
                                        name="address.state"
                                        control={control}
                                        render={({field}) => {
                                            if (selectedCountry === 'US') {
                                                return (
                                                    <Dropdown
                                                        {...field}
                                                        filter
                                                        style={{width: '100%'}}
                                                        options={countryStates}
                                                        disabled={!isDataLoaded}
                                                        optionLabel="label"
                                                        optionValue="code"
                                                        placeholder="Select a State"
                                                        className={errors.address?.state ? "p-invalid" : ""}
                                                    />
                                                )
                                            }

                                             return (
                                                 <InputText
                                                     {...field}
                                                     style={{width: '100%'}}
                                                     disabled={!isDataLoaded}
                                                     className={errors.address?.state ? "p-invalid" : ""}
                                                     placeholder="Select a State"
                                                 />
                                             )
                                        }

                                    }
                                    />
                                  {errors.address?.state && <small className="p-error">{errors.address.state.message}</small>}
                                </LabelValue>
                            </div>
                            <div className={styles.addressGridItem}>
                                <LabelValue
                                    label="Zip Code"
                                    required={true}
                                    fieldName="address.zipCode"
                                    comment=""
                                >
                                    <Controller
                                        name="address.zipCode"
                                        control={control}
                                        render={({field}) => (
                                            <InputText
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.address?.zipCode ? "p-invalid" : ""}
                                                placeholder="Enter zip code"
                                            />
                                        )}
                                    />
                                    {errors.address?.zipCode &&
                                        <small className="p-error">{errors.address.zipCode.message}</small>}
                                </LabelValue>
                            </div>
                        </div>

                        {
                            ownershipMetric === OwnershipMetric.PERCENTAGE && (
                                <LabelValue
                                    label="Ownership percentage"
                                    required={true}
                                    fieldName="ownershipPercentage"
                                    comment=""
                                >
                                    <Controller
                                        name="ownershipPercentage"
                                        control={control}
                                        render={({field}) => (
                                            <InputNumber
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.ownershipPercentage ? "p-invalid" : ""}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                }}
                                                placeholder="12%"
                                            />
                                        )}
                                    />
                                    {errors.ownershipPercentage &&
                                        <small className="p-error">{errors.ownershipPercentage.message}</small>}
                                </LabelValue>
                            )
                        }
                        {
                            ownershipMetric === OwnershipMetric.NUMBER_OF_SHARES && (
                                <LabelValue
                                    label="Number of shares"
                                    required={true}
                                    fieldName="numberOfShares"
                                    comment=""
                                >
                                    <Controller
                                        name="numberOfShares"
                                        control={control}
                                        render={({field}) => (
                                            <InputNumber
                                                {...field}
                                                style={{width: '100%'}}
                                                className={errors.numberOfShares ? "p-invalid" : ""}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.numberOfShares &&
                                        <small className="p-error">{errors.numberOfShares.message}</small>}
                                </LabelValue>
                            )
                        }
                        <LabelValue
                            label="Ownership type"
                            required={true}
                            fieldName="ownershipType"
                            comment=""
                        >
                            <Controller
                                name="ownershipType"
                                control={control}
                                render={({field}) => (
                                    <Dropdown
                                        {...field}
                                        style={{width: '100%'}}
                                        className={errors.ownershipType ? "p-invalid" : ""}
                                        options={ownershipTypesOptions}
                                        onChange={(e) => {
                                            setOwnershipValue(e.value)
                                            setOwnershipSelected(true);
                                            field.onChange(e.value);
                                        }}
                                        placeholder="Select ownership type"
                                    />
                                )}
                            />
                            {errors.ownershipType &&
                                <small className="p-error">{errors.ownershipType.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="EIN"
                            required={true}
                            fieldName="ein"
                            comment=""
                        >
                            <Controller
                                name="ein"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <InputMask
                                            {...field}
                                            style={{width: '100%'}}
                                            className={errors.ein ? "p-invalid" : ""}
                                            {
                                                ...((isOwnershipSelected) ? (
                                                    (ownershipValue === OwnershipTypes.INDIVIDUAL) ? {
                                                        mask:"999-99-9999",
                                                        placeholder:"999-99-9999"
                                                    } : {
                                                        mask:"99-9999999",
                                                        placeholder:"99-9999999"
                                                    }
                                                ) : (field.value ? {
                                                    mask:field.value,
                                                    placeholder:field.value
                                                } : {})
                                                )
                                            }
                                        />
                                    )
                                }}
                            />
                            {errors.ein && <small className="p-error">{errors.ein.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Date Acquired"
                            required={true}
                            fieldName="dateAcquired"
                            comment=""
                        >
                            <Controller
                                name="dateAcquired"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="mm/dd/yy"
                                        showIcon
                                        style={{width: '100%'}}
                                        className={errors.dateAcquired ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.dateAcquired &&
                                <small className="p-error">{errors.dateAcquired.message}</small>}
                        </LabelValue>
                        <LabelValue
                            label="Shareholder's tax year end (month and day)"
                            required={true}
                            fieldName="taxYearEndMonthAndDay"
                            comment=""
                        >
                            <Controller
                                name="taxYearEndMonthAndDay"
                                control={control}
                                render={({field}) => (
                                    <Calendar
                                        {...field}
                                        dateFormat="mm/dd/yy"
                                        showIcon
                                        style={{width: '100%'}}
                                        className={errors.taxYearEndMonthAndDay ? "p-invalid" : ""}
                                        placeholder="Select date"
                                    />
                                )}
                            />
                            {errors.taxYearEndMonthAndDay &&
                                <small className="p-error">{errors.taxYearEndMonthAndDay.message}</small>}
                        </LabelValue>
                    </div>
                </div>
            </div>
            <div className={sharedStyles.actionButtonHolder}>
                <ButtonPrimary
                    styleType="secondaryOutline"
                    onClick={onCancel}
                    type="button"
                    label={isShareholderUpdate ? 'Cancel' : 'Back to shareholders'}
                />
                <ButtonPrimary
                    type="submit"
                    label={isShareholderUpdate ? 'Update' : 'Add'}
                />
            </div>
        </form>
    )
}

export default ShareholdersForm;
