import React, {memo, useCallback, useMemo} from "react";
import {Card} from "primereact/card";
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";

function DashboardContent(props: any) {
    const {
        handleConfirm,
        children,
        document,
    } = props;

    const navigate = useNavigate();
    const chipProps = useMemo(() => {

        if (document.status === "DRAFT") {
            return {
                label: "Drat",
                icon: "pi pi-bolt",
                className: "border-round text-sm bg-gray-300"
            }
        }

        if (document.status === "PENDING") {
            return {
                label:"Pending",
                icon: "pi pi-hourglass",
                className: "border-round text-sm bg-orange-100 text-orange-500"
            }
        }

        if (document.status === "PARTIALLY_SIGNED") {
            return {
                label: "Partially Signed",
                icon: "pi pi-hourglass",
                className: "border-round text-sm bg-orange-100 text-orange-500"
            }
        }
        if (document.status === "FULLY_SIGNED") {
            return {
                label: "Fully Signed",
                icon: "pi pi-list",
                className: "border-round text-sm bg-indigo-200 text-indigo-500"
            }
        }

        if (document.status === "CONFIRMED") {
            return {
                label: "Confirmed",
                icon: "pi pi-file-check",
                className: "border-round text-sm bg-indigo-200 text-indigo-500"
            }
        }

        if (document.status === "SUBMITTED") {
            return {
                label: "Submitted",
                icon: "pi pi-verified",
                className: "border-round text-sm bg-indigo-200 text-indigo-500"
            }
        }

        if (document.status === "SHIPPED") {
            return {
                label: "Drat",
                icon: "pi pi-truck",
                className: "border-round text-sm bg-teal-200 text-indigo-500"
            }
        }
        if (document.status === "DELIVERED") {
            return {
                label: "Delivered",
                icon: "pi pi-verified",
                className: "border-round bg-green-100 text-green-400"
            }
        }

        if (document.status === "ACKNOWLEDGED") {
            return {
                label: "Drat",
                icon: "pi pi-file-check",
                className: "border-round text-sm bg-green-200 text-green-600"
            }
        }
    }, [document]);

    const goToUpdate = (id: number) => {
        navigate(`/form2553/update/${id}`);
    }
    return (
        <Card pt={{content: {className: "pt-0"}}}
              header={
                  <div className="w-full p-4 row-gap-3 border-bottom-1 surface-border flex flex-column">
                      <div className="w-full flex align-items-center justify-content-between">
                          <div className="flex align-items-start">
                          <span className="mr-2 font-semibold text-2xl">
                          {document.companyName}
                          </span>
                              <Chip {...chipProps}
                                    pt={{label: {className: "text-sm mr-1 font-bold font-semibold"}}}/>
                          </div>
                          {
                              (document.status === "FULLY_SIGNED") ? (
                                  <Button outlined
                                          tooltip="Submit to Corpora for filing"
                                          label="Submit"  onClick={handleConfirm}/>
                              ) : (document.status === "DRAFT" ? (
                                  <Button label="Continue" outlined onClick={() => goToUpdate(document.id)}/>
                              ) : null)
                          }

                      </div>
                      <div className="mt-2 column-gap-3 w-full flex align-items-center">
                          <div className="font-base text-gray-700">
                              Filling ID: <span className="font-medium text-black-alpha-90">
                              {document.orderNumber}
                          </span>
                          </div>
                          <div className="font-base text-gray-700">
                              Email: <span className="font-medium text-black-alpha-90">
                              {document.companyEmail}
                          </span>
                          </div>
                      </div>
                  </div>
              }>
            {children}
        </Card>
    )
}

export default memo(DashboardContent)